<template>
  <div v-if="card_type === 'danger'" class="card card-stretch bg-danger mb-5 mb-xxl-8">
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Section-->
      <div class="d-flex align-items-center">
        <!--begin::Symbol-->
        <div class="symbol symbol-50px me-5">
          <span class="symbol-label bg-white bg-opacity-10">
            <!--begin::Svg Icon | path: icons/duotone/Communication/Group-chat.svg-->
            <span class="svg-icon svg-icon-2x svg-icon-white">
              <!--suppress HtmlDeprecatedAttribute -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <path
                  d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z"
                  fill="#000000"
                />
                <path
                  d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z"
                  fill="#000000"
                  opacity="0.3"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </span>
        </div>
        <!--end::Symbol-->
        <!--begin::Title-->
        <div>
          <a v-show="card_subtitle != null" class="fs-4 text-white fw-bolder">{{ card_title }}</a>
          <div v-show="card_subtitle != null" class="fs-7 text-white opacity-75 fw-bold mt-1">{{ card_subtitle }}</div>
        </div>
        <!--end::Title-->
      </div>
      <!--end::Section-->
      <!--begin::Info-->
      <div class="fw-bolder text-white pt-7">
        <span v-show="card_text_line_1 != null" class="d-block">{{ card_text_line_1 }}</span>
        <span v-show="card_text_line_2 != null" class="d-block pt-2">{{ card_text_line_2 }}</span>
      </div>
      <!--end::Info-->
      <!--begin::Progress-->
      <div v-show="card_progress_percent != null" class="progress h-6px mt-7 bg-white bg-opacity-10">
        <div
          v-show="card_progress_percent != null"
          class="progress-bar bg-white"
          role="progressbar"
          :style="'width: ' + card_progress_percent + '%'"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <!--end::Progress-->
    </div>
    <!--end::Body-->
  </div>
  <div
    v-if="card_type === 'kalm'"
    class="card card-stretch mb-5 mb-xxl-8"
    style="
      background: linear-gradient(90deg, rgba(6, 56, 49, 1) 0%, rgba(1, 148, 154, 1) 100%) !important;
      background-attachment: fixed;
    "
  >
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Section-->
      <div class="d-flex align-items-center">
        <!--begin::Symbol-->
        <div class="symbol symbol-50px me-5">
          <span class="symbol-label bg-white bg-opacity-10">
            <!--begin::Svg Icon | path: icons/duotone/Communication/Group-chat.svg-->
            <span class="svg-icon svg-icon-2x svg-icon-white">
              <!--suppress HtmlDeprecatedAttribute -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <path
                  d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z"
                  fill="#000000"
                />
                <path
                  d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z"
                  fill="#000000"
                  opacity="0.3"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </span>
        </div>
        <!--end::Symbol-->
        <!--begin::Title-->
        <div>
          <a v-show="card_title != null" class="fs-4 text-white fw-bolder">{{ card_title }}</a>
          <div v-show="card_subtitle != null" class="fs-7 text-white opacity-75 fw-bold mt-1">{{ card_subtitle }}</div>
        </div>
        <!--end::Title-->
      </div>
      <!--end::Section-->
      <!--begin::Info-->
      <div class="fw-bolder text-white pt-7">
        <span v-show="card_text_line_1 != null" class="d-block">{{ card_text_line_1 }}</span>
        <span v-show="card_text_line_2 != null" class="d-block pt-2">{{ card_text_line_2 }}</span>
      </div>
      <!--end::Info-->
      <!--begin::Progress-->
      <div v-show="card_progress_percent != null" class="progress h-6px mt-7 bg-white bg-opacity-10">
        <div
          v-show="card_progress_percent != null"
          class="progress-bar bg-white"
          role="progressbar"
          :style="'width: ' + card_progress_percent + '%'"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <!--end::Progress-->
    </div>
    <!--end::Body-->
  </div>
  <div v-if="card_type === 'success'" class="card card-stretch mb-5 mb-xxl-8">
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Section-->
      <div class="d-flex align-items-center">
        <!--begin::Symbol-->
        <div class="symbol symbol-50px me-5">
          <span class="symbol-label bg-light-success">
            <!--begin::Svg Icon | path: icons/duotone/Home/Library.svg-->
            <span class="svg-icon svg-icon-2x svg-icon-success">
              <!--suppress HtmlDeprecatedAttribute -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
                    fill="#000000"
                  />
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)"
                    x="16.3255682"
                    y="2.94551858"
                    width="3"
                    height="18"
                    rx="1"
                  />
                </g>
              </svg>
            </span>
            <!--end::Svg Icon-->
          </span>
        </div>
        <!--end::Symbol-->
        <!--begin::Title-->
        <div>
          <a v-show="card_title !== null" class="fs-4 text-gray-800 fw-bolder">{{ card_title }}</a>
          <div v-show="card_subtitle !== null" class="fs-7 text-muted fw-bold mt-1">{{ card_subtitle }}</div>
        </div>
        <!--end::Title-->
      </div>
      <!--end::Section-->
      <!--begin::Info-->
      <div class="fw-bolder text-muted pt-7">
        <span v-show="card_text_line_1 != null" class="d-block">{{ card_text_line_1 }}</span>
        <span v-show="card_text_line_2 != null" class="d-block pt-2">{{ card_text_line_2 }}</span>
      </div>
      <!--end::Info-->
      <!--begin::Progress-->
      <div v-show="card_progress_percent != null" class="progress h-6px bg-light-success mt-7">
        <div
          v-show="card_progress_percent != null"
          class="progress-bar bg-success"
          role="progressbar"
          :style="'width: ' + card_progress_percent + '%;'"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <!--end::Progress-->
    </div>
    <!--end::Body-->
  </div>
  <div v-if="card_type === 'warning'" class="card card-stretch mb-5 mb-xxl-8">
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Section-->
      <div class="d-flex align-items-center">
        <!--begin::Symbol-->
        <div class="symbol symbol-50px me-5">
          <span class="symbol-label bg-light-warning">
            <!--begin::Svg Icon | path: icons/duotone/Layout/Layout-4-blocks.svg-->
            <span class="svg-icon svg-icon-2x svg-icon-warning">
              <!--suppress HtmlDeprecatedAttribute -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5" />
                  <path
                    d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                    fill="#000000"
                    opacity="0.3"
                  />
                </g>
              </svg>
            </span>
            <!--end::Svg Icon-->
          </span>
        </div>
        <!--end::Symbol-->
        <!--begin::Title-->
        <div>
          <a v-show="card_title != null" class="fs-4 text-gray-800 fw-bolder">{{ card_title }}</a>
          <div v-show="card_subtitle != null" class="fs-7 text-muted fw-bold mt-1">{{ card_subtitle }}</div>
        </div>
        <!--end::Title-->
      </div>
      <!--end::Section-->
      <!--begin::Info-->
      <div class="fw-bolder text-muted pt-7">
        <span v-show="card_text_line_1 != null" class="d-block">{{ card_text_line_1 }}</span>
        <span v-show="card_text_line_2 != null" class="d-block pt-2">{{ card_text_line_2 }}</span>
      </div>
      <!--end::Info-->
      <!--begin::Progress-->
      <div v-show="card_progress_percent != null" class="progress h-6px bg-light-warning mt-7">
        <div
          v-show="card_progress_percent != null"
          class="progress-bar bg-warning"
          role="progressbar"
          :style="'width: ' + card_progress_percent + '%;'"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <!--end::Progress-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "ProgressCard",
  props: {
    card_type: String,
    card_title: String,
    card_subtitle: String,
    card_text_line_1: String,
    card_text_line_2: String,
    card_progress_percent: Number,
  },
};
</script>

<style scoped></style>
