<template>
  <div id="modal" aria-hidden="true" aria-labelledby="modalLabel" class="modal" style="display: block" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h1 id="modalLabel" class="modal-title fs-5">{{ modalTitle }}</h1>
          <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button" @click="close"></button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer border-0">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KalmModal",
  props: {
    modalTitle: String,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
