<template>
  <div class="modal" tabindex="-1" style="display: block" id="DeleteLocationModal">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Confirmer la suppression</h3>
                      </div>
                      <p v-if="!canSafelyDeleteLocation">
                        Cet emplacement est sélectionné dans un ou plusieurs ouvrages du descriptif. Retirez-le de ces
                        ouvrages pour pouvoir le supprimer définitivement.
                      </p>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="$emit('close')" class="btn btn-lg btn-light">Annuler</button>
                      <button
                        type="button"
                        :disabled="!canSafelyDeleteLocation"
                        @click="validateDeleteLocation"
                        class="btn btn-lg btn-danger ms-2"
                        id="deleteLocationModalButton"
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MUTATION_PROJECT_DELETE_LOCATION } from "@/views/locations/data/location_graphql";

export default {
  name: "DeleteLocationModal",
  props: {
    project: {
      type: Object,
      Required: true,
    },
    locationId: {
      type: String,
      Required: true,
    },
  },
  methods: {
    async validateDeleteLocation() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_PROJECT_DELETE_LOCATION,
          variables: {
            projectId: this.project.id,
            locationId: this.locationId,
          },
        });
      } catch (e) {
        console.error(e);
      }
      this.$emit("validate");
    },
  },
  computed: {
    canSafelyDeleteLocation() {
      //todo implement location safe delete
      return true;
      // checks if there are registered ouvrages blocks or text blocks for the given lot
      /*let ret = true;
            
            if (this.locationId && this.project) {
                const doc = JSON.parse(this.project.document).doc;
                if (doc && doc.content) {
                    doc.content.forEach(lotNode => {
                        if (lotNode && lotNode.type === "docLot" && lotNode?.content) {
                            const lotContent = lotNode.content.find(n => n.type === "lotContent");
                            if (lotContent && lotContent?.content) {

                                lotContent.content.filter(n => n.type === "lotOuvrage").forEach(n => {
                                    if (n && n?.attrs && (n?.attrs?.locations || n?.attrs?.location)) {
                                        if (this.project.featureMultiLocation) {
                                            if (n?.attrs?.locations?.includes(this.locationId)) {
                                                ret = false;
                                            }
                                        } else {
                                            if (n?.attrs?.location === this.locationId) {
                                                ret = false;
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
            }
            return ret;*/
    },
  },
};
</script>

<style lang="scss" scoped></style>
