<template>
  <KalmModal modalTitle="Supprimer le template" @close="close">
    <template v-slot:body>
      <p>Etes-vous sur de vouloir supprimer ce template de todo ?</p>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
      <LoadingButton v-if="isSaving"></LoadingButton>
      <button v-if="!isSaving" type="button" class="btn btn-danger" @click="confirm">Supprimer</button>
    </template>
  </KalmModal>
</template>

<script>
import { DELETE_CANEVA_MUTATION, GET_CANEVAS } from "../../data/canevas_graphql";
import KalmModal from "@/views/_core/components/KalmModal.vue";
import LoadingButton from "@/views/_core/components/LoadingButton.vue";
import { toast } from "vue3-toastify";

export default {
  name: "CanevaDeleteConfirmModal",
  components: {
    KalmModal,
    LoadingButton,
  },
  props: {
    canevaId: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async confirm() {
      this.isSaving = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_CANEVA_MUTATION,
          variables: {
            id: this.canevaId,
          },
          refetchQueries: [
            {
              query: GET_CANEVAS,
            },
          ],
        });
        toast.success("Le template a bien été supprimé");
      } catch (e) {
        toast.error("Une erreur est survenue lors de la suppression du template");
      } finally {
        this.isSaving = false;
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
