<template>
  <div class="modal" tabindex="-1" @keydown.esc="this.$emit('close')" style="display: block">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div :class="page === 0 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Dupliquer le chiffrage</h3>
                      </div>
                      <!--end::Heading-->
                    </div>

                    <div class="">
                      <label class="text-muted mt-2 mb-1">Nom du chiffrage</label>
                      <div class="input-group align-middle">
                        <input class="form-control" type="text" v-model="modal.name" />
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.$emit('close')" class="btn btn-lg btn-light">Annuler</button>
                      <button
                        type="button"
                        @click="this.$emit('validate')"
                        :disabled="!modal.name"
                        class="btn btn-lg btn-primary btn-active-light-primary ms-2"
                      >
                        Confirmer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProposalDuplicateModal",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
