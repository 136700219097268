<template>
  <!--begin::Content-->
  <div :class="{ 'blur-content': this.isModalActive }">
    <ProjectBreadcrumbComponent
      :options="[
        { name: 'Suivi', id: 'project.tender.manage' },
        { name: 'Comparatif', id: 'project.tender.table' },
        {
          name: 'Scénarios',
          id: 'project.tender.scenarios',
          disabled: !this.currentUser?.groups?.includes('plan_premium'),
        },
      ]"
      :project_id="$route.params.id"
      current_route="project.tender.manage"
      title="Consultation"
    ></ProjectBreadcrumbComponent>
    <!--begin::Row-->
    <div class="row g-5 print-hidden">
      <div class="col-12">
        <ProgressCard
          :card_progress_percent="
            ((card_count_proposals_sent + card_count_proposals_received) / this.card_count_proposals_created) * 50
          "
          :card_text_line_1="
            '' +
            this.card_count_proposals_sent +
            (this.card_count_proposals_sent !== 1 ? ' descriptifs envoyés' : ' descriptif envoyé')
          "
          :card_text_line_2="
            '' +
            this.card_count_proposals_received +
            (this.card_count_proposals_received !== 1 ? ' chiffrages reçus' : ' chiffrage reçu')
          "
          card_title="DCE • Consultation"
          card_type="kalm"
        />
      </div>
    </div>

    <div class="d-flex">
      <h2 class="align-bottom mt-2 ms-5">Suivi de la consultation</h2>
      <div class="d-flex ms-auto">
        <button
          v-tooltip:bottom="'Mettre à jour toutes les DPGF'"
          class="ms-auto mb-3 btn btn-icon btn-primary me-2"
          @click="this.modalUpdateOuvragesShow()"
        >
          <span v-if="this.modals.updateOuvrages.loading" class="spinner-border spinner-border-sm"></span
          ><span v-else class="bi bi-arrow-repeat"></span>
        </button>
      </div>
    </div>
    <!--begin::Row-->
    <ProposalsTable
      :initialLoading="this.apolloLoading"
      :loading="this.modals.updateOuvrages.loading"
      :loadingTable="this.modals.updateProposal.loadingTable"
      :proposals="getSentProposals"
      mainButtonMessage="+ Entreprise"
      title="Entreprises consultées"
      @clickOption="openModal"
      @mainAction="openModal({ action: 'createProposal' })"
      @onUpdateOrder="this.$apollo.queries.proposals.refetch()"
    >
      <TenderProposalEmptyState @mainAction="openModal({ action: 'createProposal' })" />
    </ProposalsTable>
    <ProposalsTable
      :initialLoading="this.apolloLoading"
      :loading="this.modals.updateOuvrages.loading"
      :loadingTable="this.modals.updateProposal.loadingTable"
      :proposals="getEstimatedProposals"
      class="mt-4"
      mainButtonMessage="+ Estimatif"
      title="Estimatifs"
      @clickOption="openModal"
      @mainAction="openModal({ action: 'createEstimation' })"
      @onUpdateOrder="this.$apollo.queries.proposals.refetch()"
    >
      <TenderEstimationEmptyState @mainAction="openModal({ action: 'createEstimation' })" />
    </ProposalsTable>
    <!--end::Content-->
  </div>
  <input ref="fileInput" multiple style="display: none" type="file" @change="handleFileChange" />
  <ExplorerModal
    v-if="modals.fileExplorer.show"
    v-model="this.modals.fileExplorer.paramsId"
    :projectId="this.modals.fileExplorer.projectId"
    @close="modalFileExplorerClose"
  />
  <CreateProposalModal
    v-if="modals.proposalCreate.show"
    v-model="this.modals.proposalCreate"
    :contacts="contacts"
    :lots="this.lots"
    :page="this.modals.proposalCreate.page"
    @close="modalProposalCreateClose"
    @validate="modalProposalCreateValidate"
  />
  <CreateEstimationModal
    v-if="modals.proposalEstimation.show"
    v-model="this.modals.proposalEstimation"
    :lots="this.lots"
    :page="this.modals.proposalEstimation.page"
    @close="modalEstimationCreateClose"
    @validate="modalEstimationCreateValidate"
  />
  <ProposalDuplicateModal
    v-if="modals.proposalDuplicate.show"
    v-model="this.modals.proposalDuplicate"
    :page="this.modals.proposalCreate.page"
    @close="modalProposalDuplicateClose"
    @validate="modalProposalDuplicateValidate"
  />
  <ProposalDeleteModal
    v-if="modals.proposalDelete.show"
    @close="modalProposalDeleteClose"
    @validate="modalProposalDeleteValidate"
  />
  <ProposalDownloadModal
    v-if="modals.download.show"
    :contacts="contacts"
    :modal="modals.download"
    :proposals="proposals"
    @close="modalDownloadClose"
  />
  <ProposalEditModal
    v-if="modals.proposalEdit.show"
    v-model="modals.proposalEdit"
    :contacts="contacts"
    :lots="this.lots"
    @close="modalProposalEditClose"
    @validate="modalProposalEditValidate"
  />
  <ProposalUpdateAllModal
    v-if="modals.updateOuvrages.show"
    v-model="modals.updateOuvrages"
    @close="modalUpdateOuvragesClose"
    @validate="modalUpdateOuvragesValidate"
  />
  <ProposalUpdateModal
    v-if="modals.updateProposal.show"
    v-model="modals.updateProposal"
    @close="modalUpdateProposalClose"
    @validate="modalUpdateProposalValidate"
  />
</template>

<script>
import {
  CURRENT_USER_QUERY,
  GET_PROPOSALS_FOR_PROJECT,
  MUTATION_TENDER_PROPOSAL_UPDATE_OUVRAGES,
  MUTATION_TENDER_UPDATE_OUVRAGES,
  MUTATION_TENDER_CREATE_PROPOSAL,
  MUTATION_TENDER_DUPLICATE_PROPOSAL,
  MUTATION_TENDER_MANAGE_PROPOSAL,
  MUTATION_TENDER_DELETE_PROPOSAL,
  GET_PROJECT_BY_ID,
} from "@/graphql/graphql";

import { FILE_UPLOAD } from "@/views/documents/data/files_graphql";

import kalmStore from "@/store";
import ProgressCard from "@/views/_core/components/ProgressCard";
import { ALL_CONTACTS_QUERY } from "@/views/contacts/data/contacts_graphql";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import ProposalsTable from "./components/proposalsTable.vue";
import ProposalDeleteModal from "./components/proposalDeleteModal.vue";
import ProposalUpdateModal from "./components/proposalUpdateModal.vue";
import ProposalUpdateAllModal from "./components/proposalUpdateAllModal.vue";
import ProposalEditModal from "./components/proposalEditModal.vue";
import ProposalDownloadModal from "./components/proposalDownloadModal.vue";
import ProposalDuplicateModal from "./components/proposalDuplicateModal.vue";
import CreateEstimationModal from "./components/createEstimationModal.vue";
import CreateProposalModal from "./components/createProposalModal.vue";
import ExplorerModal from "@/views/documents/presentation/file-explorer/components/ExplorerModal.vue";
import TenderProposalEmptyState from "@/views/project/tender/components/TenderProposalEmptyState.vue";
import TenderEstimationEmptyState from "@/views/project/tender/components/TenderEstimationEmptyState.vue";

export default {
  name: "TenderPage",
  components: {
    ProgressCard,
    ProjectBreadcrumbComponent,
    ProposalsTable,
    ProposalDeleteModal,
    ProposalUpdateModal,
    ProposalUpdateAllModal,
    ProposalEditModal,
    ProposalDownloadModal,
    ProposalDuplicateModal,
    CreateEstimationModal,
    CreateProposalModal,
    ExplorerModal,
    TenderProposalEmptyState,
    TenderEstimationEmptyState,
  },
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    proposals: {
      query: GET_PROPOSALS_FOR_PROJECT,
      variables() {
        return {
          projectId: kalmStore.getters.currentProjectFocus,
        };
      },
      result() {
        this.apolloLoading = false;
      },
    },
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: kalmStore.getters.currentProjectFocus,
        };
      },
    },
  },
  data() {
    return {
      fileProposalId: "",
      apolloLoading: true,
      contacts: [],
      currentUser: {},
      modals: {
        proposalDelete: {
          show: false,
          id: "",
        },
        updateProposal: {
          show: false,
          proposalId: "",
          importUnit: true,
          importQt: false,
          importPrice: false,
          resetExisting: false,
          loadingTable: {},
        },
        proposalMenu: {
          show: false,
        },
        proposalCreate: {
          show: false,
          page: 0,
          name: "Budget estimatif",
          contact: "",
          modeLots: "ALL",
          lots: [],
          proposalId: "",
          importUnit: true,
          importQt: false,
          importPrice: false,
        },
        proposalEstimation: {
          show: false,
          page: 0,
          modeLots: "ALL",
          lots: [],
          proposalId: "",
          importUnit: true,
          importQt: true,
          importPrice: true,
        },
        proposalEdit: {
          show: false,
          proposalId: "",
          name: "",
          contactId: "",
          formattedSteps: [],
          modelSteps: {},
          modeLots: "ALL",
          lots: [],
          vatDefaultValue: 0,
          notes: "",
        },
        proposalDuplicate: {
          show: false,
          proposalId: "",
          name: "",
        },
        download: {
          show: false,
          includeChiffrages: false,
          proposalId: "",
          contactId: "",
        },
        fileExplorer: {
          show: false,
          paramsId: "",
          projectId: "",
        },
        updateOuvrages: {
          show: false,
          loading: false,
          lots: [],
          activateComplexImport: false,
          importUnit: true,
          importQt: false,
          importPrice: false,
        },
      },
    };
  },
  computed: {
    lots() {
      return this.project?.lots;
    },
    getEstimatedProposals() {
      if (!this.proposals) return [];

      return this.proposals.filter((p) => !p?.contact);
    },
    getSentProposals() {
      if (!this.proposals) return [];

      return this.proposals.filter((p) => p?.contact);
    },
    isModalActive: function () {
      return (
        kalmStore.state.display.profileToggled ||
        this.modals.proposalCreate.show ||
        this.modals.proposalEstimation.show ||
        this.modals.proposalMenu.show ||
        this.modals.proposalEdit.show ||
        this.modals.download.show ||
        this.modals.proposalDelete.show ||
        this.modals.updateOuvrages.show ||
        this.modals.proposalDuplicate.show ||
        this.modals.updateProposal.show
      );
    },
    card_count_proposals_created() {
      if (!this.proposals) return 0;

      return this.proposals.filter((p) => p?.contact).length;
    },
    card_count_proposals_sent() {
      if (!this.proposals) return 0;

      let ret = 0;
      this.proposals
        .filter((p) => p?.contact)
        .forEach((p) => {
          ret += JSON.parse(p.steps).find((s) => s.id === "1")?.status ? 1 : 0;
        });
      return ret;
    },
    card_count_proposals_received() {
      if (!this.proposals) return 0;

      let ret = 0;
      this.proposals
        .filter((p) => p?.contact)
        .forEach((p) => {
          ret += JSON.parse(p.steps).find((s) => s.id === "2")?.status ? 1 : 0;
        });
      return ret;
    },
  },
  methods: {
    async updateOuvrages() {
      const projectId = kalmStore.getters.currentProjectFocus;

      this.modals.updateOuvrages.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TENDER_UPDATE_OUVRAGES,
          variables: {
            projectId: projectId,
            importUnit: this.modals.updateOuvrages.importUnit,
            importQt: this.modals.updateOuvrages.importQt,
            importPrice: this.modals.updateOuvrages.importPrice,
          },
          result() {
            toast.success("Les ouvrages ont été mis à jour avec succès");
          },
          refetchQueries: [
            {
              query: GET_PROPOSALS_FOR_PROJECT,
              variables: {
                projectId: projectId,
              },
            },
          ],
        });
      } catch {
        toast.error("Une erreur est survenue lors de la mise à jour des ouvrages");
      }
      this.modals.updateOuvrages.loading = false;
    },
    modalProposalDeleteShow(id) {
      this.modals.proposalDelete.show = true;
      this.modals.proposalDelete.id = id;
    },
    modalProposalDeleteClose() {
      this.modals.proposalDelete.show = false;
      this.modals.proposalDelete.id = "";
    },
    async modalProposalDeleteValidate() {
      const proposalId = this.modals.proposalDelete.id;
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TENDER_DELETE_PROPOSAL,
          variables: {
            proposalId,
          },
          refetchQueries: [
            {
              query: GET_PROPOSALS_FOR_PROJECT,
              variables: {
                projectId: kalmStore.getters.currentProjectFocus,
              },
            },
          ],
        });
        toast.success("La demande de chiffrage a été supprimée avec succès");
      } catch {
        toast.error("Une erreur est survenue lors de la suppression de la demande de chiffrage");
      }

      this.modalProposalDeleteClose();
    },
    modalProposalCreateShow() {
      this.modals.proposalMenu.show = false;
      this.modals.proposalCreate.page = 0;
      this.modals.proposalCreate.modeLots = "ALL";
      this.modals.proposalCreate.contact = "";
      this.modals.proposalCreate.importUnit = true;
      this.modals.proposalCreate.importQt = false;
      this.modals.proposalCreate.importPrice = false;
      this.modals.proposalCreate.lots = [];
      this.lots.forEach((l) => {
        this.modals.proposalCreate.lots.push(l.id);
      });
      this.modals.proposalCreate.show = true;
    },
    modalProposalCreateClose() {
      this.modals.proposalCreate.show = false;
      this.modals.proposalCreate.contact = "";
      this.modals.proposalCreate.modeLots = "ALL";
      this.modals.proposalCreate.lots = [];
    },
    async modalProposalCreateValidate() {
      this.modals.proposalCreate.page = 2;
      const projectId = kalmStore.getters.currentProjectFocus;
      const contactId = this.modals.proposalCreate.contact;
      const name = this.modals.proposalCreate.name;
      const modeLots = this.modals.proposalCreate.modeLots;
      const lots = this.modals.proposalCreate.lots;
      const importUnit = this.modals.proposalCreate.importUnit;
      const importQt = this.modals.proposalCreate.importQt;
      const importPrice = this.modals.proposalCreate.importPrice;
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TENDER_CREATE_PROPOSAL,
          variables: {
            projectId,
            contactId,
            name,
            modeLots,
            lots,
            importUnit,
            importQt,
            importPrice,
          },
          refetchQueries: [
            {
              query: GET_PROPOSALS_FOR_PROJECT,
              variables: {
                projectId: kalmStore.getters.currentProjectFocus,
              },
            },
          ],
        });
        toast.success("La demande de chiffrage a été créée avec succès");
      } catch {
        toast.error("Une erreur est survenue lors de la création de la demande de chiffrage");
      }
    },
    modalEstimationCreateShow() {
      this.modals.proposalMenu.show = false;
      this.modals.proposalEstimation.page = 0;
      this.modals.proposalEstimation.name = "Budget Estimatif";
      this.modals.proposalEstimation.importQt = true;
      this.modals.proposalEstimation.importUnit = true;
      this.modals.proposalEstimation.importPrice = true;
      this.modals.proposalEstimation.modeLots = "ALL";
      this.modals.proposalEstimation.lots = [];
      this.lots.forEach((l) => {
        this.modals.proposalEstimation.lots.push(l.id);
      });
      this.modals.proposalEstimation.show = true;
    },
    modalEstimationCreateClose() {
      this.modals.proposalEstimation.show = false;
      this.modals.proposalEstimation.modeLots = "ALL";
      this.modals.proposalEstimation.lots = [];
    },
    async modalEstimationCreateValidate() {
      this.modals.proposalEstimation.page = 2;
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TENDER_CREATE_PROPOSAL,
          variables: {
            projectId: kalmStore.getters.currentProjectFocus,
            contactId: null,
            name: this.modals.proposalEstimation.name,
            modeLots: this.modals.proposalEstimation.modeLots,
            lots: this.modals.proposalEstimation.lots,
            importUnit: this.modals.proposalEstimation.importUnit,
            importQt: this.modals.proposalEstimation.importQt,
            importPrice: this.modals.proposalEstimation.importPrice,
          },
          refetchQueries: [
            {
              query: GET_PROPOSALS_FOR_PROJECT,
              variables: {
                projectId: kalmStore.getters.currentProjectFocus,
              },
            },
          ],
        });
        toast.success("Le budget estimatif a été créé avec succès");
      } catch (e) {
        toast.error("Une erreur est survenue lors de la création du budget estimatif");
      }
    },
    modalProposalEditShow(proposalId) {
      const proposal = this.proposals.find((p) => p.id === proposalId);

      this.modals.proposalEdit.proposalId = proposalId;
      this.modals.proposalEdit.contactId = proposal?.contact?.id || null;
      this.modals.proposalEdit.name = proposal.name;
      this.modals.proposalEdit.modeLots = proposal.modeLots;
      this.modals.proposalEdit.lots = proposal.lots.map((l) => l.id);

      this.modals.proposalEdit.formattedSteps = JSON.parse(proposal.steps);
      this.modals.proposalEdit.formattedSteps.forEach((s) => {
        this.modals.proposalEdit.modelSteps[s.id] = s.status;
      });

      this.modals.proposalEdit.notes = proposal.notes;
      this.modals.proposalEdit.vatDefaultValue = proposal.vatDefaultValue;
      this.modals.proposalEdit.show = true;
    },
    modalProposalEditClose() {
      this.modals.proposalEdit.proposalId = "";
      this.modals.proposalEdit.contactId = "";
      this.modals.proposalEdit.notes = "";
      this.modals.proposalEdit.name = "";
      this.modals.proposalEdit.vatDefaultValue = 0;
      this.modals.proposalEdit.formattedSteps = [];
      this.modals.proposalEdit.modelSteps = {};
      this.modals.proposalEdit.show = false;
    },
    async modalProposalEditValidate() {
      this.modals.proposalEdit.formattedSteps.map((s) => {
        s.status = this.modals.proposalEdit.modelSteps[s.id] || false;
        return s;
      });

      const newProposal = {
        name: this.modals.proposalEdit.name,
        steps: JSON.stringify(this.modals.proposalEdit.formattedSteps),
        notes: this.modals.proposalEdit.notes,
        vatDefaultValue: parseFloat(this.modals.proposalEdit.vatDefaultValue),
      };

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TENDER_MANAGE_PROPOSAL,
          variables: {
            proposalId: this.modals.proposalEdit.proposalId,
            newProposal: newProposal,
            modeLots: this.modals.proposalEdit.modeLots,
            lots: this.modals.proposalEdit.lots,
          },
          refetchQueries: [
            {
              query: GET_PROPOSALS_FOR_PROJECT,
              variables: {
                projectId: kalmStore.getters.currentProjectFocus,
              },
            },
          ],
        });
        toast.success("La demande de chiffrage a été modifiée avec succès");
      } catch {
        toast.error("Une erreur est survenue lors de la modification de la demande de chiffrage");
      }
      this.modalProposalEditClose();
    },
    modalProposalDuplicateShow(proposalId) {
      const proposal = this.proposals.find((p) => p.id === proposalId);

      this.modals.proposalDuplicate.proposalId = proposalId;
      this.modals.proposalDuplicate.name =
        "Copie de " + (proposal.name || proposal.contact.company || proposal.contact.name);

      this.modals.proposalDuplicate.show = true;
    },
    modalProposalDuplicateClose() {
      this.modals.proposalDuplicate.show = false;
      this.modals.proposalDuplicate.proposalId = "";
      this.modals.proposalDuplicate.name = "";
    },
    async modalProposalDuplicateValidate() {
      const proposalId = this.modals.proposalDuplicate.proposalId;
      const name = this.modals.proposalDuplicate.name;
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TENDER_DUPLICATE_PROPOSAL,
          variables: {
            proposalId: proposalId,
            name: name,
          },
          refetchQueries: [
            {
              query: GET_PROPOSALS_FOR_PROJECT,
              variables: {
                projectId: kalmStore.getters.currentProjectFocus,
              },
            },
          ],
        });
        this.modalProposalDuplicateClose();
      } catch (e) {
        toast.error("Une erreur est survenue lors de la duplication de la demande de chiffrage");
      }
    },
    modalUpdateProposalShow(proposal) {
      this.modalUpdateProposalCancel();
      this.modals.updateProposal.proposalId = proposal.id;
      this.modals.updateProposal.contactId = proposal.contact?.id;
      this.modals.updateProposal.show = true;
    },
    modalUpdateProposalCancel() {
      this.modals.updateProposal.loadingTable = {};
      this.modals.updateProposal.show = false;
    },
    async modalUpdateProposalValidate() {
      this.modals.updateProposal.loadingTable[this.modals.updateProposal.proposalId] = true;
      try {
        this.$apollo
          .mutate({
            mutation: MUTATION_TENDER_PROPOSAL_UPDATE_OUVRAGES,
            variables: {
              proposalId: this.modals.updateProposal.proposalId,
              importUnit: this.modals.updateProposal.importUnit,
              importQt: this.modals.updateProposal.importQt,
              importPrice: this.modals.updateProposal.importPrice,
              resetExisting: this.modals.updateProposal.resetExisting,
            },
            refetchQueries: [
              {
                query: GET_PROPOSALS_FOR_PROJECT,
                variables: {
                  projectId: kalmStore.getters.currentProjectFocus,
                },
              },
            ],
          })
          .then(() => {
            toast.success("Les ouvrages ont été mis à jour avec succès");
          });
      } catch {
        toast.error("Une erreur est survenue lors de la mise à jour des ouvrages");
      }

      this.modals.updateProposal.loadingTable[this.modals.updateProposal.proposalId] = false;
      this.modals.updateProposal.show = false;
    },
    modalDownloadShow(proposalId) {
      this.modals.download.show = true;
      this.modals.download.proposalId = proposalId;
      this.modals.download.name = this.proposals.find((p) => p.id === proposalId)?.name;
      this.modals.download.contactId = this.proposals.find((p) => p.id === proposalId)?.contact
        ? this.proposals.find((p) => p.id === proposalId).contact.id
        : null;
    },
    modalDownloadClose() {
      this.modals.download.show = false;
    },
    modalUpdateOuvragesShow() {
      this.modals.updateOuvrages.show = true;
      this.modals.updateOuvrages.importUnit = true;
      this.modals.updateOuvrages.importQt = false;
      this.modals.updateOuvrages.importPrice = false;
    },
    modalUpdateOuvragesClose() {
      this.modals.updateOuvrages.show = false;
    },
    modalUpdateProposalClose() {
      this.modals.updateProposal.show = false;
    },
    modalUpdateOuvragesValidate() {
      this.updateOuvrages();
      this.modalUpdateOuvragesClose();
    },
    async handleFileChange(event) {
      const selectedFiles = event.target.files;
      for (const file of selectedFiles) {
        try {
          await this.$apollo.mutate({
            mutation: FILE_UPLOAD,
            variables: {
              file,
              projectId: kalmStore.getters.currentProjectFocus,
              proposalId: this.fileProposalId,
              type: "TENDER_PROPOSAL",
            },
            context: {
              hasUpload: true,
            },
            refetchQueries: [
              {
                query: GET_PROPOSALS_FOR_PROJECT,
                variables: {
                  projectId: kalmStore.getters.currentProjectFocus,
                },
              },
            ],
          });
        } catch (error) {
          console.error("File upload error:", error);
        }
      }
      this.fileProposalId = "";
    },
    openFileExplorer(data) {
      // Trigger the click event on the hidden file input
      this.fileProposalId = data;
      this.$refs.fileInput.click();
    },
    modalFileExplorerShow(data) {
      const proposal = this.proposals.find((p) => p.id === data);
      this.modals.fileExplorer.projectId = kalmStore.getters.currentProjectFocus;
      this.modals.fileExplorer.paramsId = proposal.folderId;
      console.log("folderId", proposal);
      this.modals.fileExplorer.show = true;
    },
    modalFileExplorerClose() {
      this.modals.fileExplorer.show = false;
      this.modals.fileExplorer.proposalId = "";
      this.modals.fileExplorer.projectId = "";
    },
    openModal(arg) {
      const { action, data } = arg;
      switch (action) {
        case "delete":
          this.modalProposalDeleteShow(data);
          break;
        case "edit":
          this.modalProposalEditShow(data);
          break;
        case "duplicate":
          this.modalProposalDuplicateShow(data);
          break;
        case "update":
          this.modalUpdateProposalShow(data);
          break;
        case "download":
          this.modalDownloadShow(data);
          break;
        case "uploadFile":
          this.openFileExplorer(data);
          break;
        case "showFiles":
          this.modalFileExplorerShow(data);
          break;
        case "createProposal":
          this.modalProposalCreateShow(data);
          break;
        case "createEstimation":
          this.modalEstimationCreateShow(data);
          break;
      }
    },
    /*copyLinkToClipboard() {
      const element = this.$refs.dceModalLinkShareField;
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand('copy');
    },*/
  },
};
</script>
