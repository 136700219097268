import gql from 'graphql-tag';

export const GET_REPORTS = gql`
    query ProjectReports($projectId: UUID!) {
        reports: projectReports(projectId: $projectId) {
          id
          reportDate
          reportNumber
          reportName
          reportFileHeader
          reportFileFooter
          file {
              id
              url
              name
              creationDate
              archived
              archivedDate
          }
          prochaineReunion
          importantInfo
          includePlanning
          crIncludedStatus {
              id
              name
              type
          }
          iterationMode
          projectReportContacts {
              id
              isPresent
              attendanceStatus
              isConvoque
              contactProjectParticipation {
                  contact {
                      id
                  }
              }
          }
        }
    }
`;

export const REPORT_CONTACT_INPUT = gql`
  input ReportContactInput {
    contact_id: UUID!
    is_present: Boolean!
    is_convoque: Boolean!
  }
`;

export const REPORT_INPUT = gql`
  input ReportInput {
    project_id: UUID!
    reportDate: Date!
    prochaineReunion: DateTime!
    important_info: String!
    include_planning: Boolean!
    cr_included_status_ids: [UUID]!
    contacts: [ReportContactInput]!
    iteration_mode: String!
    report_number: Int!
    report_file_header: String
    report_file_footer: String
  }
`;

export const CREATE_REPORT = gql`
  mutation ProjectReportCreate($input: ReportInput!) {
    projectReportCreate(input: $input) {
      projectReport {
        id
        reportDate
        reportNumber
        reportFileHeader
        reportFileFooter
        title
        reportName
        file {
            id
            url
            name
            creationDate
            archived
            archivedDate
        }
      }
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation ProjectReportUpdate($id: UUID!, $input: ReportInput!) {
    projectReportUpdate(id: $id, input: $input) {
      projectReport {
        id
        title
        reportDate
        reportNumber
        reportFileHeader
        reportFileFooter
        reportName
        file {
            id
            url
            name
            creationDate
            archived
            archivedDate
        }
      }
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation ProjectReportDelete($id: UUID!) {
    projectReportDelete(id: $id) {
      deleted
    }
  }
`;