<template>
  <div>
    <label class="col-form-label">{{ title }}</label>
    <select v-model="selectedUserIdInternal" class="form-select form-select-solid">
      <option :value="null">Non attribué</option>
      <option v-for="user in currentUser.org.team" :key="user.id" :value="user.id">
        {{ user.firstName + " " + user.lastName }}
      </option>
    </select>
  </div>
</template>

<script>
import { CURRENT_USER_QUERY } from "@/views/users/data/users_graphql";
export default {
  name: "UserSelect",
  props: {
    title: String,
    modelValue: {
      type: String,
      default: "",
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
  },
  computed: {
    selectedUserIdInternal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
