<template>
  <KalmModal :modalTitle="getTitle()" @close="close">
    <template v-slot:body>
      <form @submit.prevent="createTask">
        <LotSelect v-model="lotId" title="Lot" :projectId="projectId"></LotSelect>
        <div class="mb-3">
          <label for="title" class="col-form-label">Nom de la tâche</label>
          <input v-model="title" type="text" class="form-control" id="title" />
        </div>
        <div class="mb-3">
          <label for="description" class="col-form-label">Description</label>
          <textarea
            v-model="description"
            type="text"
            class="form-control"
            id="description"
            style="white-space: pre-wrap"
          ></textarea>
        </div>
        <p class="col-form-label">Emplacement :</p>
        <LocationMultiselect
          v-model="locationIds"
          :options="locationOptions"
          @validate="this.$apollo.queries.project.refetch()"
        ></LocationMultiselect>
        <StatusSelect v-model="statusId" title="Status"></StatusSelect>
        <div class="mt-3 w-100">
          <label for="color" class="col-form-label">Tâche mise en avant (couleur) :</label>
          <div class="w-100 d-flex">
            <input v-model="this.color" type="radio" class="btn-check" id="color_default" :value="null" />
            <label class="flex-grow-1 btn btn-light rounded-end-0" for="color_default">Par défaut</label>

            <input
              v-model="this.color"
              type="radio"
              class="btn-check"
              id="color_persoColor1"
              :value="this.currentUser?.org?.persoColor1"
            />
            <label class="btn btn-icon btn-light rounded-0" for="color_persoColor1"
              ><span
                class="rounded-circle h-20px w-20px text-white"
                :style="'background-color: ' + this.currentUser?.org?.persoColor1"
                >1</span
              ></label
            >

            <input
              v-model="this.color"
              type="radio"
              class="btn-check"
              id="color_persoColor2"
              :value="this.currentUser?.org?.persoColor2"
            />
            <label class="btn btn-icon btn-light rounded-0" for="color_persoColor2"
              ><span
                class="rounded-circle h-20px w-20px text-white"
                :style="'background-color: ' + this.currentUser?.org?.persoColor2"
                >2</span
              ></label
            >

            <input
              v-model="this.color"
              type="radio"
              class="btn-check"
              id="color_persoColor3"
              :value="this.currentUser?.org?.persoColor3"
            />
            <label class="btn btn-icon btn-light rounded-0" for="color_persoColor3"
              ><span
                class="rounded-circle h-20px w-20px text-white"
                :style="'background-color: ' + this.currentUser?.org?.persoColor3"
                >3</span
              ></label
            >

            <input
              v-model="this.color"
              type="radio"
              class="btn-check"
              id="color_persoColor4"
              :value="this.currentUser?.org?.persoColor4"
            />
            <label class="btn btn-icon btn-light rounded-0" for="color_persoColor4"
              ><span
                class="rounded-circle h-20px w-20px text-white"
                :style="'background-color: ' + this.currentUser?.org?.persoColor4"
                >4</span
              ></label
            >

            <input
              v-model="this.color"
              type="radio"
              class="btn-check"
              id="color_persoColor5"
              :value="this.currentUser?.org?.persoColor5"
            />
            <label class="btn btn-icon btn-light rounded-0" for="color_persoColor5"
              ><span
                class="rounded-circle h-20px w-20px text-white"
                :style="'background-color: ' + this.currentUser?.org?.persoColor5"
                >5</span
              ></label
            >

            <input
              v-model="this.color"
              type="radio"
              class="btn-check"
              id="color_persoColor6"
              :value="this.currentUser?.org?.persoColor6"
            />
            <label class="btn btn-icon btn-light rounded-start-0" for="color_persoColor6"
              ><span
                class="rounded-circle h-20px w-20px text-white"
                :style="'background-color: ' + this.currentUser?.org?.persoColor6"
                >6</span
              ></label
            >
          </div>
        </div>
        <ContactSelect v-model="assignedCompanyId" :contacts="contactOptions" title="Responsable"></ContactSelect>
        <p class="col-form-label">Concerne aussi :</p>
        <ContactMultiSelect v-model="contactIds" :options="contactOptions"></ContactMultiSelect>
        <div class="mb-3">
          <label for="quantity" class="col-form-label">Quantité</label>
          <input v-model="qt" type="number" class="form-control" id="quantity" />
        </div>
        <!--                <div class="mb-3">
                        <label for="ouvrage" class="col-form-label">Identifiant ouvrage</label>
                        <input v-model="ouvrageId" type="text" class="form-control" id="ouvrage">
                    </div>-->
        <div class="input-group input-group-solid mb-3 mt-6">
          <label class="input-group-text">Début :</label>
          <input type="date" class="form-control form-control-solid" v-model="startDate" />
        </div>
        <div class="input-group input-group-solid mb-3">
          <label class="input-group-text">Fin :</label>
          <input type="date" class="form-control form-control-solid" v-model="endDate" />
        </div>
        <div class="mb-3">
          <label for="title" class="col-form-label">Ordre personnalisé</label>
          <input v-model="order" type="text" class="form-control" id="order" />
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
      <LoadingButton v-if="isSaving"></LoadingButton>
      <button v-if="!isSaving" type="button" class="btn btn-primary" @click="createTask" :disabled="!canSave()">
        Sauvegarder
      </button>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import LoadingButton from "@/views/_core/components/LoadingButton.vue";
import ContactSelect from "@/views/contacts/presentation/contact-select/ContactSelect.vue";
import ContactMultiSelect from "@/views/contacts/presentation/contact-select/ContactMultiSelect.vue";
import LocationMultiselect from "@/views/locations/presentation/location-select/LocationMultiselect.vue";
import LotSelect from "@/views/lots/presentation/lot-select/LotSelect.vue";
import StatusSelect from "@/views/status/presentation/status-select/StatusSelect.vue";
import { CREATE_TASK, GET_TASKS, UPDATE_TASK } from "../../data/tasks_graphql";
import kalmStore from "@/store";
import { CURRENT_USER_QUERY, GET_PROJECT_BY_ID } from "@/graphql/graphql";

export default {
  name: "TaskForm",
  props: {
    task: Object,
    initialLotId: String,
    initialLocationId: String,
    initialProjectId: String,
    initialStatusId: String,
  },
  apollo: {
    currentUser: CURRENT_USER_QUERY,
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: kalmStore.getters.currentProjectFocus,
        };
      },
    },
  },
  components: {
    KalmModal,
    ContactSelect,
    LotSelect,
    StatusSelect,
    LocationMultiselect,
    LoadingButton,
    ContactMultiSelect,
  },
  data() {
    return {
      currentUser: null,
      title: undefined, // required
      description: undefined,
      lotId: null, // required
      projectId: undefined, // required
      statusId: undefined,
      color: "",
      assignedCompanyId: null,
      locationIds: [],
      contactIds: [],
      startDate: null,
      endDate: null,
      order: undefined,
      qt: undefined,
      //ouvrageId: undefined,
      isSaving: false,
    };
  },
  created() {
    this.initializeData();
  },
  computed: {
    locationOptions() {
      const project = this.project;
      return project?.locations;
    },
    contactOptions() {
      const project = this.project;
      return project?.contacts.map((c) => c.contact) || [];
    },
  },
  methods: {
    initializeData() {
      if (this.initialProjectId) {
        this.projectId = this.initialProjectId;
      }
      if (this.initialLotId) {
        this.lotId = this.initialLotId;
      }
      if (this.initialLocationId) {
        this.locationIds = [this.initialLocationId];
      }
      if (this.initialStatusId !== undefined) {
        this.statusId = this.initialStatusId;
      }
      if (this.task) {
        this.title = this.task.title;
        this.description = this.task.description;
        this.lotId = this.task?.lot?.id || null;
        this.projectId = this.task.project.id;
        this.statusId = this.task.status?.id;
        this.color = this.task?.color;
        this.assignedCompanyId = this.task.assignedCompany?.id || null;
        this.locationIds = this.task.locations.map((location) => location.id);
        this.contactIds = this.task.contacts.map((contact) => contact.id);
        this.startDate = this.task.startDate;
        this.endDate = this.task.endDate;
        this.order = this.task.order;
        this.qt = this.task.qt;
        //this.ouvrageId = this.task.ouvrageId;
      }
    },

    getTitle() {
      if (this.task) {
        return "Modifier la tâche";
      }
      return "Créer une tâche";
    },
    canSave() {
      return !!this.title && !!this.projectId;
    },
    async createTask() {
      this.isSaving = true;
      const input = {
        title: this.title,
        description: this.description,
        lotId: this?.lotId || null,
        projectId: this.projectId,
        statusId: this.statusId,
        contactIds: this.contactIds,
        color: this.color,
        assignedCompanyId: this.assignedCompanyId,
        locationIds: this.locationIds,
        startDate: this?.startDate || null, // null is important if empty
        endDate: this?.endDate || null, // null is important if empty
        order: this.order,
        qt: this.qt?.toString(),
        //ouvrageId: this.ouvrageId,
      };
      if (this.task) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_TASK,
            variables: {
              id: this.task.id,
              input,
            },
            refetchQueries: [
              {
                query: GET_TASKS,
                variables: {
                  projectId: this.projectId,
                },
              },
            ],
          });
        } catch (error) {
          console.error("Update task error:", error);
        }
      } else {
        try {
          await this.$apollo.mutate({
            mutation: CREATE_TASK,
            variables: {
              input,
            },
            refetchQueries: [
              {
                query: GET_TASKS,
                variables: {
                  projectId: this.projectId,
                },
              },
            ],
          });
        } catch (error) {
          console.error("Create task error:", error);
        }
      }
      this.isSaving = false;
      this.$emit("refetchTasks");
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
