<template>
  <!-- MAIN -->
  <div :class="modalActive ? 'blur-content' : ''">
    <PrintHeader
      pageTitle="Planning"
      :sectionTitle="this.printMode === 'project' ? 'Phases du projet' : 'Planning de chantier'"
    ></PrintHeader>
    <div class="row gy-5">
      <div class="col-12 print-hidden">
        <div class="alert bg-light-primary border border-dashed border-primary d-flex flex-column flex-sm-row">
          <div class="">
            <span class="bi bi-cone-striped text-primary me-4 fs-1 align-middle"></span>
          </div>
          <div class="d-flex flex-column pe-0 pe-sm-10">
            <h5 class="mb-1">Version beta</h5>
            <span
              >Cette page est encore en développement, il manque quelques fonctionnalités et des erreurs peuvent s'y
              glisser.<br />Si vous avez des suggestions,
              <a :href="'https://tally.so/r/nWEr4L?user_email=' + currentUser.email" target="_blank" class=""
                >n'hésitez pas à nous en parler !</a
              ></span
            >
          </div>
          <button
            type="button"
            class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
            data-bs-dismiss="alert"
          >
            <i class="bi bi-x fs-1"></i>
          </button>
        </div>
      </div>
      <div class="col-12" :class="this.printMode === 'chantier' ? 'print-hidden' : ''">
        <div class="card">
          <div class="d-flex card-header border-0 pt-5 print-hidden">
            <div>
              <h3 class="card-title align-text-bottom flex-column print-hidden">
                <span class="card-label fw-bolder text-dark fs-3"
                  ><i class="bi fs-3 me-2 bi-calendar4-event"></i>Phases du projet</span
                >
              </h3>
            </div>
            <div>
              <select
                class="form-select align-baseline form-select-solid py-2 print-hidden"
                v-model="this.currentPhase"
              >
                <option :value="null" disabled>Pas de phase en cours</option>
                <option v-for="(item, index) in this.timeline" :value="item.name" :key="index">{{ item.name }}</option>
              </select>
            </div>
            <div class="ms-auto">
              <button
                type="button"
                @click="this.printPlanningProject()"
                class="btn me-1 py-2 btn-active-light btn-color-muted fw-bolder print-hidden"
              >
                <span class="bi align-baseline bi-download me-2"></span>Télécharger
              </button>
            </div>
          </div>
          <div class="card-body w-100 row pt-2">
            <div class="d-flex gap-4 w-100 flex-wrap">
              <div
                v-for="(item, index) in timeline"
                :key="item.id"
                class="timeline-card border rounded planning-card-shadow flex-grow-1 flex-column d-flex text-center"
                style="min-width: 150px; max-width: 50%; break-inside: avoid"
              >
                <div class="d-flex justify-content-between text-muted px-3 pt-3 print-hidden">
                  <div
                    :class="{ 'cursor-pointer': index > 0 }"
                    @click="modalPlanningMoveItem(index, timeline.length, 'left')"
                  >
                    &lt;
                  </div>
                  <div>
                    <div class="cursor-pointer d-inline-block" @click="modalPlanningEditItemShow(index)">
                      <span class="bi bi-pencil-fill"></span>
                    </div>
                    <div class="cursor-pointer d-inline-block ms-3" @click="modalPlanningRemoveItem(index)">
                      <span class="bi bi-trash-fill"></span>
                    </div>
                  </div>
                  <div
                    :class="{ 'cursor-pointer': index < timeline.length - 1 }"
                    @click="modalPlanningMoveItem(index, timeline.length, 'right')"
                  >
                    &gt;
                  </div>
                </div>
                <div class="p-3">
                  <div class="fw-bold fs-3">{{ item.name }}</div>
                  <div class="">Début : {{ item.startDate ? new Date(item.startDate).toLocaleDateString() : "—" }}</div>
                  <div class="">Fin : {{ item.endDate ? new Date(item.endDate).toLocaleDateString() : "—" }}</div>
                  <div
                    v-if="item?.comment"
                    class="text-muted text-break mt-2 justify-content-center w-100"
                    style="white-space: pre-wrap"
                  >
                    <span class="bi bi-chat-left-text me-1"></span> Commentaire :<br />
                    <span>{{ item.comment }}</span>
                  </div>
                </div>
              </div>
              <div
                class="w-30px timeline-card d-flex flex-column justify-content-center text-center cursor-pointer print-hidden"
              >
                <span class="bi bi-plus-circle-dotted print-hidden" @click="this.modalPlanningAddItemShow()"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" :class="this.printMode === 'chantier' ? 'print-hidden' : ''">
        <div class="card print-hidden">
          <div class="d-flex card-header border-0 pt-5 justify-content-between">
            <div>
              <h3 class="card-title align-text-bottom flex-column print-hidden">
                <span class="card-label fw-bolder text-dark fs-3"
                  ><i class="bi fs-3 me-2 bi-calendar4-event"></i>Planning</span
                >
              </h3>
            </div>
            <div class="d-flex align-baseline">
              <div class="me-2">
                <div class="input-group input-group-solid">
                  <label class="input-group-text text-muted">Début :</label>
                  <input type="date" class="form-control form-control-solid" v-model="this.ganttConfig.startDate" />
                </div>
              </div>
              <div class="me-2">
                <div class="input-group input-group-solid">
                  <label class="input-group-text text-muted">Précision :</label>
                  <select class="form-select form-select-solid" v-model="this.ganttConfig.precision">
                    <option value="day">Jour</option>
                    <option value="week">Semaine</option>
                    <option value="month">Mois</option>
                  </select>
                </div>
              </div>
              <div>
                <div class="input-group input-group-solid">
                  <label class="input-group-text text-muted">Fin :</label>
                  <input type="date" class="form-control form-control-solid" v-model="this.ganttConfig.endDate" />
                </div>
              </div>
            </div>
            <div class="">
              <button
                type="button"
                @click="this.printPlanningProject()"
                class="btn me-1 py-2 btn-active-light btn-color-muted fw-bolder print-hidden"
              >
                <span class="bi align-baseline bi-download me-2"></span>Télécharger
              </button>
            </div>
          </div>
          <div class="card-body">
            <div
              v-if="
                this.validatePhasesSchema &&
                this.timeline &&
                this.currentProject &&
                this.ganttConfig.startDate &&
                this.ganttConfig.endDate
              "
              class="d-flex"
              ref="content"
            >
              <g-gantt-chart
                date-format="YYYY-MM-DD"
                :chart-start="this.ganttConfig.startDate || Date.now()"
                :chart-end="this.ganttConfig.endDate || Date.now()"
                :precision="this.ganttConfig.precision"
                :row-height="50"
                grid
                bar-start="startDate"
                bar-end="endDate"
                class="w-100"
              >
                <template v-for="(phase, index) in this.timeline" :key="index">
                  <g-gantt-row
                    :label="phase.name"
                    :bars="[
                      {
                        startDate: phase.startDate,
                        endDate: phase.endDate,
                        ganttBarConfig: {
                          id: 'bar2',
                          mobile: true,
                          hasHandles: true,
                          label: phase.name,
                          style: {
                            background: phase?.bgColor || this.currentUser.org.persoColorMain,
                            borderRadius: '5px',
                            color: phase?.textColor || 'white',
                          },
                        },
                      },
                    ]"
                  />
                  <template v-if="phase?.type === 'CHANTIER'">
                    <template v-for="lot in this.project?.lots" :key="lot.id">
                      <g-gantt-row
                        :label="lot.fullName"
                        v-if="lot?.startDate && lot?.endDate"
                        :bars="[
                          {
                            startDate: lot.startDate,
                            endDate: lot.endDate,
                            ganttBarConfig: {
                              id: lot.id,
                              hasHandles: true,
                              label: lot.fullName,
                              style: {
                                background: phase?.bgColor || this.currentUser.org.persoColorMain,
                                borderRadius: '5px',
                                color: phase?.textColor || 'white',
                              },
                            },
                          },
                        ]"
                      />
                    </template>
                  </template>
                </template>
              </g-gantt-chart>
            </div>
            <div v-else>
              Pour afficher le planning en format Gantt, entrez une date sur chacune des phases ci-dessus.
            </div>
          </div>
        </div>
        <div class="card print-planning">
          <div class="card-body">
            <img
              v-if="this.planningImageUrl && this.printMode === 'project'"
              :src="this.planningImageUrl"
              class="mw-100"
              alt="capture d'écran du planning pour l'impression"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card pt-3 w-100">
          <div class="card-header w-100 border-0 print-hidden justify-content-between">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"
                ><i class="bi fs-3 me-2 bi-calendar4-event"></i>Planning de chantier</span
              >
            </h3>
            <div class="card-toolbar text-end print-hidden">
              <button
                type="button"
                @click="this.printPlanningChantier()"
                class="btn me-1 btn-active-light btn-color-muted fw-bolder print-hidden"
              >
                <span class="bi align-baseline bi-download"></span> Télécharger
              </button>
            </div>
          </div>
          <div class="card-body" :class="this.printMode === 'project' ? 'print-hidden' : ''">
            <div class="table-responsive">
              <table class="table table-borderless mb-0 align-middle">
                <thead>
                  <tr>
                    <th class="p-0 min-w-150px"></th>
                    <th class="p-0 min-w-50px"></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="lot in currentProject.lots" :key="lot.id">
                    <tr style="break-inside: avoid; break-after: avoid">
                      <td class="px-0" :class="lot.number.includes('.') ? 'ps-5' : ''">
                        <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ lot.fullName }}</a>
                        <span class="text-gray-700 fw-bold d-block mt-1">{{
                          lot.assignedCompany?.id
                            ? lot.assignedCompany.company || lot.assignedCompany.name
                            : "Non attribué"
                        }}</span>
                        <span v-if="lot.startDate || lot.endDate" class="text-gray-700 fw-light d-block mt-1"
                          ><i class="bi bi-calendar4-event me-2"></i
                          >{{ lot.startDate ? new Date(lot.startDate).toLocaleDateString() : "À déterminer" }} >
                          {{ lot.endDate ? new Date(lot.endDate).toLocaleDateString() : "À déterminer" }}</span
                        >
                      </td>
                      <td class="text-end w-100px pe-0 print-hidden">
                        <button
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                          v-tooltip:bottom="'Modifier'"
                          @click="modalEditLotPlanningShow(lot.id)"
                        >
                          <span class="bi bi-pencil-fill"></span>
                        </button>
                      </td>
                    </tr>
                    <tr style="break-inside: avoid">
                      <td class="p-0 row" :class="lot.number.includes('.') ? 'ps-5' : 'ps-0'">
                        <div class="col-1" style="width: 20px">
                          <i class="bi bi-chat-text align-baseline me-2"></i>
                        </div>
                        <div class="col-11" style="">
                          <p v-if="lot.comment" class="text-gray-700 fw-light fs-6" style="white-space: pre-wrap">
                            {{ lot.comment }}
                          </p>
                          <p v-else class="text-gray-700 fw-light fs-6">Aucun commentaire</p>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL EDIT LOT -->
  <div class="modal" tabindex="-1" :style="modals.editLotPlanning.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditLotPlanningCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-1">
                        <h3 class="fw-bolder text-dark display-6">Modifier un lot</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                        <label class="ms-1 fs-8 text-muted form-label"></label>
                        <input
                          type="date"
                          v-model="modals.editLotPlanning.lot.startDate"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="2023-07-01"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                        <label class="ms-1 fs-8 text-muted form-label"></label>
                        <input
                          type="date"
                          v-model="modals.editLotPlanning.lot.endDate"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="2023-08-01"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaire pour le CR</label>
                        <textarea
                          v-model="modals.editLotPlanning.lot.comment"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Pose du parquet dans les chambres lundi et mardi"
                        ></textarea>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalEditLotPlanningCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalEditLotPlanningValidate" class="btn btn-lg btn-primary ms-2">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL ADD PLANNING ITEM -->
  <div class="modal" tabindex="-1" :style="modalPlanningAddItem.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalPlanningAddItemCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Ajouter une phase</h3>
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom</label>
                        <input
                          type="text"
                          v-model="this.modalPlanningAddItem.name"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="APS"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                        <input
                          type="date"
                          v-model="this.modalPlanningAddItem.startDate"
                          class="form-control form-control-lg form-control-solid"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                        <input
                          type="date"
                          v-model="this.modalPlanningAddItem.endDate"
                          class="form-control form-control-lg form-control-solid"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Type de phase</label>
                        <select
                          class="form-select align-baseline form-select-solid print-hidden"
                          v-model="this.modalPlanningAddItem.type"
                        >
                          <option value="">Pas de type</option>
                          <option value="CONCEPTION">Conception</option>
                          <option value="CONSULTATION">Consultation</option>
                          <option value="CHANTIER">Chantier</option>
                        </select>
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaires</label>
                        <textarea
                          class="form-control form-control-solid form-text"
                          v-model="this.modalPlanningAddItem.comment"
                        ></textarea>
                      </div>

                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Couleur</label>
                        <input
                          v-model="this.modalPlanningAddItem.bgColor"
                          type="color"
                          class="form-control form-control-lg form-control-solid"
                          placeholder=""
                        />
                      </div>

                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Couleur du texte</label>
                        <input
                          v-model="this.modalPlanningAddItem.textColor"
                          type="color"
                          class="form-control form-control-lg form-control-solid"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalPlanningAddItemCancel()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button
                        type="button"
                        @click="this.modalPlanningAddItemValidate()"
                        :disabled="!this.modalPlanningAddItem.name"
                        class="btn btn-lg btn-primary ms-2"
                      >
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL EDIT PLANNING ITEM -->
  <div class="modal" tabindex="-1" :style="modals.planningEditItem.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalPlanningEditItemCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Modifier une phase</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom</label>
                        <input
                          type="text"
                          v-model="this.modals.planningEditItem.item.name"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="APS"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                        <input
                          type="date"
                          v-model="this.modals.planningEditItem.item.startDate"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="01/01/2024"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                        <input
                          type="date"
                          v-model="this.modals.planningEditItem.item.endDate"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="31/01/2024"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Type de phase</label>
                        <select
                          class="form-select align-baseline form-select-solid print-hidden"
                          v-model="this.modals.planningEditItem.item.type"
                        >
                          <option value="">Pas de type</option>
                          <option value="CONCEPTION">Conception</option>
                          <option value="CONSULTATION">Consultation</option>
                          <option value="CHANTIER">Chantier</option>
                        </select>
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaires</label>
                        <textarea
                          class="form-control form-control-solid form-text"
                          v-model="this.modals.planningEditItem.item.comment"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalPlanningEditItemCancel()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button
                        type="button"
                        @click="this.modalPlanningEditItemValidate()"
                        :disabled="!this.modals.planningEditItem.item.name"
                        class="btn btn-lg btn-primary ms-2"
                      >
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!--suppress PointlessBooleanExpressionJS -->
<script>
import {
  CURRENT_USER_QUERY,
  GET_PROJECT_BY_ID,
  MUTATION_PROJECT_UPDATE_PHASES,
  MUTATION_PROJECT_CREATE_LOCATION,
  MUTATION_PROJECT_CREATE_LOT,
} from "@/graphql/graphql";
import kalmStore from "@/store";
import { ALL_CONTACTS_QUERY } from "@/views/contacts/data/contacts_graphql";
import PrintHeader from "@/views/project/tender/PrintHeader";
import domtoimage from "dom-to-image";
import { GGanttRow } from "@infectoone/vue-ganttastic";

export default {
  name: "ProjectPlanning",
  components: {
    GGanttRow,
    PrintHeader,
  },
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: kalmStore.getters.currentProjectFocus,
        };
      },
      skip() {
        return (
          !kalmStore.getters.currentProjectFocus ||
          kalmStore.getters.currentProjectFocus === 0 ||
          kalmStore.getters.currentProjectFocus === "0"
        );
      },
    },
  },
  data() {
    return {
      ganttConfig: {
        precision: "week",
        startDate: "",
        endDate: "",
      },
      project: null,
      currentUser: {},
      contacts: [],
      projects: [],
      currentProject: [],
      lots: [],
      locations: [],
      modals: {
        importLot: {
          show: false,
          page: 0,
          projectId: "",
          lots: [],
        },
        importLocation: {
          show: false,
          page: 0,
          projectId: "",
          locations: [],
        },
        planningEditItem: {
          show: false,
          item: {
            name: "",
            startDate: "",
            endDate: "",
            type: "",
            comment: "",
          },
        },
        editLotPlanning: {
          show: false,
          lotId: "",
          lot: {
            comment: "",
            startDate: "",
            endDate: "",
          },
        },
      },
      modalLot: {
        show: false,
        default: {
          number: 0,
          name: "Nouveau Lot",
          assignedCompanyId: null,
        },
        current: {
          number: 0,
          name: "Nouveau Lot",
          assignedCompanyId: null,
        },
      },
      modalConfirmDeleteLot: {
        show: false,
        id: "",
      },
      modalEditLot: {
        show: false,
        newLot: {
          id: "",
          number: "",
          name: "",
          assignedCompanyId: null,
        },
      },
      modalLocation: {
        show: false,
        default: {
          name: "Nouvel Emplacement 01",
        },
        current: {
          name: "Nouvel Emplacement 01",
        },
      },
      modalConfirmDeleteLocation: {
        show: false,
        id: "",
      },
      modalEditLocation: {
        show: false,
        id: "",
        newLocation: {
          id: "",
          name: "",
        },
      },
      modalAssignContact: {
        show: false,
        default: {
          contactId: "",
          role: "CONTRACTOR",
        },
        current: {
          contactId: "",
          role: "CONTRACTOR",
        },
      },
      modalPlanningAddItem: {
        show: false,
        name: "",
        startDate: "",
        endDate: "",
        type: "",
        comment: "",
        textColor: "",
        bgColor: "",
      },
      modalUnassignContact: {
        show: false,
        contactId: "",
      },
      contact_role_options: [
        {
          id: "MOA",
          name: "MOA - Maîtrise d'Ouvrage",
        },
        {
          id: "MOE",
          name: "MOE - Maîtrise d'Œuvre",
        },
        {
          id: "CONTRACTOR",
          name: "Entreprise",
        },
      ],
      editProject: {
        name: "",
        address: "",
        infoOpName: "",
        importantInfo: "",
        archived: "",
      },
      buttonProjectPropertiesLoading: false,
      localTimeline: null,
      localCurrentPhase: null,
      planningImageUrl: "",
      printMode: "project",
    };
  },
  mounted() {
    this.ganttConfig.startDate = "";
    this.ganttConfig.endDate = "";

    this.localCurrentPhase = this.project?.currentPhase;
    if (!(this.timeline || []).map((i) => i.name).includes(this.localCurrentPhase)) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.localCurrentPhase = null;
    }

    if (!this.ganttConfig.startDate || !this.ganttConfig.endDate) {
      (this?.timeline || []).forEach((item) => {
        if (!this.ganttConfig.startDate || (item.startDate && item.startDate < this.ganttConfig.startDate))
          this.ganttConfig.startDate = item.startDate;
        if (!this.ganttConfig.endDate || (item.endDate && item.endDate > this.ganttConfig.endDate))
          this.ganttConfig.endDate = item.endDate;
      });
    }
  },
  methods: {
    printPlanningProject() {
      this.printMode = "project";
      // makes a screenshot of gantt planning and adds it into a print-only div
      domtoimage
        .toPng(this.$refs.content)
        .then((dataUrl) => (this.planningImageUrl = dataUrl))
        .then(() => {
          this.$nextTick(function () {
            window.print();
          });
        });
    },
    printPlanningChantier() {
      this.printMode = "chantier";
      this.$nextTick(function () {
        window.print();
      });
    },
    getContactById(contactId) {
      return this.contacts.find((c) => c.id === contactId);
    },
    getLotById(lotId) {
      return this.project?.lots.find((l) => l.id === lotId);
    },
    getLocationById(locationId) {
      return this.project?.locations.find((l) => l.id === locationId);
    },
    modalImportLotShow() {
      this.modals.importLot.show = true;
    },
    modalImportLotClose() {
      this.modals.importLot.show = false;
      this.modals.importLot.page = 0;
      this.modals.importLot.lots = [];
      this.modals.importLot.projectId = "";
    },
    modalImportLotFetch() {
      return this.currentProject?.lots;
    },
    async modalImportLotValidate() {
      this.modals.importLot.lots.forEach(async (importedlotId) => {
        const importedLot = kalmStore.getters
          .getProjectById(this.modals.importLot.projectId)
          .lots.find((i) => i.id === importedlotId);

        try {
          await this.$apollo.mutate({
            mutation: MUTATION_PROJECT_CREATE_LOT,
            variables: {
              newLot: {
                number: "" + importedLot.number || "0",
                name: importedLot.name || "Nouveau Lot",
                assignedCompany: importedLot.assignedCompany?.id || null,
              },
              projectId: kalmStore.getters.currentProjectFocus,
            },
          });
        } catch (e) {
          console.error(e);
        }
      });

      this.modalImportLotClose();
    },
    modalImportLocationShow() {
      this.modals.importLocation.show = true;
    },
    modalImportLocationClose() {
      this.modals.importLocation.show = false;
      this.modals.importLocation.page = 0;
      this.modals.importLocation.locations = [];
      this.modals.importLocation.projectId = "";
    },
    modalImportLocationFetch() {
      return this.currentProject?.locations;
    },
    async modalImportLocationValidate() {
      this.modals.importLocation.locations.forEach(async (importedlocationId) => {
        const importedLocation = kalmStore.getters
          .getProjectById(this.modals.importLocation.projectId)
          .locations.find((i) => i.id === importedlocationId);

        try {
          await this.$apollo.mutate({
            mutation: MUTATION_PROJECT_CREATE_LOCATION,
            variables: {
              newLocation: {
                name: importedLocation.name,
              },
              projectId: kalmStore.getters.currentProjectFocus,
            },
            refetchQueries: [
              {
                query: GET_PROJECT_BY_ID,
                variables: {
                  id: kalmStore.getters.currentProjectFocus,
                },
              },
            ],
          });
        } catch (e) {
          console.error(e);
        }
      });

      this.modalImportLocationClose();
    },
    modalLotShow() {
      this.modalLot.current = {
        id: this.modalLot.default.id,
        name: this.modalLot.default.name,
        number: this.modalLot.default.number,
        assignedCompanyId: this.modalLot.default.assignedCompanyId,
      };
      this.modalLot.show = true;
    },
    modalLotCancel() {
      this.modalLot.current = this.modalLot.default;
      this.modalLot.show = false;
    },
    modalLotValidate() {
      const payload = {
        number: this.modalLot.current.number,
        name: this.modalLot.current.name,
        assignedCompany: this.modalLot.current.assignedCompanyId,
        apolloClient: this.$apollo,
      };

      kalmStore.dispatch("addLotToFocusProject", payload);

      this.modalLot.show = false;
    },
    modalEditLotPlanningShow(lotId) {
      this.modals.editLotPlanning.lotId = lotId;

      const lot = this.project?.lots.find((l) => l.id === lotId);

      this.modals.editLotPlanning.lot.comment = lot.comment;
      this.modals.editLotPlanning.lot.startDate = lot.startDate;
      this.modals.editLotPlanning.lot.endDate = lot.endDate;

      this.modals.editLotPlanning.show = true;
    },
    modalEditLotPlanningCancel() {
      this.modals.editLotPlanning.show = false;

      this.modals.editLotPlanning.lotId = "";
      this.modals.editLotPlanning.lot.comment = "";
      this.modals.editLotPlanning.lot.startDate = "";
      this.modals.editLotPlanning.lot.endDate = "";
    },
    modalEditLotPlanningValidate() {
      kalmStore.dispatch("editLotPlanning", {
        lotId: this.modals.editLotPlanning.lotId,
        apolloClient: this.$apollo,
        startDate: this.modals.editLotPlanning.lot.startDate,
        endDate: this.modals.editLotPlanning.lot.endDate,
        comment: this.modals.editLotPlanning.lot.comment,
      });

      this.modalEditLotPlanningCancel();
    },
    modalConfirmDeleteLotShow(id) {
      this.modalConfirmDeleteLot.id = id;
      this.modalConfirmDeleteLot.show = true;
    },
    modalConfirmDeleteLotValidate() {
      const lotId = this.modalConfirmDeleteLot.id;
      if (lotId) {
        kalmStore.dispatch("deleteLot", { lotId, apolloClient: this.$apollo });
      }

      this.modalConfirmDeleteLot.id = "";
      this.modalConfirmDeleteLot.show = false;
    },
    modalConfirmDeleteLotCancel() {
      this.modalConfirmDeleteLot.show = false;
    },
    modalEditLotShow(id) {
      this.modalEditLot.id = id;
      const oldLot = JSON.parse(JSON.stringify(this.getLotById(id)));
      this.modalEditLot.newLot = {
        id: oldLot.id,
        name: oldLot.name,
        number: oldLot.number,
        assignedCompanyId: oldLot.assignedCompany?.id || null,
      };
      this.modalEditLot.show = true;
    },
    modalEditLotValidate() {
      const lotId = this.modalEditLot.id;

      const newLot = {
        id: lotId,
        number: this.modalEditLot.newLot.number || "",
        name: this.modalEditLot.newLot.name || "",
        assignedCompany: this.modalEditLot.newLot.assignedCompanyId || null,
      };

      kalmStore.dispatch("editLot", { lotId, newLot, apolloClient: this.$apollo });

      this.modalEditLot.id = "";
      this.modalEditLot.show = false;
    },
    modalEditLotCancel() {
      this.modalEditLot.show = false;
      this.modalEditLot.id = "";
      this.modalEditLot.newLot = "";
    },
    modalConfirmDeleteLocationShow(id) {
      this.modalConfirmDeleteLocation.id = id;
      this.modalConfirmDeleteLocation.show = true;
    },
    modalConfirmDeleteLocationValidate() {
      const locationId = this.modalConfirmDeleteLocation.id;
      if (locationId) {
        kalmStore.dispatch("deleteLocation", { locationId, apolloClient: this.$apollo });
      }

      this.modalConfirmDeleteLocation.id = "";
      this.modalConfirmDeleteLocation.show = false;
    },
    modalConfirmDeleteLocationCancel() {
      this.modalConfirmDeleteLocation.show = false;
    },
    modalLocationShow() {
      this.modalLocation.current.name = this.modalLocation.default.name;
      this.modalLocation.show = true;
    },
    modalLocationCancel() {
      this.modalLocation.current = this.modalLocation.default;
      this.modalLocation.show = false;
    },
    async modalLocationValidate() {
      const payload = {
        number: this.modalLocation.current.number,
        name: this.modalLocation.current.name,
        apolloClient: this.$apollo,
      };

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_PROJECT_CREATE_LOCATION,
          variables: {
            newLocation: {
              name: payload.name,
            },
            projectId: kalmStore.getters.currentProjectFocus,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_BY_ID,
              variables: {
                id: kalmStore.getters.currentProjectFocus,
              },
            },
          ],
        });
      } catch (e) {
        console.error(e);
      }

      this.modalLocation.show = false;
    },
    modalEditLocationShow(id) {
      this.modalEditLocation.id = id;
      this.modalEditLocation.newLocation = JSON.parse(JSON.stringify(this.getLocationById(id)));
      this.modalEditLocation.show = true;
    },
    modalEditLocationValidate() {
      const locationId = this.modalEditLocation.id;

      const newLocation = {
        id: locationId,
        name: this.modalEditLocation.newLocation.name || "",
      };

      if (locationId) {
        kalmStore.dispatch("editLocation", { locationId, newLocation, apolloClient: this.$apollo });
      }

      this.modalEditLocation.id = "";
      this.modalEditLocation.show = false;
    },
    modalEditLocationCancel() {
      this.modalEditLocation.show = false;
      this.modalEditLocation.id = "";
      this.modalEditLocation.newLocation = "";
    },
    modalAssignContactShow() {
      this.modalAssignContact.current.contactId = this.modalAssignContact.default.contactId;
      this.modalAssignContact.current.role = this.modalAssignContact.default.role;
      this.modalAssignContact.show = true;
    },
    modalAssignContactValidate() {
      this.modalAssignContact.show = false;

      kalmStore.dispatch("assignContact", {
        apolloClient: this.$apollo,
        contactId: this.modalAssignContact.current.contactId,
        role: this.modalAssignContact.current.role,
      });
    },
    modalAssignContactCancel() {
      this.modalAssignContact.show = false;
    },
    validateLotNumberInput(input) {
      let ret = true;
      if (input) {
        ret = true;
      }
      return ret;
    },
    modalUnassignContactShow(contactId) {
      this.modalUnassignContact.show = true;
      this.modalUnassignContact.contactId = contactId;
    },
    modalUnassignContactClose() {
      this.modalUnassignContact.show = false;
      this.modalUnassignContact.contactId = "";
    },
    modalUnassignContactValidate() {
      const contactId = this.modalUnassignContact.contactId;
      const apolloClient = this.$apollo;
      if (contactId) kalmStore.dispatch("unassignContact", { contactId, apolloClient });
      this.modalUnassignContactClose();
    },
    modalPlanningAddItemShow() {
      this.modalPlanningAddItem.show = true;
    },
    modalPlanningAddItemCancel() {
      this.modalPlanningAddItem.show = false;
    },
    modalPlanningAddItemValidate() {
      this.timeline.push({
        name: this.modalPlanningAddItem.name,
        type: this.modalPlanningAddItem.type,
        comment: this.modalPlanningAddItem.comment,
        startDate: this.modalPlanningAddItem.startDate,
        endDate: this.modalPlanningAddItem.endDate,
        bgColor: this.currentUser.org.persoColorMain,
        textColor: "white",
      });

      this.updateProjectPlanning();
      this.modalPlanningAddItemCancel();
    },
    modalPlanningRemoveItem(index) {
      this.timeline.splice(index, 1);

      this.updateProjectPlanning();
    },
    modalPlanningMoveItem(currentIndex, length, direction) {
      if (direction === "left" && currentIndex > 0) {
        const item = this.timeline[currentIndex];
        this.timeline.splice(currentIndex, 1);
        this.timeline.splice(currentIndex - 1, 0, item);
      }
      if (direction === "right" && currentIndex < length - 1) {
        const item = this.timeline[currentIndex];
        this.timeline.splice(currentIndex, 1);
        this.timeline.splice(currentIndex + 1, 0, item);
      }

      this.updateProjectPlanning();
    },
    modalPlanningEditItemShow(index) {
      this.modals.planningEditItem.index = index;
      this.modals.planningEditItem.item = this.timeline[index];
      this.modals.planningEditItem.show = true;
    },
    modalPlanningEditItemCancel() {
      this.modals.planningEditItem.show = false;
      this.modals.planningEditItem.index = null;
      this.modals.planningEditItem.show = null;
    },
    modalPlanningEditItemValidate() {
      this.timeline[this.modals.planningEditItem.index] = this.modals.planningEditItem.item;
      this.updateProjectPlanning();
      this.modalPlanningEditItemCancel();
    },
    updateProjectPlanning() {
      this.$apollo.mutate({
        mutation: MUTATION_PROJECT_UPDATE_PHASES,
        variables: {
          projectId: this.project?.id,
          newPhases: JSON.stringify(this.timeline),
          newCurrentPhase: this.currentPhase,
        },
        refetchQueries: [
          {
            query: GET_PROJECT_BY_ID,
            variables: {
              id: this.project?.id,
            },
          },
        ],
      });
    },
    updateProjectProperties() {
      this.buttonProjectPropertiesLoading = true;

      setTimeout(() => {
        this.buttonProjectPropertiesLoading = false;
      }, 350);

      const apolloClient = this.$apollo;
      const newProperties = {
        name: this.projectProperties.name,
        infoOpName: this.projectProperties.infoOpName,
        address: this.projectProperties.address,
      };

      kalmStore.dispatch("updateProjectProperties", { apolloClient, newProperties });
    },
  },
  computed: {
    validatePhasesSchema() {
      return true;
    },
    modalActive() {
      return (
        kalmStore.state.display.profileToggled ||
        this.modalPlanningAddItem.show ||
        this.modals.planningEditItem.show ||
        this.modals.editLotPlanning.show
      );
    },
    isArchived() {
      return this.project?.archived;
    },
    getProjects() {
      return kalmStore.state.description.projects;
    },
    projectProperties: {
      get() {
        return {
          name: this.editProject.name || this.project?.name,
          address: this.editProject.address || this.project?.address,
          infoOpName: this.editProject.infoOpName || this.project?.infoOpName,
          importantInfo: this.editProject.importantInfo || this.project?.importantInfo,
          archived: this.editProject.archived,
        };
      },
      set(newValue) {
        this.editProject = newValue;
      },
    },
    timeline: {
      get() {
        if (this.project?.phases) {
          if (!this.localTimeline) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.localTimeline = JSON.parse(this.project?.phases);
          }
          return this.localTimeline;
        } else {
          return [];
        }
      },
      set(newValue) {
        this.localTimeline = newValue;
      },
    },
    currentPhase: {
      get() {
        if (!this.localCurrentPhase) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.localCurrentPhase = this.project?.currentPhase;
          if (!(this.timeline || []).map((i) => i.name).includes(this.localCurrentPhase)) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.localCurrentPhase = null;
          }
        }
        return this.localCurrentPhase;
      },
      set(newValue) {
        this.localCurrentPhase = newValue;
        this.updateProjectPlanning();
      },
    },
    canSafelyDeleteLot() {
      return kalmStore.getters.canSafelyDeleteLot(
        kalmStore.state.display.currentProjectFocus,
        this.modalConfirmDeleteLot.id
      );
    },
    canSafelyDeleteLocation() {
      return kalmStore.getters.canSafelyDeleteLocation(
        kalmStore.state.display.currentProjectFocus,
        this.modalConfirmDeleteLocation.id
      );
    },
  },
  watch: {
    currentProject() {
      this.localCurrentPhase = null;
      this.localTimeline = null;
      this.ganttConfig.startDate = "";
      this.ganttConfig.endDate = "";

      if (!this.localCurrentPhase) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.localCurrentPhase = this.project?.currentPhase;
        if (!(this.timeline || []).map((i) => i.name).includes(this.localCurrentPhase)) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.localCurrentPhase = null;
        }
      }

      if (!this.ganttConfig.startDate || !this.ganttConfig.endDate) {
        this.timeline.forEach((item) => {
          if (!this.ganttConfig.startDate || (item.startDate && item.startDate < this.ganttConfig.startDate))
            this.ganttConfig.startDate = item.startDate;
          if (!this.ganttConfig.endDate || (item.endDate && item.endDate > this.ganttConfig.endDate))
            this.ganttConfig.endDate = item.endDate;
        });
      }
    },
    timeline() {
      if (!this.ganttConfig.startDate || !this.ganttConfig.endDate) {
        this.timeline.forEach((item) => {
          if (!this.ganttConfig.startDate || (item.startDate && item.startDate < this.ganttConfig.startDate))
            this.ganttConfig.startDate = item.startDate;
          if (!this.ganttConfig.endDate || (item.endDate && item.endDate > this.ganttConfig.endDate))
            this.ganttConfig.endDate = item.endDate;
        });
      }
    },
  },
};
</script>

<style>
.g-gantt-tooltip {
  display: none !important;
}
.g-gantt-row-label {
  display: none;
  /*background-color: white !important;
    box-shadow: none;
    border-bottom-right-radius: 0;
    height: 100%;
    width: 300px;*/
}
.g-gantt-row-bars-container {
  border-bottom: hidden !important;
  border-top: hidden !important;
}
.g-timeunits-container,
.g-upper-timeunit,
.g-timeunit {
  background-color: white !important;
}
.g-timeaxis {
  box-shadow: none;
}
</style>

<style scoped>
.planning-card-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen {
  .print-planning {
    display: none;
  }
}

@media print {
  .print-hidden {
    display: none !important;
  }
  .print-planning {
    display: block !important;
  }

  .planning-card-shadow {
    box-shadow: none;
  }
}
</style>
