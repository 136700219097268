<template>
  <div v-if="!this.url" class="symbol symbol-55px mt-1 me-5">
    <span class="symbol-label bg-light-primary align-items-end">
      <img :class="this.height" :src="preview" alt="Logo" />
    </span>
  </div>
  <div v-else class="symbol symbol-55px mt-1 me-5">
    <span class="symbol-label align-items-center">
      <img :src="preview" alt="Logo" class="h-100 rounded" />
    </span>
  </div>
</template>

<script>
export default {
  name: "AvatarLogo",
  props: {
    url: {
      type: String,
      required: false,
      default: "/assets/media/svg/avatars/001-boy.svg",
    },
    height: {
      type: String,
      required: false,
      default: "mh-80px",
    },
  },
  computed: {
    preview() {
      if (this.url) {
        return this.url;
      }
      return "/assets/media/svg/avatars/001-boy.svg";
    },
  },
};
</script>

<style lang="scss" scoped></style>
