import gql from "graphql-tag";

export const EXPORT_DOCUMENT_EXCEL_MUTATION = gql`
  mutation ProjectExportDocument(
    $exportFormat: String = "EXCEL"
    $lots: [UUID]
    $projectId: UUID!
    $fileHeader: String
    $fileFooter: String
    $includeUnitPrice: Boolean
    $includeUnit: Boolean
    $includeQt: Boolean
    $marginTop: String
    $marginBottom: String
    $marginLeft: String
    $marginRight: String
    $forceBreakPageLot: Boolean
  ) {
    projectExportDocument(
      exportFormat: $exportFormat
      lots: $lots
      projectId: $projectId
      fileHeader: $fileHeader
      fileFooter: $fileFooter
      includeUnitPrice: $includeUnitPrice
      includeUnit: $includeUnit
      includeQt: $includeQt
      marginTop: $marginTop
      marginBottom: $marginBottom
      marginLeft: $marginLeft
      marginRight: $marginRight
      forceBreakPageLot: $forceBreakPageLot
    ) {
      url
    }
  }
`;
