import gql from 'graphql-tag';
import { TODO_ITEM_FRAGMENT } from '../../todos/data/todos_graphql';

export const CANEVAS_QUERY = gql`
  query CanevasQuery {
    canevas {
      id
      name
      type
      todos {
        id
        title
        todoItems {
          ...TodoItemFields
        }
      }
      tags {
        id
        name
        color
      }
    }
  }
  ${TODO_ITEM_FRAGMENT}
`;

export const GET_CANEVA_BY_ID_QUERY = gql`
    query GetCanevaByIdQuery($id: UUID!) {
        caneva: canevaById(id: $id) {
            id
            name
            type
            todos {
                id
                title
                order
                createdAt
                updatedAt
                todoItems {
                    ...TodoItemFields
                }
            }
            tags {
                id
                name
                color
            }
        }
    }
    ${TODO_ITEM_FRAGMENT}
`
export const CREATE_CANEVA_MUTATION = gql`
    mutation CreateCanevaMutation($name: String!, $todos: [UUID!]) {
        canevaCreate(name: $name, todoIds: $todos) {
            caneva {
                id,
                name,
                type,
                todos {
                    id,
                    title,
                    todoItems {
                        ...TodoItemFields
                    }
                }
            }
        }
    }
    ${TODO_ITEM_FRAGMENT}
`

export const DELETE_CANEVA_MUTATION = gql`
  mutation DeleteCanevaMutation($id: UUID!) {
    canevaDelete(id: $id) {
      ok
    }
  }
`;

export const UPDATE_CANEVA_MUTATION = gql`
    mutation UpdateCanevaMutation($canevaId: UUID!, $name: String!, $tagIds: [UUID]) {
        canevaUpdate(canevaId: $canevaId, name: $name, tagIds: $tagIds) {
            caneva {
                id,
                name,
                type,
                todos {
                    id,
                    title,
                    todoItems {
                        ...TodoItemFields
                    }
                }
                tags {
                    id
                    name
                    color
                }
            }
        }
    }
    ${TODO_ITEM_FRAGMENT}
`