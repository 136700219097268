<template>
  <div style="display: block" class="modal show" tabindex="-1" @keydown.esc="this.close()" id="modalCreateCanevas">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.close()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Content-->
            <div class="d-flex flex-row-fluid justify-content-center">
              <!--begin::Form-->
              <div ref="contactEditModalForm" class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                <!--begin::Step-->
                <div class="pb-5 completed">
                  <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-5">
                      <h3 class="fw-bolder text-dark display-6">Importer un template de todos</h3>
                    </div>
                    <!--end::Heading-->

                    <!--begin::Form Group Options-->
                    <h4 class="menu-content text-muted mb-0 fs-6 fw-bold">Attribuer à un projet</h4>
                    <select
                      class="form-select form-select-solid"
                      aria-label="Select project"
                      v-model="this.selectedProject"
                    >
                      <option
                        v-for="project in [{ id: null, name: 'Agence' }, ...projects.filter((p) => !p.archived)]"
                        :value="project.id"
                        :name="project.name"
                        :key="project.id"
                        :selected="project.id === currentProjectFocus"
                      >
                        {{ project.name }}
                      </option>
                    </select>
                    <!--end::Form Group Options-->
                  </div>
                </div>
                <!--end::Step-->
                <!--begin::Actions-->
                <div class="d-flex justify-content-end">
                  <div>
                    <button class="btn btn-lg btn-light" @click="close()">Annuler</button>
                    <button class="btn btn-lg btn-primary ms-2" @click="validate()">Importer</button>
                  </div>
                </div>
                <!--end::Actions-->
              </div>
              <!--end::Form-->
            </div>
            <!--end::Content-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ALL_PROJECTS_QUERY } from "@/graphql/graphql";
import { CREATE_TODOS_FROM_CANEVA } from "@/views/todos/data/todos_graphql";
import { toast } from "vue3-toastify";

export default {
  name: "ImportCanevaModal",
  apollo: {
    projects: ALL_PROJECTS_QUERY,
  },
  props: {
    canevaId: {
      type: Number,
      Required: true,
    },
  },
  data() {
    return {
      projects: [],
      selectedProject: null,
    };
  },
  methods: {
    close() {
      this.$emit("close", this.selectedProject);
    },
    async validate() {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_TODOS_FROM_CANEVA,
          variables: {
            canevaId: this.canevaId,
            projectId: this.selectedProject,
          },
        });
        toast.success("Les todos ont été importés avec succès");
      } catch (e) {
        toast.error("Une erreur est survenue lors de l'importation des todos");
        console.error(e);
      }
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
