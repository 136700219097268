<template>
  <div :class="{ 'blur-content': this.isModalActive }" class="row g-5">
    <div class="col-12" id="notes">
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Mes Notes</span>
          </h3>
          <div class="card-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-light">
              <li class="nav-item">
                <a class="nav-link btn btn-primary fw-bolder me-2" @click="modalCreateNoteShow()">Créer une note</a>
              </li>
            </ul>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 mt-n3">
          <div class="tab-content mt-4" id="myTabTables2">
            <!--begin::Table-->
            <div class="table-responsive">
              <div v-if="this.notes.length">
                <table class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="px-2 py-0 w-50px"></th>
                      <th class="px-2 py-0 w-100"></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                      <th class="px-2 py-0 min-w-50px"></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="note in this.notes"
                      :key="note.id"
                      @click="this.navigateToNote(note.id)"
                      class="bg-hover-light"
                    >
                      <td class="px-0 py-3 rounded-start p-5">
                        <div class="symbol symbol-55px ms-5 mt-1 me-5">
                          <span class="symbol-label bg-light-primary align-middle align-items-end">
                            <!--         REMPLACER PAR IMAGE-AVATAR DU PROJET <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />-->
                            <i class="bi bi-bookmark-check fs-1 align-middle align-self-center"></i>
                          </span>
                        </div>
                      </td>
                      <td class="px-0">
                        <p class="text-gray-800 fw-bolder fs-6 my-1">{{ note.title }}</p>
                      </td>
                      <td></td>
                      <td class="px-0 text-center">
                        <!--                      <div v-if="project?.team?.length" class="d-grid justify-content-center m-auto" style="grid-template-columns: repeat(auto-fit,  minmax(10px, max-content))">
                                                                    <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 3;">A</div>
                                                                    <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 2; transform: translateX(-7px);">B</div>
                                                                    <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 1; transform: translateX(-14px);">C</div>
                                                                  </div>-->
                      </td>
                      <td></td>
                      <td class="px-0 rounded-end">
                        <button
                          v-tooltip:bottom="'Supprimer la note'"
                          @click.stop="this.modalConfirmDeleteNoteShow(note.id)"
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                        >
                          <i class="bi bi-trash-fill align-baseline text-center"></i>
                        </button>
                        <!-- <button v-tooltip:bottom="'Dupliquer ce projet'" @click="this.modalDuplicateFromProjectShow(project.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"><i class="fa fa-copy align-baseline text-center"></i></button>-->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <p class="text-center text-muted my-8">
                  <span class="bi bi-folder me-2"></span>Aucune Note. Cliquez sur créer une note pour en ajouter
                  une<span class="ms-1 bi bi-arrow-up-right"></span>
                </p>
              </div>
            </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
    </div>
    <!-- MODAL CREATE NEW NOTE -->
    <div class="modal" tabindex="-1" :style="this.modals.createNote.show ? 'display: block;' : 'display: none;'">
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalCreateNoteCancel()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div
                    ref="contactEditModalForm"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Nouvelle note</h3>
                        </div>

                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Nom de la note</label>
                          <input
                            v-model="modals.createNote.newNote.title"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="Nouvelle note"
                          />
                        </div>
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalCreateNoteCancel()">Annuler</button>
                        <button
                          class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                          :disabled="!modals.createNote.newNote.title"
                          @click="modalCreateNoteValidate()"
                        >
                          Créer une note
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL CONFIRM DELETE BIBLIO -->
    <div class="modal" tabindex="-1" :style="this.modals.deleteNote.show ? 'display: block;' : 'display: none;'">
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalConfirmDeleteNoteCancel()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content0-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Supprimer la note</h3>
                        </div>
                        <p>Vous ne pourrez plus la consulter ni la modifier.</p>
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button type="button" @click="this.modalConfirmDeleteNoteCancel()" class="btn btn-lg btn-light">
                          Annuler
                        </button>
                        <button
                          type="button"
                          @click="this.modalConfirmDeleteNoteValidate()"
                          class="btn btn-lg btn-primary ms-2"
                        >
                          Confirmer
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </form>
                  <!--end::Form-->
                </div>
                <!--end::Content0-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CREATE_NOTE_MUTATION, DELETE_NOTE_MUTATION, GET_NOTES_BY_PROJECT_ID } from "@/graphql/graphql";
import kalmStore from "@/store";
import { toast } from "vue3-toastify";

export default {
  name: "NotesList",
  data() {
    return {
      modals: {
        createNote: {
          show: false,
          newNote: {
            title: "",
          },
        },
        deleteNote: {
          show: false,
          noteId: null,
        },
      },
      notes: [],
    };
  },
  apollo: {
    notes: {
      query: GET_NOTES_BY_PROJECT_ID,
      variables() {
        return {
          id: kalmStore.getters.currentProjectFocus,
        };
      },
      skip() {
        return (
          !kalmStore.getters.currentProjectFocus &&
          kalmStore.getters.currentProjectFocus === "0" &&
          kalmStore.getters.currentProjectFocus === 0
        );
      },
    },
  },
  methods: {
    modalCreateNoteShow() {
      this.modals.createNote.show = true;
    },
    modalCreateNoteCancel() {
      this.modals.createNote.show = false;
      this.modals.createNote.newNote.title = "";
    },
    async modalCreateNoteValidate() {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_NOTE_MUTATION,
          variables: {
            title: this.modals.createNote.newNote.title,
            projectId: kalmStore.getters.currentProjectFocus,
          },
          refetchQueries: [
            {
              query: GET_NOTES_BY_PROJECT_ID,
              variables: {
                id: kalmStore.getters.currentProjectFocus,
              },
            },
          ],
        });
        toast.success("La note a été créée avec succès");
      } catch (e) {
        toast.error("Une erreur est survenue lors de la création de la note");
        console.error(e);
      }
      this.modals.createNote.show = false;
      this.modals.createNote.newNote.title = "";
    },
    modalConfirmDeleteNoteShow(noteId) {
      this.modals.deleteNote.show = true;
      this.modals.deleteNote.noteId = noteId;
    },
    modalConfirmDeleteNoteCancel() {
      this.modals.deleteNote.show = false;
      this.modals.deleteNote.noteId = null;
    },
    async modalConfirmDeleteNoteValidate() {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_NOTE_MUTATION,
          variables: {
            noteId: this.modals.deleteNote.noteId,
            projectId: kalmStore.getters.currentProjectFocus,
          },
          refetchQueries: [
            {
              query: GET_NOTES_BY_PROJECT_ID,
              variables: {
                id: kalmStore.getters.currentProjectFocus,
              },
            },
          ],
        });
        toast.success("La note a été supprimée avec succès");
      } catch (e) {
        toast.error("Une erreur est survenue lors de la suppression de la note");
        console.error(e);
      }
      this.modals.deleteNote.show = false;
      this.modals.deleteNote.noteId = null;
    },
    navigateToNote(noteId) {
      this.$router.push({ name: "project.note", params: { noteId: noteId } });
    },
  },
};
</script>

<style lang="scss" scoped></style>
