<template>
  <KalmModal modalTitle="Supprimer la Todo-list" @close="close">
    <template v-slot:body>
      <p>Etes-vous sur de vouloir supprimer cette Todo-list ?</p>
    </template>
    <template v-slot:footer>
      <button class="btn btn-secondary" data-bs-dismiss="modal" type="button" @click="close">Annuler</button>
      <LoadingButton v-if="isSaving"></LoadingButton>
      <button v-if="!isSaving" class="btn btn-danger" type="button" @click="confirm">Supprimer</button>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import LoadingButton from "@/views/_core/components/LoadingButton.vue";
import { DELETE_TODO, GET_TODOS } from "../../../data/todos_graphql";
import { GET_CANEVA_BY_ID_QUERY } from "@/views/canevas/data/canevas_graphql";
export default {
  name: "DeleteTodoConfirmModal",
  components: {
    KalmModal,
    LoadingButton,
  },
  props: {
    todo: {
      type: Object,
      required: true,
    },
    projectId: {
      type: String,
      required: false,
    },
    canevaId: {
      type: String,
      required: false,
    },
    filterAssignedTo: {
      type: Array,
    },
    filterProject: {
      type: Array,
    },
    assignedToCurrentUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    async confirm() {
      this.isSaving = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_TODO,
          variables: {
            id: this.todo.id,
          },
          refetchQueries: [
            {
              query: GET_TODOS,
              variables: {
                projectId: this.projectId,
                assignedToCurrentUser: this.assignedToCurrentUser,
                filterAssignedTo: this.filterAssignedTo,
                filterProject: this.filterProject,
              },
              skip: !this.projectId,
            },
            {
              query: GET_CANEVA_BY_ID_QUERY,
              variables: {
                id: this.canevaId,
              },
              skip() {
                return !this.canevaId;
              },
            },
          ],
        });
      } catch (error) {
        console.error("Delete todo error:", error);
      }
      this.isSaving = false;
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
