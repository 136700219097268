<template>
  <div id="UnassignContactModal" class="modal" style="display: block" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="mb-5">
                        <h3 class="fw-bolder text-dark display-6">Retirer du projet</h3>
                      </div>
                    </div>
                    <p class="text-muted fw-bolder">{{ this.contactFullName }}</p>
                    <p class="">
                      <span class="fw-bolder">Attention :</span> cette action est définitive et cela supprimera toutes
                      les informations liées à ce contact dans le projet : consultation, dpgf, tâches du suivi de
                      chantier.
                    </p>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" type="button" @click="$emit('close')">Annuler</button>
                      <button class="btn btn-lg btn-danger ms-2" type="button" @click="validateUnassignContact()">
                        Retirer du projet
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MUTATION_UNASSIGN_CONTACT } from "@/views/contacts/data/contacts_graphql";

export default {
  name: "UnassignContactModal",
  props: {
    projectId: {
      type: String,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
    contactFullName: {
      type: String,
      required: true,
    },
  },
  methods: {
    async validateUnassignContact() {
      console.log("Unassigning contact", this.contactId);
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UNASSIGN_CONTACT,
          variables: {
            projectId: this.projectId,
            contactId: this.contactId,
          },
        });
      } catch (e) {
        console.error(e);
      }
      this.$emit("validate");
    },
  },
};
</script>

<style lang="scss" scoped></style>
