<template>
  <div class="h-100">
    <div class="d-flex mb-2">
      <IconButton v-if="parentId" iconName="arrow-left" @click="openParentFolder"></IconButton>
      <ProjectBreadcrumbComponent
        v-if="projectId"
        :title="parentId ? this.folder?.name : 'Documents'"
        current_route="project.documents"
        :project_id="$route.params.id"
      >
      </ProjectBreadcrumbComponent>
    </div>
    <div class="row g-5">
      <div class="card col-12">
        <!-- DOSSIERS -->
        <FolderList
          :isLoading="isLoading"
          :folders="getFolders"
          @onCreate="onCreateFolderClick"
          @onEdit="modalEditFileOpen"
          @onDelete="modalConfirmDeleteFileOpen"
          @onShare="onShareFileClick"
          :projectId="projectId"
          :parentId="parentId"
          @onOpenFolder="openFolder"
        >
        </FolderList>
      </div>
      <div class="card col-12">
        <!-- FICHIERS -->
        <FileList
          :isLoading="isLoading"
          :files="getFiles"
          @onEdit="modalEditFileOpen"
          @onDelete="modalConfirmDeleteFileOpen"
          @onAssignTask="assignTask"
          @onShare="onShareFileClick"
          :projectId="projectId"
          :parentId="parentId"
          :callback="this.refetchFiles"
        ></FileList>
      </div>
    </div>
  </div>
  <div>
    <!-- CREATE FOLDER / EDIT FILE NAME -->
    <FolderModal
      v-if="modals.folderModal.show"
      @close="onCloseFolderModal"
      :projectId="projectId"
      :parentId="parentId"
      :folder="modals.folderModal.file"
    >
    </FolderModal>
    <!-- ASSIGN TASK -->
    <TaskSelectModal
      v-if="projectId && modals.assignTaskModal.show"
      :initiallySelectedTaskIds="modals.assignTaskModal.file.tasks"
      :projectId="modals.assignTaskModal.file.project.id"
      @close="closeAssignTaskModal"
      @save="saveAssignedTasks"
    >
    </TaskSelectModal>
    <!-- DELETE FILE -->
    <DeleteFileConfirmModal
      v-if="modals.confirmDeleteFileModal.show"
      :file-id="modals.confirmDeleteFileModal.file.id"
      :project-id="projectId"
      :parent-id="parentId"
      @close="modalConfirmDeleteFileClose"
      :callback="this.refetchFiles"
    >
    </DeleteFileConfirmModal>
    <!-- SHARE FILE -->
    <LinkCreationModal
      v-if="modals.linkCreationModal.show"
      @close="onCloseLinkCreationModal"
      :file-id="modals.linkCreationModal.file.id"
    ></LinkCreationModal>
  </div>
</template>

<script>
import IconButton from "@/views/_core/components/IconButton.vue";
import LinkCreationModal from "@/views/links/presentation/link-creation-modal/LinkCreationModal.vue";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent.vue";
import TaskSelectModal from "@/views/tasks/presentation/task-select-modal/TaskSelectModal.vue";
import { ASSIGN_TASKS, GET_FILES, GET_FOLDER_BY_ID } from "../../data/files_graphql";
import DeleteFileConfirmModal from "./components/DeleteFileConfirmModal.vue";
import FileList from "./components/FileList.vue";
import FolderList from "./components/FolderList.vue";
import FolderModal from "./components/FolderModal.vue";

export default {
  name: "DocumentsPage",
  components: {
    ProjectBreadcrumbComponent,
    FolderModal,
    TaskSelectModal,
    IconButton,
    DeleteFileConfirmModal,
    FolderList,
    FileList,
    LinkCreationModal,
  },
  props: {
    callWith: {
      type: String,
      default: "router",
    },
    project: {
      type: String,
    },
    modelValue: {
      type: String || undefined,
      default: undefined,
    },
  },
  apollo: {
    files: {
      query: GET_FILES,
      variables() {
        return this.getCommonVariables();
      },
      fetchPolicy: "cache-and-network",
    },
    folder: {
      query: GET_FOLDER_BY_ID,
      variables() {
        return {
          folderId: this.parentId,
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.parentId;
      },
    },
  },
  data() {
    return {
      projectId: undefined,
      parentId: undefined,
      files: [],
      modals: {
        linkCreationModal: {
          show: false,
          file: {
            id: "",
          },
        },
        assignTaskModal: {
          show: false,
          file: {
            id: "",
            projectId: "",
            tasks: [],
          },
        },
        folderModal: {
          show: false,
          file: {
            id: "",
            name: "",
          },
        },
        confirmDeleteFileModal: {
          show: false,
          file: {
            id: "",
          },
        },
      },
    };
  },
  watch: {
    $route(to) {
      this.projectId = to.params.id;
      this.parentId = to.params.parentId;
    },
    parentId() {
      if (this.callWith !== "router") {
        this.$apollo.queries.folder.refetch();
        this.$apollo.queries.files.refetch();
      }
    },
  },
  computed: {
    isLoading() {
      return this.$apollo.queries?.files?.loading;
    },
    getFolders() {
      return this.files.filter((file) => file.isDirectory);
    },
    getFiles() {
      return this.files.filter((file) => !file.isDirectory);
    },
    parent: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:params", value);
      },
    },
  },
  methods: {
    getCommonVariables() {
      if (this.parentId) {
        return {
          projectId: this.projectId,
          parentId: this.parentId,
        };
      }

      return {
        projectId: this.projectId,
      };
    },
    openFolder(folder) {
      if (this.callWith === "router") {
        this.$router.push({
          name: this.projectId ? "project.documents.folder" : "documents.folder",
          params: {
            id: this.projectId,
            parentId: folder.id,
          },
        });
      } else {
        this.parentId = folder.id;
      }
    },
    openParentFolder() {
      if (this.callWith === "router") {
        if (this.folder?.parent) {
          this.$router.push({
            name: this.projectId ? "project.documents.folder" : "documents.folder",
            params: {
              projectId: this.projectId,
              parentId: this.folder.parent.id,
            },
          });
        } else {
          this.$router.push({
            name: this.projectId ? "project.documents" : "documents",
            params: {
              id: this.projectId,
            },
          });
        }
      } else {
        if (this.parent) {
          this.parentId = this.folder?.parent?.id || "";
        } else {
          this.parentId = "";
        }
      }
    },
    refetchFiles() {
      this.$apollo.queries?.files?.refetch();
    },

    //-- MODALS
    onShareFileClick(file) {
      this.modals.linkCreationModal.file = {
        id: file.id,
      };
      console.log(this.modals.linkCreationModal.file.id);
      this.modals.linkCreationModal.show = true;
    },
    onCloseLinkCreationModal() {
      this.modals.linkCreationModal.file = {
        id: "",
      };
      this.modals.linkCreationModal.show = false;
    },
    onCreateFolderClick() {
      this.modals.folderModal.file = {
        id: "",
        name: "",
      };
      this.modals.folderModal.show = true;
    },
    onCloseFolderModal() {
      this.modals.folderModal.file = {
        id: "",
        name: "",
      };
      this.modals.folderModal.show = false;
      this.refetchFiles();
    },
    assignTask(file) {
      this.modals.assignTaskModal.file = {
        ...file,
        tasks: file.tasks.map((task) => task.id),
      };
      this.modals.assignTaskModal.show = true;
    },
    closeAssignTaskModal() {
      this.modals.assignTaskModal.show = false;
    },
    saveAssignedTasks(taskIds) {
      this.$apollo.mutate({
        mutation: ASSIGN_TASKS,
        variables: {
          fileId: this.modals.assignTaskModal.file.id,
          taskIds: taskIds,
        },
        refetchQueries: [
          {
            query: GET_FILES,
            variables: this.getCommonVariables(),
          },
        ],
      });
      this.closeAssignTaskModal();
    },
    modalEditFileOpen(file) {
      this.modals.folderModal.file.id = file.id;
      this.modals.folderModal.file.name = file.name;
      this.modals.folderModal.show = true;
    },
    modalEditFileClose() {
      this.modals.folderModal.file = {
        id: "",
        name: "",
      };
      this.modals.folderModal.show = false;
      this.refetchFiles();
    },
    modalConfirmDeleteFileOpen(file) {
      this.modals.confirmDeleteFileModal.file.id = file.id;
      this.modals.confirmDeleteFileModal.show = true;
    },
    modalConfirmDeleteFileClose() {
      this.modals.confirmDeleteFileModal.file.id = "";
      this.modals.confirmDeleteFileModal.show = false;
    },
    //-- END MODAL
  },
  async mounted() {
    if (this.callWith === "router") {
      this.projectId = this.$route.params.id;
      this.parentId = this.$route.params.parentId;
    } else {
      this.projectId = this.project;
      this.parentId = this.parent;
    }
  },
};
</script>
