<template>
  <div id="AssignContactModal" class="modal" style="display: block" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Ajouter un intervenant depuis mes contacts</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">
                          Contact à ajouter
                          <i class="bi bi-search ms-2" @click="this.showSearch = !this.showSearch"></i>
                        </label>
                        <input
                          v-if="this.showSearch"
                          v-model="this.searchFilter"
                          class="form-control form-control-solid mb-2"
                          placeholder="Rechercher un contact..."
                          type="text"
                        />
                        <select v-if="contacts" v-model="assignContact.contactId" class="form-select form-select-solid">
                          <option
                            v-for="contact in this.filteredContacts"
                            :key="contact.id"
                            :disabled="contact.disabled"
                            :value="contact.id"
                          >
                            {{ getContactName(contact) }}
                          </option>
                        </select>

                        <label class="fs-6 mt-5 me-2 fw-bolder text-dark form-label">Role</label>
                        <select
                          v-model="assignContact.role"
                          class="form-select form-select-solid"
                          @change="this.onChangeRole($event)"
                        >
                          <option
                            v-for="contact_role_option in contact_role_options"
                            :key="contact_role_option.id"
                            :value="contact_role_option.id"
                          >
                            {{ contact_role_option.name }}
                          </option>
                        </select>

                        <label class="fs-6 mt-5 me-2 fw-bolder text-dark form-label">Role affiché</label>
                        <input
                          v-model="this.assignContact.roleDisplayName"
                          class="form-control form-control-solid"
                          type="text"
                        />
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" type="button" @click="$emit('close')">Annuler</button>
                      <button
                        :disabled="this.assignContact.contactId === ''"
                        class="btn btn-lg btn-primary ms-2"
                        type="button"
                        @click="validateAssignContact"
                      >
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MUTATION_ASSIGN_CONTACT } from "@/views/contacts/data/contacts_graphql";
export default {
  name: "AssignContactModal",
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      Required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    alreadyAssignedContacts: {
      type: Array,
    },
  },
  data() {
    return {
      showSearch: false,
      searchFilter: "",
      contact_role_options: [
        {
          id: "MOA",
          name: "MOA - Maîtrise d'Ouvrage",
        },
        {
          id: "MOE",
          name: "MOE - Maîtrise d'Œuvre",
        },
        {
          id: "CONTRACTOR",
          name: "Entreprise",
        },
        {
          id: "BET",
          name: "Bureaux d'études & conseil",
        },
        {
          id: "AUTRE",
          name: "Autre",
        },
      ],
    };
  },
  methods: {
    onChangeRole($event) {
      console.log("onChangeRole", $event);
      this.assignContact.roleDisplayName = this.contact_role_options.find((r) => r.id === $event.target.value)?.name;
    },
    getContactName(contact) {
      if (contact.company && contact.name) return `${contact.name} (${contact.company})`;
      else
        return contact?.company || (contact.firstName + (contact.firstName && contact.name) ? " " : "" + contact?.name);
    },
    async validateAssignContact() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_ASSIGN_CONTACT,
          variables: {
            projectId: this.projectId,
            contactId: this.assignContact.contactId,
            role: this.assignContact.role,
            roleDisplayName: this.assignContact.roleDisplayName,
          },
        });
      } catch (error) {
        console.error(error);
      }
      this.$emit("validate");
    },
  },
  computed: {
    filteredContacts() {
      let retList = this.contacts.filter((contact) => !this.alreadyAssignedContacts.includes(contact.id));

      if (this.searchFilter) {
        const filter = this.searchFilter.toLowerCase();
        retList = retList.filter(
          (contact) =>
            contact.name.toLowerCase().includes(filter) ||
            (contact.company && contact.company.toLowerCase().includes(filter))
        );
      }

      return [{ id: "", name: "Sélectionnez un contact...", disabled: true }, ...retList];
    },
    assignContact: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
