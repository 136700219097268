import gql from "graphql-tag";

export const MUTATION_CONTACT_CREATE = gql`
  mutation ($newContact: ContactInput!) {
    contactCreate(newContact: $newContact) {
      contact {
        id
        name
        email
        phone
        phone2
        type
        company
        infoSiret
        infoCorps
        infoWebsite
        infoInsurance
        infoComment
        infoAddress
        infoTva
        logoUrl
        proposals {
          id
          name
          project {
            id
            name
          }
        }
        infoLine1
        infoLine2
      }
    }
  }
`;

export const MUTATION_CONTACT_UPDATE = gql`
  mutation ($contactId: String!, $newContact: ContactInput!) {
    contactUpdate(contactId: $contactId, newContact: $newContact) {
      contact {
        id
        name
        email
        phone
        phone2
        type
        company
        infoSiret
        infoCorps
        infoWebsite
        infoInsurance
        infoComment
        infoAddress
        infoTva
        logoUrl
        proposals {
          id
          name
          project {
            id
            name
          }
        }
        infoLine1
        infoLine2
      }
    }
  }
`;

export const MUTATION_CONTACT_DELETE = gql`
  mutation ($contactId: String!) {
    contactDelete(contactId: $contactId) {
      deleted
    }
  }
`;

export const MUTATION_ASSIGN_CONTACT = gql`
  mutation (
    $projectId: UUID!
    $contactId: UUID!
    $role: String!
    $roleDisplayName: String
    $starred: Boolean
    $hidden: Boolean
  ) {
    projectAssignContact(
      projectId: $projectId
      contactId: $contactId
      role: $role
      roleDisplayName: $roleDisplayName
      starred: $starred
      hidden: $hidden
    ) {
      project {
        id
        name
        contacts {
          role
          roleDisplayName
          starred
          hidden
          contact {
            id
            name
            email
            phone
            type
            company
            infoLine1
            infoLine2
          }
        }
      }
    }
  }
`;

export const MUTATION_UNASSIGN_CONTACT = gql`
  mutation ($projectId: UUID!, $contactId: UUID!) {
    projectUnassignContact(projectId: $projectId, contactId: $contactId) {
      project {
        id
        name
        contacts {
          role
          roleDisplayName
          starred
          hidden
          contact {
            id
            name
            email
            phone
            type
            company
            infoLine1
            infoLine2
          }
        }
      }
    }
  }
`;

export const ALL_CONTACTS_QUERY = gql`
  query AllContactsQuery {
    contacts: allContacts {
      id
      email
      name
      phone
      phone2
      company
      type
      infoSiret
      infoCorps
      infoWebsite
      infoInsurance
      infoComment
      infoAddress
      infoTva
      logoUrl
      proposals {
        id
        name
        project {
          id
          name
        }
      }
      infoLine1
      infoLine2
    }
  }
`;

export const GET_ALL_CONTACTS_SIMPLE = gql`
  query AllContactsQuery {
    contacts: allContacts {
      id
      name
      company
    }
  }
`;

export const PROJECT_CONTACTS = gql`
  query ProjectContacts($projectId: UUID!) {
    contacts: projectContacts(projectId: $projectId) {
      id
      email
      name
      phone
      phone2
      company
      type
      infoSiret
      infoCorps
      infoWebsite
      infoInsurance
      infoComment
      infoAddress
      infoTva
      infoLine1
      infoLine2
    }
  }
`;

export const CONTACT_BY_ID = gql`
  query ContactById($contactId: UUID!) {
    contact: contactById(contactId: $contactId) {
      id
      email
      name
      phone
      phone2
      company
      type
      infoSiret
      infoCorps
      infoWebsite
      infoInsurance
      infoComment
      infoAddress
      infoTva
      infoLine1
      infoLine2
      logoUrl
      projectParticipations {
        role
        roleDisplayName
        project {
          id
          name
        }
      }
      proposals {
        id
        name
        project {
          id
          name
        }
      }
      files {
        id
        name
        url
        creationDate
      }
    }
  }
`;
