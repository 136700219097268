<template>
  <div class="modal" tabindex="-1" @keydown.esc="this.$emit('close')" style="display: block">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content2-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="">
                        <h3 class="fw-bolder text-dark display-6">
                          <span v-if="modal?.contactId">Modifier le chiffrage</span>
                          <span v-else>Modifier l'estimatif</span>
                        </h3>
                        <p v-if="modal?.contactId" class="text-gray-500 fw-bold">
                          <i class="bi bi-building me-2"></i>
                          {{ getContactNameById(modal.contactId) }}
                        </p>
                      </div>
                      <!--end::Heading-->

                      <div class="mt-5">
                        <label class="text-muted mt-2 mb-1">
                          <span v-if="modal?.contactId">Nom du chiffrage</span>
                          <span v-else>Nom de l'estimatif</span>
                        </label>
                        <div class="input-group align-middle">
                          <input class="form-control" type="text" v-model="modal.name" />
                        </div>
                      </div>

                      <div class="mt-5">
                        <label class="text-muted mt-2 mb-1">Taux de TVA par défaut pour les ouvrages :</label>
                        <div class="input-group align-middle">
                          <input class="form-control" type="text" v-model="modal.vatDefaultValue" />
                          <span class="input-group-text">%</span>
                        </div>
                      </div>

                      <div class="mt-3" v-if="modal?.contactId">
                        <label class="text-muted mb-1">Avancement :</label>
                        <div
                          v-for="step in modal.formattedSteps"
                          :key="step.id"
                          class="form-check form-check-custom mb-1"
                        >
                          <input
                            type="checkbox"
                            class="form-check-input"
                            :ref="step.id"
                            v-model="modal.modelSteps[step.id]"
                            :id="step.id"
                          />
                          <label class="form-check-label text-gray-800 ms-2 text-muted" :for="step.id">
                            {{ step.name }}
                          </label>
                        </div>
                      </div>
                      <div class="mt-3">
                        <label class="fs-6 mb-2 text-muted">
                          <span v-if="modal?.contactId">Consultation sur :</span>
                          <span v-else>Estimatif sur :</span>
                        </label>
                        <select
                          class="form-select form-select-solid"
                          v-model="modal.modeLots"
                          aria-label="Select project"
                        >
                          <option v-for="mode in modes" :value="mode.id" :name="mode.name" :key="mode.id">
                            {{ mode.name }}
                          </option>
                        </select>
                      </div>

                      <div class="mt-3" v-if="modal.modeLots === 'PARTIAL'">
                        <div class="d-flex justify-content-between">
                          <label class="fs-6 mb-2 form-label text-muted">Lots concernés</label>
                          <p
                            class="fs-8 mb-2 align-self-end primary text-hover-secondary fw-bolder text-primary form-label text-decoration-underline"
                          ></p>
                        </div>
                        <div class="text-end text-muted">
                          <a class="cursor-pointer" @click="proposalLotsQuickSelect(modal.lots, 'all')"
                            >Tout sélectionner</a
                          >
                          /
                          <a class="cursor-pointer" @click="proposalLotsQuickSelect(modal.lots, 'none')">Aucun</a>
                        </div>
                        <!--begin::Form Group-->
                        <div v-for="lot in lots" :key="lot.id">
                          <!--begin:Option-->
                          <label class="d-flex flex-stack mb-6 cursor-pointer">
                            <span class="d-flex align-items-center me-2">
                              <span class="d-flex flex-column">
                                <span class="fs-6">{{ lot.fullName }}</span>
                              </span>
                            </span>
                            <span class="form-check form-check-custom form-check-solid">
                              <input class="form-check-input" type="checkbox" :value="lot.id" v-model="modal.lots" />
                            </span>
                          </label>
                          <!--end::Option-->
                        </div>
                      </div>

                      <div class="mt-3">
                        <label class="text-muted mt-2 mb-1">Notes :</label>
                        <div class="">
                          <textarea class="form-control" v-model="modal.notes"></textarea>
                        </div>
                      </div>

                      <!--
                            <div class="mb-5 w-100">
                            <div class="d-flex justify-content-between">
                                <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                            </div>
                            <div class="input-group input-group-lg input-group-solid">
                                <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                                <span @click="copyLinkToClipboard()"
                                    class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                                <i class="la la-copy fs-3 pe-4"></i>
                                </span>
                            </div>
                            </div>
                        -->
                    </div>
                  </div>

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.$emit('close')" class="me-2 btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button
                        type="button"
                        @click="this.$emit('validate')"
                        class="btn btn-lg btn-primary btn-active-light-primary"
                      >
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProposalEditModal",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    lots: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modes: [
        { id: "ALL", name: "Tous les lots" },
        { id: "PARTIAL", name: "Une partie des lots" },
      ],
    };
  },
  methods: {
    getContactNameById(contactId) {
      let ret = "";
      if (contactId) {
        const contact = this.contacts.find((c) => c.id === contactId);
        ret = contact?.company || contact?.name || "";
      }
      return ret;
    },
    proposalLotsQuickSelect(lots, mode) {
      if (mode === "all") {
        lots.length = 0;
        this.lots.forEach((l) => {
          lots.push(l.id);
        });
      } else {
        lots.length = 0;
      }
    },
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
