<template>
  <div class="modal" tabindex="-1" @keydown.esc="this.$emit('close')" style="display: block">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div :class="modal.page === 0 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouvel estimatif</h3>
                      </div>
                      <!--end::Heading-->
                    </div>

                    <div class="w-100">
                      <div class="mb-5">
                        <label class="fs-6 mb-1 fw-bolder form-label">Nom de l'estimatif</label>
                        <div class="input-group align-middle">
                          <input class="form-control form-control-solid" type="text" v-model="modal.name" />
                        </div>
                      </div>

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <div>
                          <label class="fs-6 mb-1 fw-bolder form-label">Estimatif sur</label>
                          <select
                            class="form-select form-select-solid"
                            v-model="modal.modeLots"
                            aria-label="Select project"
                          >
                            <option v-for="mode in modes" :value="mode.id" :name="mode.name" :key="mode.id">
                              {{ mode.name }}
                            </option>
                          </select>
                        </div>
                        <div class="mt-3" v-if="modal.modeLots === 'PARTIAL'">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lots concernés</label>
                            <p
                              class="fs-8 mb-2 align-self-end primary text-hover-secondary fw-bolder text-primary form-label text-decoration-underline"
                            ></p>
                          </div>
                          <div class="text-end text-muted">
                            <a class="cursor-pointer" @click="proposalLotsQuickSelect(modal.lots, 'all')"
                              >Tout sélectionner</a
                            >
                            /
                            <a class="cursor-pointer" @click="proposalLotsQuickSelect(modal.lots, 'none')">Aucun</a>
                          </div>
                          <!--begin::Form Group-->
                          <div v-for="lot in lots" :key="lot.id">
                            <!--begin:Option-->
                            <label class="d-flex flex-stack mb-6 cursor-pointer">
                              <span class="d-flex align-items-center me-2">
                                <span class="d-flex flex-column">
                                  <span class="fs-6">{{ lot.fullName }}</span>
                                </span>
                              </span>
                              <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" :value="lot.id" v-model="modal.lots" />
                              </span>
                            </label>
                            <!--end::Option-->
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <p class="mb-1 text-dark fw-bold mb-2">Import personnalisé depuis le descriptif</p>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="proposalEstimation.importUnit"
                            v-model="modal.importUnit"
                          />
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalEstimation.importUnit">Unités</label>
                        </div>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="proposalEstimation.importQt"
                            v-model="modal.importQt"
                          />
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalEstimation.importQt">Quantités</label>
                        </div>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="proposalEstimation.importPrice"
                            v-model="modal.importPrice"
                          />
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalEstimation.importPrice"
                            >Prix unitaires</label
                          >
                        </div>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->
                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button
                        type="button"
                        @click="$emit('validate')"
                        :disabled="modal.modeLots === 'PARTIAL' && !modal.lots.length"
                        class="btn btn-lg btn-primary btn-active-light-primary"
                      >
                        Suivant >
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->

              <!--begin::Content1-->
              <div :class="modal.page === 1 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <!--end::Form-->
              </div>
              <!--end::Content1-->

              <!--begin::Content2-->
              <div :class="modal.page >= 2 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouvel estimatif</h3>
                        <p class="text-gray-500 fw-bold">Nom : {{ modal.name }}</p>
                      </div>
                      <!--end::Heading-->

                      <!--
                                <div class="mb-5 w-100">
                                <div class="d-flex justify-content-between">
                                    <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                                </div>
                                <div class="input-group input-group-lg input-group-solid">
                                    <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                                    <span @click="copyLinkToClipboard()"
                                        class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                                    <i class="la la-copy fs-3 pe-4"></i>
                                    </span>
                                </div>
                                </div>
                            -->
                    </div>
                  </div>
                  <!--end::Step-->
                  <!--begin::Alert-->
                  <div class="alert bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"
                      ><i class="bi fs-1 bi-check"></i
                    ></span>
                    <!--end::Icon-->

                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                      <!--begin::Title-->
                      <h5 class="mb-1">Votre estimatif a été créé !</h5>
                      <!--end::Title-->
                      <!--begin::Content-->
                      <span
                        >Vous pouvez dès maintenant le remplir puis l'exporter en vous rendant sur l'onglet
                        Comparatif.</span
                      >
                      <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Alert-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button
                        type="button"
                        @click="$emit('close')"
                        class="btn btn-lg btn-primary btn-active-light-primary"
                      >
                        Terminer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateEstimationModal",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    lots: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modes: [
        { id: "ALL", name: "Tous les lots" },
        { id: "PARTIAL", name: "Une partie des lots" },
      ],
    };
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    proposalLotsQuickSelect(lots, mode) {
      if (mode === "all") {
        lots.length = 0;
        this.lots.forEach((l) => {
          lots.push(l.id);
        });
      } else {
        lots.length = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
