<template>
    <KalmModal modalTitle="Supprimer le tag" @close="this.$emit('close')">
        <template v-slot:body>
            <p>Êtes-vous sûr de vouloir supprimer ce tag ?</p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="this.$emit('close')">Annuler</button>
            <LoadingButton v-if="isSaving"></LoadingButton>
                <button v-if="!isSaving" type="button" class="btn btn-danger" @click="this.$emit('validate')">Supprimer</button>
            </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
export default {
    name: 'DeleteTagModal',
    components: {
        KalmModal
    },
};
</script>

<style lang="scss" scoped></style>
