<template>
  <node-view-wrapper class="vue-component">
    <div @mouseover="this.hover = true" @mouseleave="this.hover = false">
      <div class="row g-5 mt-3 mb-3 ms-8 print-border">
        <div class="col-xl-12 mt-0">
          <div class="card lot-component-shadow">
            <!--begin::Body-->
            <div class="p-0 card-body row">
              <!--begin::Section-->
              <div class="print-margins col-12 col-md-10 col-xl-11 pb-6 pt-8 ps-0 pe-10 d-flex align-items-center">
                <div class="ouvrage-left-actions d-flex align-content-center">
                  <div
                    role="button"
                    tabindex="0"
                    @click="plusAction()"
                    contenteditable="false"
                    title="Ajouter une ligne"
                    :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                    class="ouvrage-actions-left ouvrage-action-plus align-middle"
                  >
                    <svg viewBox="0 0 16 16" style="width: 15px; height: 100%; display: block">
                      <path
                        d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"
                      ></path>
                    </svg>
                  </div>
                  <div
                    role="button"
                    tabindex="0"
                    contenteditable="false"
                    draggable="true"
                    data-drag-handle
                    title="Déplacer le bloc texte"
                    class="ouvrage-actions-left drag-handle"
                    :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      style="width: 15px; height: 100%; display: block"
                    >
                      <path
                        fill-opacity="0.2"
                        d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                      />
                    </svg>
                  </div>
                </div>
                <!--begin::Content-->
                <div class="ms-0 w-100">
                  <node-view-content id="lotText-node-content" class="content p-0" />
                </div>
                <!--end::Content-->
                <div class="ouvrage-actions position-absolute end-0 row-cols-1 w-60px">
                  <!--
                                    <button class="btn btn-outline-secondary"
                                            @click="insertAbove()">
                                      <i class="bi bi-plus"></i>
                                    </button>
                  -->
                  <button class="btn btn-outline-secondary" @click="duplicateNode()">
                    <i class="fa fa-light fa-copy"></i>
                  </button>
                  <button class="btn btn-outline-secondary" @click="deleteNode()">
                    <i class="bi bi-trash"></i>
                  </button>
                  <!--
                                    <button class="btn btn-outline-secondary"
                                            @click="insertBelow()">
                                      <i class="bi bi-plus"></i>
                                    </button>
                  -->
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewContent, NodeViewWrapper } from "@tiptap/vue-3";

export default {
  name: "BiblioTextComponent",
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {},
  methods: {
    insertAbove() {
      const { editor, getPos } = this;

      editor.commands.insertContentAt(getPos(), "<lot-text>Nouveau bloc de texte</lot-text>");
      editor.commands.focus();
    },
    insertBelow() {
      const { editor, getPos, node } = this;

      const end = getPos() + node.nodeSize;

      editor.commands.insertContentAt(end, "<lot-text>Nouveau bloc de texte</lot-text>");
      editor.commands.focus();
    },
    deleteNode() {
      const { editor, getPos, node } = this;

      const from = getPos();
      const to = from + node.nodeSize;

      editor.commands.deleteRange({ from, to });
      editor.commands.focus();
    },
    duplicateNode() {
      const { editor, getPos, node } = this;

      const end = getPos() + node.nodeSize;

      const innerContent = editor.view.nodeDOM(getPos()).querySelector("#lotText-node-content").innerHTML;

      editor.commands.insertContentAt(end, `<lot-text>${innerContent}</lot-text>`);
      editor.commands.focus();
    },
    plusAction() {
      const { editor, getPos, node } = this;

      const end = getPos() + node.nodeSize;

      editor.chain().insertContentAt(end, { type: "lotParagraph", content: [] }).focus().run();
    },
  },
};
</script>

<!--suppress CssInvalidHtmlTagReference -->
<style scoped>
.lot-component-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.ouvrage-left-actions {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  right: 40px;
}

.ProseMirror-focused:focus {
  outline: none !important;
}
.ProseMirror:focus {
  outline: none !important;
}
.ProseMirror-focused:focus-visible {
  outline: none !important;
}
.ProseMirror:focus-visible {
  outline: none !important;
}

@media print {
  .lot-component-shadow {
    box-shadow: none;
  }

  div .print-margins {
    margin-left: 0 !important;
    padding-left: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .print-border {
    border-left: 1px solid lightgray;
    padding-left: 10px !important;
    margin-bottom: 20px !important;
    margin-top: 15px !important;
    margin-left: 5px !important;
  }

  .ouvrage-left-actions {
    display: none !important;
    visibility: hidden;
  }

  .print-info {
    display: block;
  }

  lotText-node-content > p {
    margin-bottom: 0 !important;
  }
}
</style>
