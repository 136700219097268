<template>
  <button
    v-tooltip:bottom="tooltipName"
    :disabled="disabled"
    :title="tooltipName"
    class="btn me-2 btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
    @click.stop.prevent
  >
    <slot name="before-icon"></slot>
    <i :class="`bi bi-${iconName}`" class="align-baseline text-center m-1"></i>
    <slot name="after-icon"></slot>
  </button>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    iconName: String,
    tooltipName: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },
};
</script>
