<template>
  <IconButton iconName="pencil-square" :tooltip-name="tooltipName"></IconButton>
</template>

<script>
import IconButton from "./IconButton.vue";
export default {
  name: "EditButton",
  props: {
    tooltipName: {
      type: String,
      default: "",
    },
  },
  components: {
    IconButton,
  },
};
</script>
