<template>
  <div class="modal" tabindex="-1" @keydown.esc="$emit('close')" style="display: block">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div :class="page === 0 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Entreprise</label>
                        <router-link :to="{ name: 'contacts' }" class="fs-8 align-self-end form-label"
                          >+ Importer une nouvelle entreprise</router-link
                        >
                        <select v-model="this.modal.contact" class="form-select form-select-solid">
                          <option
                            v-for="contact in contactsList"
                            :key="contact.id"
                            :value="contact.id"
                            :disabled="contact?.disabled"
                          >
                            {{ contact.company || contact.name }}
                          </option>
                        </select>
                      </div>

                      <div class="">
                        <p class="mb-1 text-dark fw-bold mb-2">Import personnalisé depuis le descriptif</p>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="proposalCreate.importUnit"
                            v-model="this.modal.importUnit"
                          />
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalCreate.importUnit">Unités</label>
                        </div>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="proposalCreate.importQt"
                            v-model="this.modal.importQt"
                          />
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalCreate.importQt">Quantités</label>
                        </div>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="proposalCreate.importPrice"
                            v-model="this.modal.importPrice"
                          />
                          <label class="ms-2 fs-6 mb-1 form-label" for="proposalCreate.importPrice"
                            >Prix unitaires</label
                          >
                        </div>
                      </div>

                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button
                        type="button"
                        @click="
                          this.modal.page++;
                          this.modal.name = getContactNameById(this.modal.contact);
                        "
                        :disabled="!this.modal.contact"
                        class="btn btn-lg btn-primary btn-active-light-primary"
                      >
                        Suivant >
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->

              <!--begin::Content1-->
              <div :class="this.modal.page === 1 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                        <p class="text-gray-500 fw-bold">
                          <i class="bi bi-building me-2"></i>{{ getContactNameById(this.modal.contact) }}
                        </p>
                      </div>
                      <!--end::Heading-->

                      <div class="mb-5">
                        <label class="fs-6 mb-1 fw-bolder form-label">Nom du chiffrage</label>
                        <div class="input-group align-middle">
                          <input class="form-control form-control-solid" type="text" v-model="this.modal.name" />
                        </div>
                      </div>

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <div>
                          <label class="fs-6 mb-1 fw-bolder form-label">Consultation sur</label>
                          <select
                            class="form-select form-select-solid"
                            v-model="this.modal.modeLots"
                            aria-label="Select project"
                          >
                            <option
                              v-for="mode in [
                                { id: 'ALL', name: 'Tous les lots' },
                                { id: 'PARTIAL', name: 'Une partie des lots' },
                              ]"
                              :value="mode.id"
                              :name="mode.name"
                              :key="mode.id"
                            >
                              {{ mode.name }}
                            </option>
                          </select>
                        </div>
                        <div class="mt-3" v-if="this.modal.modeLots === 'PARTIAL'">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lots concernés</label>
                            <p
                              class="fs-8 mb-2 align-self-end primary text-hover-secondary fw-bolder text-primary form-label text-decoration-underline"
                            ></p>
                          </div>
                          <div class="text-end text-muted">
                            <a class="cursor-pointer" @click="proposalLotsQuickSelect(this.modal.lots, 'all')"
                              >Tout sélectionner</a
                            >
                            /
                            <a class="cursor-pointer" @click="proposalLotsQuickSelect(this.modal.lots, 'none')"
                              >Aucun</a
                            >
                          </div>
                          <!--begin::Form Group-->
                          <div v-for="lot in lots" :key="lot.id">
                            <!--begin:Option-->
                            <label class="d-flex flex-stack mb-6 cursor-pointer">
                              <span class="d-flex align-items-center me-2">
                                <span class="d-flex flex-column">
                                  <span class="fs-6">{{ lot.fullName }}</span>
                                </span>
                              </span>
                              <span class="form-check form-check-custom form-check-solid">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :value="lot.id"
                                  v-model="this.modal.lots"
                                />
                              </span>
                            </label>
                            <!--end::Option-->
                          </div>
                        </div>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button
                        type="button"
                        @click="$emit('validate')"
                        :disabled="this.modal.modeLots === 'PARTIAL' && !this.modal.lots.length"
                        class="btn btn-lg btn-primary btn-active-light-primary"
                      >
                        Suivant >
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content1-->

              <!--begin::Content2-->
              <div :class="this.modal.page >= 2 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                        <p class="text-gray-500 fw-bold">
                          <i class="bi bi-building me-2"></i>
                          {{ getContactNameById(this.modal.contact) }}
                        </p>
                      </div>
                      <!--end::Heading-->

                      <!--
                            <div class="mb-5 w-100">
                            <div class="d-flex justify-content-between">
                                <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                            </div>
                            <div class="input-group input-group-lg input-group-solid">
                                <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                                <span @click="copyLinkToClipboard()"
                                    class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                                <i class="la la-copy fs-3 pe-4"></i>
                                </span>
                            </div>
                            </div>
                        -->
                    </div>
                  </div>
                  <!--end::Step-->
                  <!--begin::Alert-->
                  <div class="alert bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"
                      ><i class="bi fs-1 bi-check"></i
                    ></span>
                    <!--end::Icon-->

                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                      <!--begin::Title-->
                      <h5 class="mb-1">Demande de chiffrage créée</h5>
                      <!--end::Title-->
                      <!--begin::Content-->
                      <span
                        >Vous pouvez désormais mettre à jour son statut d'avancement et télécharger le DCE en format
                        Excel dans l'onglet suivi. Dès que vous recevrez le chiffrage, vous pourrez le saisir dans
                        l'onglet Comparatif afin de compléter le tableau de synthèse.</span
                      >
                      <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Alert-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button
                        type="button"
                        @click="$emit('close')"
                        class="btn btn-lg btn-primary btn-active-light-primary"
                      >
                        Terminer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateProposalModal",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    lots: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      contactsList: [
        {
          id: "",
          name: "Sélectionner un contact...",
          disabled: true,
        },
        ...this.contacts.filter((c) => c.type === "COMPANY"),
      ],
    };
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    proposalLotsQuickSelect(lots, mode) {
      if (mode === "all") {
        lots.length = 0;
        this.lots.forEach((l) => {
          lots.push(l.id);
        });
      } else {
        lots.length = 0;
      }
    },
    getContactNameById(contactId) {
      let ret = "";
      if (contactId) {
        const contact = this.contacts.find((c) => c.id === contactId);
        ret = contact?.company || contact?.name || "";
      }
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped></style>
