<template>
  <div class="row g-5 print-hidden">
    <div class="col-12 mb-5">
      <div class="card">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">
              <i
                class="bi bi-arrow-left cursor-pointer align-middle fs-1 me-3"
                @click="this.$router.push({ name: 'canevas' })"
              ></i>
              Mon caneva
            </span>
          </h3>
        </div>
        <div class="card-body w-100 row pt-2">
          <div class="col-12">
            <div class="form-group mb-3">
              <label class="text-muted">Nom du caneva</label>
              <input
                v-model="this.localCaneva.name"
                class="form-control form-control-lg form-control-solid"
                type="text"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group mb-3">
              <TagMultiSelect
                v-model="this.localCaneva.tags"
                for="CANEVA"
                @newTag="modalCreateTagShow"
              ></TagMultiSelect>
            </div>
          </div>
          <div class="flex-column text-end">
            <!--:disabled="this.biblio.type === 'DEFAULT'"-->
            <button :disabled="!this.leaveGuard" class="btn btn-primary btn-active-accent" @click="this.saveCaneva()">
              <span v-if="this.loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Enregistrer</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <CanevaTodo :caneva="this.caneva" @close="refetchApolloQueries" @importCaneva="importCaneva" />
  </div>
  <ImportCanevaModal v-if="modals.importCaneva.show" :caneva-id="modals.importCaneva.canevaId" @close="close" />
  <CreateTagModal
    v-if="modals.createTag.show"
    v-model="modals.createTag.newTag"
    @close="modalCreateTagClose"
    @validate="modalCreateTagValidate"
  />
</template>

<script>
import { GET_CANEVA_BY_ID_QUERY, UPDATE_CANEVA_MUTATION } from "../data/canevas_graphql";
import { toast } from "vue3-toastify";
import { CREATE_TAG, GET_TAGS } from "@/views/tags/data/tags_graphql";
import CanevaTodo from "./components/CanevaTodo.vue";
import ImportCanevaModal from "./components/ImportCanevaModal.vue";
import TagMultiSelect from "@/views/tags/presentation/tag-select/TagMultiSelect.vue";
import CreateTagModal from "@/views/tags/presentation/components/CreateTagModal.vue";

export default {
  name: "CanevaItemPage",
  data() {
    return {
      localCaneva: {
        name: "",
        tags: [],
      },
      leaveGuard: false,
      caneva: {
        name: "",
      },
      loading: false,
      modals: {
        importCaneva: {
          show: false,
          canevaId: null,
        },
        createTag: {
          show: false,
          callback: null,
          newTag: {
            name: "",
            color: "",
          },
        },
      },
    };
  },
  components: {
    CanevaTodo,
    ImportCanevaModal,
    TagMultiSelect,
    CreateTagModal,
  },
  apollo: {
    caneva: {
      query: GET_CANEVA_BY_ID_QUERY,
      variables() {
        return {
          id: this.canevaId,
        };
      },
      update(data) {
        this.localCaneva = { ...data.caneva, tags: data.caneva.tags.map((t) => t.id) };
        return data.caneva;
      },
      skip: function () {
        return !this.canevaId;
      },
    },
  },
  watch: {
    "localCaneva.name": function () {
      if (!this.caneva) return;

      if (this.localCaneva.name !== this.caneva.name) {
        this.leaveGuard = true;
      }
    },
    "localCaneva.tags": function () {
      if (!this.caneva) return;
      if (JSON.stringify(this.localCaneva.tags) !== JSON.stringify(this.caneva.tags.map((t) => t.id))) {
        this.leaveGuard = true;
      }
    },
  },
  methods: {
    async saveCaneva() {
      try {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: UPDATE_CANEVA_MUTATION,
          variables: {
            canevaId: this.canevaId,
            name: this.localCaneva.name,
            tagIds: this.localCaneva.tags,
          },
          refetchQueries: {
            query: GET_CANEVA_BY_ID_QUERY,
            variables: { id: this.canevaId },
            skip: !this.canevaId,
          },
        });
        this.leaveGuard = false;
        toast.success("Caneva enregistré avec succès");
      } catch (e) {
        toast.error("Erreur lors de l'enregistrement du caneva");
        console.error(e);
      }
      this.loading = false;
    },
    importCaneva(canevaId) {
      this.modals.importCaneva.canevaId = canevaId;
      this.modals.importCaneva.show = true;
    },
    refetchApolloQueries() {
      this.$apollo.queries.caneva.refetch();
    },
    close() {
      this.modals.importCaneva.show = false;
      this.modals.importCaneva.canevaId = null;
      this.refetchApolloQueries();
    },
    modalCreateTagShow(callback) {
      this.modals.createTag.show = true;
      this.modals.createTag.callback = callback;
    },
    async modalCreateTagValidate() {
      try {
        const tag = await this.$apollo.mutate({
          mutation: CREATE_TAG,
          variables: {
            name: this.modals.createTag.newTag.name,
            color: this.modals.createTag.newTag.color,
            entity: "CANEVA",
          },
          refetchQueries: [{ query: GET_TAGS, variables: { entity: "CANEVA" } }],
        });
        this.modals.createTag.callback(tag.data.tagCreate.tag.id);
        this.modalCreateTagClose();
        toast.success("Le tag a bien été créé");
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue lors de la création du tag");
      }
    },
    modalCreateTagClose() {
      this.modals.createTag.show = false;
      this.modals.createTag.newTag.name = "";
      this.modals.createTag.newTag.color = "";
    },
  },
  computed: {
    canevaId() {
      return this.$route.params.id;
    },
  },
};
</script>
