<template>
  <router-view></router-view>
</template>

<script>
import kalmStore from "@/store";

function setFocus(id) {
  if (id) {
    kalmStore.commit("setCurrentProjectFocus", { projectId: id });
  }
}
export default {
  name: "ProjectDetail",
  mounted() {
    setFocus(this.$route.params.id);
  },
  beforeUpdate() {
    setFocus(this.$route.params.id);
  },
};
</script>

<style scoped></style>
