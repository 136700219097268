<template>
  <KalmModal modalTitle="Supprimer le commentaire" @close="close">
    <template v-slot:body>
      <p>Etes-vous sur de vouloir supprimer ce commentaire ?</p>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
      <LoadingButton v-if="isSaving"></LoadingButton>
      <button v-if="!isSaving" type="button" class="btn btn-danger" @click="confirm">Supprimer</button>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import LoadingButton from "@/views/_core/components/LoadingButton.vue";
import { DELETE_COMMENT, GET_COMMENTS } from "../../../data/comments_graphql";
export default {
  name: "DeleteCommentConfirmModal",
  components: {
    KalmModal,
    LoadingButton,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    taskId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    async confirm() {
      this.isSaving = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_COMMENT,
          variables: {
            id: this.comment.id,
            content: this.content,
          },
          refetchQueries: [
            {
              query: GET_COMMENTS,
              variables: {
                taskId: this.taskId,
              },
            },
          ],
        });
      } catch (error) {
        console.error("Delete comment error:", error);
      }
      this.isSaving = false;
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
