<template>
  <!--begin::Form Group-->
  <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
    <label class="fs-6 fw-bolder text-dark form-label">Nouveau logo (jpg ou png de 500px par 500px)</label>
    <input
      accept="image/png,image/jpeg"
      class="form-control form-control-lg form-control-solid"
      type="file"
      @change="onFileChange"
    />
    <div :style="errorMessage ? 'display: block;' : ''" class="fv-plugins-message-container invalid-feedback">
      <div v-if="errorMessage">{{ errorMessage }}</div>
    </div>
  </div>
  <div v-if="this?.existingLogo || this?.preview">
    <div class="text-center">
      <label class="" for="deleteExistingLogo">Supprimer le logo existant</label>
      <input
        id="deleteExistingLogo"
        v-model="this.deleteExistingLogo"
        class="form-check-input ms-1"
        type="checkbox"
        @change="onCheckboxChange"
      />
    </div>
    <div v-if="!deleteExistingLogo" id="preview" class="d-flex justify-content-center">
      <AvatarLogo :height="'mh-50px'" :url="this.preview" alt="test"></AvatarLogo>
    </div>
  </div>
  <!--end::Form Group-->
</template>

<script>
import AvatarLogo from "@/views/_core/components/AvatarLogo.vue";
export default {
  name: "ContactUploadLogoInput",
  components: {
    AvatarLogo,
  },
  props: {
    modelValue: {
      type: File,
      required: true,
    },
    existingLogo: {
      type: String,
      default: null,
    },
    defaultUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      preview: null,
      errorMessage: null,
      deleteExistingLogo: false,
    };
  },
  methods: {
    checkImageDimension(file) {
      const img = new Image();
      if (file) {
        img.src = URL.createObjectURL(file);
        console.log("check image dimension", file);
        img.onload = () => {
          const { width, height } = img;
          console.log(img, width, height);
          if (width !== height || width > 500 || height > 500) {
            console.log("ok condition");
            this.errorMessage = "La photo de profil doit être carrée et avoir une largeur < 500px";
            return false;
          }
        };
      }
      return true;
    },
    onCheckboxChange() {
      if (this.deleteExistingLogo) {
        this.$emit("update:modelValue", "delete");
        this.preview = null;
        return;
      }
    },
    onFileChange(e) {
      if (this.deleteExistingLogo) {
        this.$emit("update:modelValue", "delete");
        this.preview = null;
        return;
      }
      this.errorMessage = null;
      const file = e.target.files[0];
      this.preview = null;
      if (this.checkImageDimension(file)) {
        this.preview = URL.createObjectURL(file);
        this.$emit("update:modelValue", file);
      } else {
        this.$emit("update:modelValue", null);
      }
    },
  },
  mounted() {
    if (this.existingLogo) {
      this.preview = this.existingLogo;
    }
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
