<template>
  <IconButton iconName="camera-fill">
    <template v-slot:before-icon>
      <span class="ms-2">{{ countFilesForLinkedTask }}</span>
    </template>
  </IconButton>
</template>

<script>
import IconButton from "@/views/_core/components/IconButton.vue";
import { GET_TASK_BY_ID } from "@/views/tasks/data/tasks_graphql";

export default {
  name: "FileCounterForTask",
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  components: {
    IconButton,
  },
  computed: {
    countFilesForLinkedTask() {
      return this.task?.files?.length || 0;
    },
  },
  data() {
    return {
      task: {
        files: [],
      },
    };
  },
  apollo: {
    task: {
      query: GET_TASK_BY_ID,
      variables() {
        return {
          taskId: this.taskId,
        };
      },
    },
  },
};
</script>
