<template>
  <KalmModal modalTitle="Todo" @close="close">
    <template v-slot:body>
      <TodoItemForm
        :assignedToCurrentUser="assignedToCurrentUser"
        :canevaId="canevaId"
        :isEmbedded="true"
        :projectId="projectId"
        :readOnly="readOnly"
        :todo-id="todoId"
        :todoItem="todoItem"
        @close="close"
      />
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import TodoItemForm from "./TodoItemForm.vue";
export default {
  name: "TodoItemFormModal",
  props: {
    todoItem: Object,
    todoId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      required: false,
    },
    canevaId: {
      type: String,
      required: false,
    },
    assignedToCurrentUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    KalmModal,
    TodoItemForm,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
