<template>
  <div
    :class="{ 'bg-light border border-dashed border-primary rounded': isDraggingFile }"
    @dragover.prevent="handleDragOver"
    @drop.prevent="handleDrop"
    @dragleave="handleDragLeave"
  >
    <div class="row g-5 pb-3 p-10">
      <input ref="fileInput" type="file" style="display: none" @change="handleFileChange" multiple />
      <div class="d-flex">
        <h4 class="me-auto">Fichiers</h4>
        <button class="btn btn-primary" @click="this.openFileExplorer()">
          <span v-if="this.isUploading" class="spinner-border spinner-border-sm"></span><span v-else>+</span> Ajouter
        </button>
      </div>
      <div v-if="isLoading || files.length" class="table-responsive col-12">
        <table class="table table-borderless align-middle">
          <thead>
            <tr>
              <th class="px-2 py-0 w-50px min-w-100px"></th>
              <th class="px-2 py-0 w-100"></th>
              <th class="px-2 py-0 min-w-250px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="isLoading">
              <tr>
                <th colspan="3" class="text-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Chargement...</span>
                  </div>
                </th>
              </tr>
            </template>
            <template v-else>
              <tr
                v-for="file in files"
                :key="file.id"
                class="bg-hover-light cursor-default"
                draggable="true"
                @dragstart="handleDragStart(file)"
              >
                <td class="px-0 py-3 rounded-start p-5">
                  <div v-if="getFileIcon(file) === 'bi bi-file-image'" class="symbol symbol-55px ms-5 mt-1 me-5">
                    <img
                      :src="getFileUrl(file)"
                      class="img-fluid img-thumbnail mw-100 mh-100 object-fit-cover"
                      alt="file"
                    />
                  </div>
                  <div v-else class="symbol symbol-55px ms-5 mt-1 me-5">
                    <span class="symbol-label bg-light-primary align-middle align-items-end">
                      <i class="fs-1 align-middle align-self-center" :class="getFileIcon(file)"></i>
                    </span>
                  </div>
                </td>
                <td class="px-0">
                  <p class="text-gray-800 fw-bolder fs-6 my-1">{{ file.name }}</p>
                  <span class="text-muted fw-bold d-block mt-1"
                    >Créé le {{ new Date(file.creationDate).toLocaleString() }}</span
                  >
                  <TaskTagList v-if="file.tasks?.length" :tasks="file.tasks" class="mt-2"></TaskTagList>
                </td>
                <td class="px-0 rounded-end">
                  <EditButton @click="onEditFileClick(file)" v-if="editButton" tooltip-name="Renommer"></EditButton>
                  <IconButton
                    iconName="list-task"
                    @click="onAssignTaskClick(file)"
                    v-if="assignButton"
                    tooltip-name="Assigner à une tâche"
                  ></IconButton>
                  <DeleteButton @click="onDeleteFileClick(file)" v-if="deleteButton"></DeleteButton>
                  <IconButton
                    iconName="download"
                    @click="downloadFile(file)"
                    v-if="downloadButton"
                    tooltip-name="Télécharger"
                  ></IconButton>
                  <IconButton iconName="share" @click="shareFile(file)"></IconButton>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div v-else class="py-10">
        <p class="text-center text-muted my-12">
          <span class="bi bi-file-earmark me-2"></span>
          Aucun fichier dans ce dossier. Cliquez sur ajouter <span class="ms-1 bi bi-arrow-up-right"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import EditButton from "@/views/_core/components/EditButton.vue";
import IconButton from "@/views/_core/components/IconButton.vue";
import TaskTagList from "@/views/tasks/presentation/task-tag-list/TaskTagList.vue";
import { FILE_UPLOAD } from "../../../data/files_graphql";
import { getFileIconClass } from "../../../helpers/documents.helpers";

export default {
  name: "FolderList",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      require: true,
    },
    projectId: {
      type: String,
      required: false,
    },
    contactId: {
      type: String,
      required: false,
    },
    parentId: {
      type: String,
    },
    callback: {
      type: Function,
      required: false,
      default: () => {},
    },
    assignButton: {
      type: Boolean,
      default: true,
    },
    editButton: {
      type: Boolean,
      default: true,
    },
    deleteButton: {
      type: Boolean,
      default: true,
    },
    downloadButton: {
      type: Boolean,
      default: true,
    },
    previewButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isUploading: false,
      isDraggingFile: false,
    };
  },
  components: {
    DeleteButton,
    EditButton,
    TaskTagList,
    IconButton,
  },
  methods: {
    getFileUrl(file) {
      if (file.url.startsWith("http")) {
        return file.url;
      }
      return process.env.VUE_APP_URL_BACK + file.url;
    },
    getFileIcon(file) {
      return getFileIconClass(file);
    },
    openFileInNewTab(file) {
      window.open(this.getFileUrl(file), "_blank");
    },
    downloadFile(file) {
      fetch(this.getFileUrl(file))
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file?.name || "download";
          link.click();
        })
        .catch(console.error);
    },
    shareFile(file) {
      this.$emit("onShare", file);
    },
    onEditFileClick(file) {
      this.$emit("onEdit", file);
    },
    onAssignTaskClick(file) {
      this.$emit("onAssignTask", file);
    },
    onDeleteFileClick(folder) {
      this.$emit("onDelete", folder);
    },
    openFileExplorer() {
      // Trigger the click event on the hidden file input
      this.$refs.fileInput.click();
    },
    handleDragStart(file) {
      // Use the event's dataTransfer to set custom data
      event.dataTransfer.setData("kalm-file", JSON.stringify(file));
      event.dataTransfer.effectAllowed = "copy";
    },
    handleDragOver(event) {
      if (event.dataTransfer.types.includes("Files")) {
        event.dataTransfer.dropEffect = "copy";
        this.isDraggingFile = true;
      } else {
        this.isDraggingFile = false;
      }
    },
    handleDragLeave() {
      this.isDraggingFile = false;
    },
    handleDrop(event) {
      this.isDraggingFile = false;
      const droppedFiles = Array.from(event.dataTransfer.files);
      this.handleFileChange({ target: { files: droppedFiles } });
    },
    handleFileChange(event) {
      const selectedFiles = event.target.files;
      for (const file of selectedFiles) {
        this.isUploading = true;
        this.$apollo
          .mutate({
            mutation: FILE_UPLOAD,
            variables: {
              file,
              contactId: this.contactId,
              projectId: this.projectId,
              parentId: this.parentId,
            },
            context: {
              hasUpload: true,
            },
          })
          .then(() => {
            this.callback();
            this.isUploading = false;
          })
          .catch(console.error);
      }
    },
  },
};
</script>
