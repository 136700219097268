<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "WrapperPage",
  components: {},
};
</script>

<style scoped></style>
