<template>
  <div>
    <label class="text-center fw-bolder mb-3">Présence des intervenants :</label>
    <div v-if="this.loading" class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div
        v-for="contactAttendance in value"
        :key="contactAttendance.contactId"
        class="bg-light cursor-default rounded px-3 py-5 mb-5"
      >
        <p class="text-center pb-0 mb-0">
          <span class="bi bi-person text-muted me-2"></span>{{ getName(contactAttendance.contactId) }}
        </p>
        <div class="pt-0 mt-0 mb-3">
          <PresenceButton
            v-model="contactAttendance.attendanceStatus"
            :contactId="contactAttendance.contactId"
          ></PresenceButton>
        </div>
        <!--            <div class="form-check form-switch mb-6">
                        <label class="form-check-label" :for="`switch-present-${contactAttendance.contactId}`">Présent</label>
                        <input class="form-check-input" type="checkbox" role="switch"
                            :id="`switch-present-${contactAttendance.contactId}`" v-model="contactAttendance.isPresent">
                    </div>-->
        <div class="form-check form-switch">
          <label :for="`switch-convoquee-${contactAttendance.contactId}`" class="form-check-label">
            Convoquer à la prochaine réunion
          </label>
          <input
            :id="`switch-convoquee-${contactAttendance.contactId}`"
            v-model="contactAttendance.isConvoque"
            class="form-check-input"
            role="switch"
            type="checkbox"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PresenceButton from "@/views/reports/presentation/report-form/components/PresenceButton.vue";

export default {
  name: "ContactsAttendance",
  components: {
    PresenceButton,
  },
  props: {
    projectId: String,
    loading: Boolean,
    modelValue: Array,
    contacts: Array,
  },
  data() {
    return {
      contactsAttendance: [],
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    getName(contactId) {
      const contact = this.contacts?.filter((contact) => contact.id === contactId);
      if (contact.length) {
        if (contact[0].company && contact[0].name) return `${contact[0].name} (${contact[0].company})`;
        else
          return (
            contact[0]?.company ||
            (contact[0].firstName + (contact[0].firstName && contact[0].name) ? " " : "" + contact[0]?.name)
          );
      }
    },
    getAttendanceStatusForContact(contactId) {
      return this.value.find((contact) => contact.contactId === contactId)?.attendanceStatus;
    },
    isConvoqueInModel(contactId) {
      return this.value.some((contact) => contact.contactId === contactId && contact.isConvoque);
    },
  },
};
</script>
