<template>
  <KalmModal modalTitle="Partager" @close="close">
    <template v-slot:body>
      <div v-if="!!getFullLinkPath()" class="text-center">
        <a ref="link" :href="getFullLinkPath()" target="_blank">{{ getFullLinkPath() }}</a>
        <button class="btn btn-primary mt-4 w-100" @click="copyLink">Copier le lien</button>
        <a
          :disabled="!this.getFullLinkPath()"
          :href="this.getEmailMailToHref"
          class="btn btn-primary mt-4 w-100"
          @click="this.clickedShareByEmail = true"
        >
          Partager par email
        </a>
        <p v-if="this.clickedShareByEmail" class="text-muted text-center mt-2">
          <span class="bi bi-info-circle me-2"></span>Ce bouton ouvre automatiquement votre client mail par défaut, avec
          un e-mail pré-rédigé contenant le lien. Si rien ne se passe ou que le mauvais outil s'ouvre, vérifiez que vous
          avez bien défini votre client e-mail favori comme outil e-mail par défaut.
        </p>
        <p v-if="!!getFormattedExpirationDate()" class="text-muted mt-2">
          Le lien expire le : {{ getFormattedExpirationDate() }}
        </p>
        <button class="btn btn-secondary w-100 mt-8" @click="deleteLink">Supprimer le lien de partage</button>
        <!-- list of link events -->
        <div v-if="getLinkEvents()?.length" class="mt-8">
          <h5 class="text-center">Historique des téléchargements</h5>
          <ul v-for="event in getLinkEvents()" :key="event.id" class="list-group">
            <li class="list-group">
              {{ getFormattedEventDate(event.createdAt) }}
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <label class="form-label" for="date">Date d'expiration (optionnel)</label>
        <input id="date" v-model="expirationDate" class="form-control" type="datetime-local" />
        <button class="btn btn-primary w-100 mt-8" @click="createLink()">Générer un lien de partage</button>
      </div>
    </template>

    <template v-slot:footer>
      <button class="btn btn-secondary" data-bs-dismiss="modal" type="button" @click="close">Ok</button>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import dayjs from "dayjs";
import { GET_LINKS, LINK_CREATE, LINK_DELETE } from "../../data/links_graphql";
import { toast } from "vue3-toastify";
export default {
  name: "LinkCreationModal",
  components: {
    KalmModal,
  },
  props: {
    fileId: {
      type: String,
      required: true,
    },
  },
  apollo: {
    linkByFile: {
      query: GET_LINKS,
      variables() {
        return {
          fileId: this.fileId,
        };
      },
      skip() {
        return !this.fileId;
      },
      fetchPolicy: "cache-and-network",
    },
  },
  data() {
    return {
      linkByFile: undefined,
      expirationDate: undefined,
      clickedShareByEmail: false,
    };
  },
  computed: {
    getEmailMailToHref() {
      if (!this.getFullLinkPath()) return;
      return `mailto:?subject=Partage de fichier&body=Bonjour, je partage avec vous un fichier : ${this.getFullLinkPath()}`;
    },
  },
  methods: {
    async createLink() {
      try {
        await this.$apollo.mutate({
          mutation: LINK_CREATE,
          variables: {
            fileId: this.fileId,
            expirationDate: this.expirationDate,
          },
          refetchQueries: [
            {
              query: GET_LINKS,
              variables: { fileId: this.fileId },
            },
          ],
        });
      } catch (error) {
        console.error("Error creating link:", error);
      }
    },
    async deleteLink() {
      try {
        await this.$apollo.mutate({
          mutation: LINK_DELETE,
          variables: { id: this.linkByFile[0].id },
          refetchQueries: [
            {
              query: GET_LINKS,
              variables: { fileId: this.fileId },
            },
          ],
        });
      } catch (error) {
        console.error("Error deleting link:", error);
      }
    },
    getLinkEvents() {
      if (!this.linkByFile?.length) return;
      return this.linkByFile[0].linkEvents;
    },
    getFormattedExpirationDate() {
      if (!this.linkByFile?.length || !this.linkByFile[0].expirationDate) return;
      return dayjs(this.linkByFile[0].expirationDate).format("DD MMM YYYY - HH:mm");
    },
    getFormattedEventDate(date) {
      return dayjs(date).format("DD MMM YYYY - HH:mm");
    },
    getFullLinkPath() {
      if (!this.linkByFile?.length) return;
      return window.location.origin + "/shared-files/" + this.linkByFile[0].id;
    },
    copyLink() {
      if (!this.getFullLinkPath()) return;
      navigator.clipboard.writeText(this.getFullLinkPath()).then(() => {
        toast.success("Lien copié dans le presse-papier");
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
