<template>
  <div>
    <div v-for="comment in commentsByTask" :key="comment.id" class="bg-light rounded p-4 mb-2">
      <p style="white-space: pre-wrap">{{ comment.content }}</p>
      <div class="flex items-center">
        <i class="bi bi-calendar"></i>
        <span class="ms-2 text-gray-700">{{ getFormattedDate(comment.updatedAt) }}</span>
      </div>
      <div class="flex items-center mt-2">
        <i class="bi bi-person"></i>
        <span class="ms-2 text-gray-700">{{ comment.author.firstName }} {{ comment.author.lastName }}</span>
      </div>

      <EditButton @click="editComment(comment)"></EditButton>
      <DeleteButton @click="showDeleteComment(comment)"></DeleteButton>
    </div>
    <hr class="border-2 border-top border-grey mt-16" />
    <CommentForm class="mt-8" :taskId="taskId"></CommentForm>
    <CommentFormModal v-if="showCommentEditModal" :comment="selectedComment" :taskId="taskId" @close="closeEditComment">
    </CommentFormModal>
    <DeleteCommentConfirmModal
      v-if="showCommentDeleteModal"
      :comment="selectedComment"
      :taskId="taskId"
      @close="closeDeleteComment"
    >
    </DeleteCommentConfirmModal>
  </div>
</template>

<script>
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import EditButton from "@/views/_core/components/EditButton.vue";
import dayjs from "dayjs";
import { GET_COMMENTS } from "../../data/comments_graphql";
import CommentForm from "../comment-form/CommentForm.vue";
import CommentFormModal from "../comment-form/CommentFormModal.vue";
import DeleteCommentConfirmModal from "./components/DeleteCommentConfirmModal";
export default {
  name: "CommentsList",
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  components: {
    CommentForm,
    CommentFormModal,
    DeleteCommentConfirmModal,
    EditButton,
    DeleteButton,
  },
  data() {
    return {
      commentsByTask: [],
      showCommentEditModal: false,
      showCommentDeleteModal: false,
      selectedComment: undefined,
    };
  },
  methods: {
    showDeleteComment(comment) {
      this.selectedComment = comment;
      this.showCommentDeleteModal = true;
    },
    closeDeleteComment() {
      this.showCommentDeleteModal = false;
    },
    editComment(comment) {
      this.selectedComment = comment;
      this.showCommentEditModal = true;
    },
    closeEditComment() {
      this.showCommentEditModal = false;
    },
    getFormattedDate(date) {
      return dayjs(date).format("DD MMM YYYY - HH:mm");
    },
  },
  apollo: {
    commentsByTask: {
      query: GET_COMMENTS,
      variables() {
        return {
          taskId: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
};
</script>
