import gql from "graphql-tag";

export const MUTATION_USER_CREATE = gql`
  mutation ($firstName: String, $lastName: String, $email: String!, $color: String) {
    userCreate(firstName: $firstName, lastName: $lastName, email: $email, color: $color) {
      user {
        id
        firstName
        lastName
        photo
        color
        email
        role
        linkedContact {
          id
        }
      }
    }
  }
`;

export const MUTATION_USER_UPDATE = gql`
  mutation ($id: UUID!, $firstName: String, $lastName: String, $phone: String, $color: String) {
    userUpdate(id: $id, firstName: $firstName, lastName: $lastName, phone: $phone, color: $color) {
      user {
        id
        firstName
        lastName
        photo
        color
        email
        phone
        role
        linkedContact {
          id
        }
      }
    }
  }
`;

export const MUTATION_ORG_UPDATE = gql`
  mutation (
    $id: UUID!
    $name: String
    $infoSiret: String
    $infoAddress: String
    $persoAlwaysForceSave: Boolean
    $persoColorMain: String
    $persoColor1: String
    $persoColor2: String
    $persoColor3: String
    $persoColor4: String
    $persoColor5: String
    $persoColor6: String
    $persoTaskSort1: String
    $persoTaskSort2: String
    $persoTaskSort3: String
    $persoTaskSort4: String
    $persoTaskHideArchived: Boolean
    $persoFontBody: String
    $persoFontTitle: String
  ) {
    orgUpdate(
      id: $id
      name: $name
      infoSiret: $infoSiret
      infoAddress: $infoAddress
      persoAlwaysForceSave: $persoAlwaysForceSave
      persoColorMain: $persoColorMain
      persoColor1: $persoColor1
      persoColor2: $persoColor2
      persoColor3: $persoColor3
      persoColor4: $persoColor4
      persoColor5: $persoColor5
      persoColor6: $persoColor6
      persoTaskSort1: $persoTaskSort1
      persoTaskSort2: $persoTaskSort2
      persoTaskSort3: $persoTaskSort3
      persoTaskSort4: $persoTaskSort4
      persoTaskHideArchived: $persoTaskHideArchived
      persoFontBody: $persoFontBody
      persoFontTitle: $persoFontTitle
    ) {
      org {
        id
        name
        logo
        infoSiret
        infoAddress
        persoAlwaysForceSave
        persoColorMain
        persoColor1
        persoColor2
        persoColor3
        persoColor4
        persoColor5
        persoColor6
        persoTaskSort1
        persoTaskSort2
        persoTaskSort3
        persoTaskSort4
        persoTaskHideArchived
        persoFontBody
        persoFontTitle
      }
    }
  }
`;

export const MUTATION_CREATE_PROJECT = gql`
  mutation ($newProjectName: String!, $newInfoOpAddress: String, $newInfoOpName: String) {
    projectCreate(newInfoOpAddress: $newInfoOpAddress, newInfoOpName: $newInfoOpName, newProjectName: $newProjectName) {
      project {
        id
        name
        address: infoOpAddress
        infoOpName
        importantInfo: infoImportantInfo
        currentPhase
        phases
        archived
        document
        documentName
        contacts {
          role
          roleDisplayName
          hidden
          starred
          contact {
            id
            name
            email
            phone
            type
            company
            infoLine1
            infoLine2
          }
        }
        featureMultiLocation
        lots {
          id
          name
          fullName
        }
        locations {
          id
          name
        }
        lastUpdateTime
      }
    }
  }
`;

export const MUTATION_DUPLICATE_PROJECT = gql`
  mutation (
    $oldProjectId: UUID!
    $newProjectName: String!
    $newInfoOpAddress: String
    $newInfoOpName: String
    $duplicateLocations: Boolean
    $duplicateContacts: Boolean
    $duplicateQts: Boolean
  ) {
    projectDuplicate(
      oldProjectId: $oldProjectId
      newProjectName: $newProjectName
      newInfoOpAddress: $newInfoOpAddress
      newInfoOpName: $newInfoOpName
      duplicateLocations: $duplicateLocations
      duplicateContacts: $duplicateContacts
      duplicateQts: $duplicateQts
    ) {
      project {
        id
        name
        address: infoOpAddress
        infoOpName
        importantInfo: infoImportantInfo
        currentPhase
        phases
        archived
        document
        documentName
        contacts {
          role
          roleDisplayName
          hidden
          starred
          contact {
            id
            name
            email
            phone
            type
            company
            infoLine1
            infoLine2
          }
        }
        featureMultiLocation
        lots {
          id
          name
          fullName
          number
          startDate
          endDate
          comment
          assignedCompany {
            id
            name
            company
          }
        }
        locations {
          id
          name
        }
        lastUpdateTime
      }
    }
  }
`;

export const MUTATION_ARCHIVE_PROJECT = gql`
  mutation ($projectId: UUID!) {
    projectArchive(projectId: $projectId) {
      project {
        id
        name
        address: infoOpAddress
        infoOpName
        importantInfo: infoImportantInfo
        currentPhase
        phases
        archived
        document
        documentName
        contacts {
          role
          roleDisplayName
          hidden
          starred
          contact {
            id
            name
            email
            phone
            type
            company
            infoLine1
            infoLine2
          }
        }
        featureMultiLocation
        lots {
          id
          number
          name
          fullName
          startDate
          endDate
          comment
        }
        locations {
          id
          name
        }
      }
    }
  }
`;

export const MUTATION_UNARCHIVE_PROJECT = gql`
  mutation ($projectId: UUID!) {
    projectUnarchive(projectId: $projectId) {
      project {
        id
        name
        address: infoOpAddress
        infoOpName
        importantInfo: infoImportantInfo
        archived
        document
        documentName
        contacts {
          role
          roleDisplayName
          hidden
          starred
          contact {
            id
            name
            email
            phone
            type
            company
            infoLine1
            infoLine2
          }
        }
        featureMultiLocation
        lots {
          id
          number
          name
          fullName
          startDate
          endDate
          comment
        }
        locations {
          id
          name
        }
      }
    }
  }
`;

export const MUTATION_PROJECT_UPDATE = gql`
  mutation (
    $projectId: UUID!
    $newName: String
    $newArchived: Boolean
    $newInfoImportantInfo: String
    $newInfoOpAddress: String
    $newInfoOpName: String
    $newInfoStartDate: Date
    $newInfoSurface: String
    $projectContactsOrder: [UUID]
    $newCoverPhoto: CustomCoverPhotoUpload
    $tagIds: [UUID]
  ) {
    projectUpdate(
      projectId: $projectId
      newName: $newName
      newArchived: $newArchived
      newInfoImportantInfo: $newInfoImportantInfo
      newInfoOpAddress: $newInfoOpAddress
      newInfoOpName: $newInfoOpName
      newInfoStartDate: $newInfoStartDate
      newInfoSurface: $newInfoSurface
      projectContactsOrder: $projectContactsOrder
      newCoverPhoto: $newCoverPhoto
      tagIds: $tagIds
    ) {
      project {
        id
        name
        archived
        coverPhoto
        address: infoOpAddress
        infoOpName
        importantInfo: infoImportantInfo
        infoStartDate
        infoSurface
        contacts {
          role
          roleDisplayName
          hidden
          starred
          order
          contact {
            id
            name
          }
        }
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const MUTATION_PROJECT_UPDATE_PHASES = gql`
  mutation ($projectId: UUID!, $newPhases: JSONString, $newCurrentPhase: String) {
    projectUpdatePhases(projectId: $projectId, newPhases: $newPhases, newCurrentPhase: $newCurrentPhase) {
      project {
        id
        phases
        currentPhase
      }
    }
  }
`;
export const MUTATION_TENDER_CREATE_PROPOSAL = gql`
  mutation (
    $projectId: UUID!
    $contactId: UUID
    $modeLots: String!
    $lots: [UUID]
    $name: String!
    $importUnit: Boolean
    $importQt: Boolean
    $importPrice: Boolean
  ) {
    projectTenderProposalCreate(
      projectId: $projectId
      companyId: $contactId
      modeLots: $modeLots
      lots: $lots
      name: $name
      importUnit: $importUnit
      importQt: $importQt
      importPrice: $importPrice
    ) {
      proposal {
        id
        name
        hide
        folderId
        countFilesInSpecialFolder
        fileXlsxUrl
        project {
          id
        }
        contact {
          id
          name
          email
          phone
          type
          company
          infoLine1
          infoLine2
        }
        chiffrages {
          id
          ouvrageId
          order
          numero
          title
          description
          groupTitle
          qt
          quantity
          unit
          unitPrice
          value
          vatRate
          vatValue
          lot {
            id
            number
            fullName
          }
          location {
            id
            name
          }
          locations {
            id
            name
          }
        }
        lotGlobalValues
        lots {
          id
          number
          fullName
        }
        modeLots
        createdDate
        notes
        steps
        vatDefaultValue
      }
    }
  }
`;

export const MUTATION_TENDER_DUPLICATE_PROPOSAL = gql`
  mutation ($proposalId: UUID!, $name: String!) {
    projectTenderProposalDuplicate(proposalId: $proposalId, name: $name) {
      proposal {
        id
        name
        hide
        folderId
        countFilesInSpecialFolder
        fileXlsxUrl
        project {
          id
        }
        contact {
          id
          name
          email
          phone
          type
          company
          infoLine1
          infoLine2
        }
        chiffrages {
          id
          ouvrageId
          order
          numero
          title
          description
          groupTitle
          qt
          quantity
          unit
          unitPrice
          value
          vatRate
          vatValue
          lot {
            id
            number
            fullName
          }
          location {
            id
            name
          }
          locations {
            id
            name
          }
        }
        lotGlobalValues
        lots {
          id
          number
          fullName
        }
        modeLots
        createdDate
        notes
        steps
        vatDefaultValue
      }
    }
  }
`;

export const MUTATION_TENDER_DELETE_PROPOSAL = gql`
  mutation ($proposalId: UUID!) {
    projectTenderProposalDelete(proposalId: $proposalId) {
      deleted
    }
  }
`;

export const MUTATION_TENDER_MANAGE_PROPOSAL = gql`
  mutation ($proposalId: UUID!, $newProposal: ProposalInput!, $modeLots: String!, $lots: [UUID]) {
    projectTenderProposalManage(proposalId: $proposalId, newProposal: $newProposal, modeLots: $modeLots, lots: $lots) {
      proposal {
        id
        name
        hide
        folderId
        countFilesInSpecialFolder
        fileXlsxUrl
        project {
          id
        }
        contact {
          id
          name
          email
          phone
          type
          company
          infoLine1
          infoLine2
        }
        chiffrages {
          id
          ouvrageId
          order
          numero
          title
          description
          groupTitle
          qt
          quantity
          unit
          unitPrice
          value
          vatRate
          vatValue
          lot {
            id
            number
            fullName
          }
          location {
            id
            name
          }
          locations {
            id
            name
          }
        }
        lotGlobalValues
        lots {
          id
          number
          fullName
        }
        modeLots
        createdDate
        notes
        steps
        vatDefaultValue
      }
    }
  }
`;

export const MUTATION_TENDER_HIDE_PROPOSAL = gql`
  mutation ($proposalId: UUID!, $hide: Boolean!) {
    projectTenderProposalHide(proposalId: $proposalId, hide: $hide) {
      proposal {
        id
        hide
      }
    }
  }
`;

export const MUTATION_TENDER_PROPOSAL_UPDATE_CHIFFRAGE = gql`
  mutation ($proposalId: UUID!, $newChiffrage: JSONString!) {
    projectTenderProposalChiffrageUpdate(proposalId: $proposalId, newChiffrage: $newChiffrage) {
      proposal {
        id
        name
        hide
        folderId
        countFilesInSpecialFolder
        fileXlsxUrl
        project {
          id
        }
        contact {
          id
          name
          email
          phone
          type
          company
          infoLine1
          infoLine2
        }
        chiffrages {
          id
          ouvrageId
          order
          numero
          title
          description
          groupTitle
          qt
          quantity
          unit
          unitPrice
          value
          vatRate
          vatValue
          lot {
            id
            number
            fullName
          }
          location {
            id
            name
          }
          locations {
            id
            name
          }
        }
        lotGlobalValues
        lots {
          id
          number
          fullName
        }
        modeLots
        createdDate
        notes
        steps
        vatDefaultValue
      }
    }
  }
`;

export const MUTATION_TENDER_CREATE_SCENARIO = gql`
  mutation ($projectId: UUID!, $name: String!, $graph: JSONString!) {
    projectTenderScenarioCreate(projectId: $projectId, name: $name, graph: $graph) {
      scenario {
        id
        name
        graph
        project {
          id
        }
      }
    }
  }
`;

export const MUTATION_TENDER_UPDATE_SCENARIO = gql`
  mutation ($scenarioId: UUID!, $name: String!, $graph: JSONString!) {
    projectTenderScenarioUpdate(scenarioId: $scenarioId, name: $name, graph: $graph) {
      scenario {
        id
        name
        graph
        project {
          id
        }
      }
    }
  }
`;

export const MUTATION_TENDER_DELETE_SCENARIO = gql`
  mutation ($scenarioId: UUID!) {
    projectTenderScenarioDelete(scenarioId: $scenarioId) {
      deleted
    }
  }
`;

export const UPLOAD_DOCUMENT_MUTATION = gql`
  mutation (
    $projectId: String!
    $newDoc: GenericScalar!
    $documentName: String
    $lastDocUpdate: String
    $forceSave: Boolean
  ) {
    updateProjectDocument(
      projectId: $projectId
      newDoc: $newDoc
      documentName: $documentName
      lastDocUpdate: $lastDocUpdate
      forceSave: $forceSave
    ) {
      project {
        id
        document
        documentName
        lastDocUpdate
      }
    }
  }
`;

export const MUTATION_PROJECT_UPLOAD_TASKS = gql`
  mutation ($projectId: UUID!, $mode: String, $lots: [UUID]) {
    projectUploadTasks(projectId: $projectId, mode: $mode, lots: $lots) {
      project {
        id
        name
      }
    }
  }
`;

export const ALL_PROJECTS_NAMES_QUERY = gql`
  query {
    projects: allProjects {
      id
      name
      archived
      address: infoOpAddress
      coverPhoto
      lastUpdateTime
    }
  }
`;

export const ALL_PROJECTS_QUERY = gql`
  query {
    projects: allProjects {
      id
      name
      address: infoOpAddress
      infoOpName
      currentPhase
      phases
      importantInfo: infoImportantInfo
      archived
      document
      coverPhoto
      contacts {
        role
        roleDisplayName
        hidden
        starred
        hidden
        starred
        contact {
          id
          name
          email
          phone
          type
          company
          infoLine1
          infoLine2
        }
      }
      lots {
        id
        name
        fullName
        number
        assignedCompany {
          id
          name
          company
        }
        startDate
        endDate
        comment
      }
      locations {
        id
        name
      }
      featureMultiLocation
      lastUpdateTime
      tags {
        id
        name
        color
      }
    }
  }
`;

export const ALL_PROJECTS_SIMPLE_QUERY = gql`
  query {
    projects: allProjects {
      id
      name
      infoOpAddress
      archived
      coverPhoto
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query ($id: UUID!) {
    project: projectById(id: $id) {
      id
      name
      address: infoOpAddress
      infoOpName
      coverPhoto
      currentPhase
      phases
      importantInfo: infoImportantInfo
      archived
      document
      documentName
      contacts {
        role
        roleDisplayName
        hidden
        starred
        contact {
          id
          name
          email
          phone
          type
          company
          infoLine1
          infoLine2
          logoUrl
        }
      }
      lots {
        id
        number
        name
        fullName
        startDate
        endDate
        comment
        assignedCompany {
          id
          name
          company
        }
      }
      locations {
        id
        name
      }
      featureMultiLocation
      betaSpreadsheet
      lastUpdateTime
      lastDocUpdate
      tags {
        id
        name
        color
      }
    }
  }
`;

export const ALL_BIBLIO_QUERY = gql`
  query {
    biblios: allBiblios {
      id
      name
      type
      document
      tags {
        id
        name
        color
      }
    }
  }
`;

export const BIBLIO_ADD_OUVRAGE_MUTATION = gql`
  mutation ($id: UUID!, $ouvrage: String!) {
    biblioAddOuvrage(id: $id, ouvrage: $ouvrage) {
      biblio {
        id
        name
        type
        document
      }
    }
  }
`;

export const BIBLIO_ADD_TEXT_MUTATION = gql`
  mutation ($id: UUID!, $textNode: String!) {
    biblioAddText(id: $id, textNode: $textNode) {
      biblio {
        id
        name
        type
        document
      }
    }
  }
`;

export const GET_BIBLIO_BY_ID = gql`
  query ($id: UUID!) {
    biblio: biblioById(id: $id) {
      id
      name
      type
      document
      tags {
        id
        name
        color
      }
    }
  }
`;

export const GET_NOTES_BY_PROJECT_ID = gql`
  query ($id: UUID!) {
    notes: notesByProjectId(id: $id) {
      id
      title
      createdAt
      lastUpdate
    }
  }
`;

export const GET_NOTE_BY_ID = gql`
  query ($id: UUID!, $projectId: UUID!) {
    note: noteById(id: $id, projectId: $projectId) {
      id
      title
      createdAt
      updatedAt
      document
      lastUpdate
    }
  }
`;

export const MUTATION_NOTES_UPDATE_BY_PROJECT_ID = gql`
  mutation ($projectId: UUID!, $document: GenericScalar!, $lastUpdate: String!, $noteId: UUID!, $title: String) {
    notesUpdateByProjectId(
      projectId: $projectId
      document: $document
      lastUpdate: $lastUpdate
      noteId: $noteId
      title: $title
    ) {
      note {
        id
        title
        createdAt
        updatedAt
        document
        lastUpdate
      }
    }
  }
`;

export const CREATE_NOTE_MUTATION = gql`
  mutation ($projectId: UUID!, $title: String!) {
    noteCreate(projectId: $projectId, title: $title) {
      note {
        id
        title
        createdAt
        updatedAt
        document
        lastUpdate
      }
    }
  }
`;

export const DELETE_NOTE_MUTATION = gql`
  mutation ($projectId: UUID!, $noteId: UUID!) {
    noteDelete(projectId: $projectId, noteId: $noteId) {
      success
    }
  }
`;

export const MUTATION_BIBLIO_CREATE = gql`
  mutation ($newBiblioName: String!, $duplicateFrom: UUID, $tags: [UUID]) {
    biblioCreate(newBiblioName: $newBiblioName, duplicateFrom: $duplicateFrom, tagIds: $tags) {
      biblio {
        id
        name
        type
        document
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const MUTATION_BIBLIO_UPDATE = gql`
  mutation ($id: UUID!, $name: String!, $document: GenericScalar!, $tags: [UUID]) {
    biblioUpdate(id: $id, name: $name, document: $document, tagIds: $tags) {
      biblio {
        id
        name
        type
        document
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const MUTATION_BIBLIO_DELETE = gql`
  mutation ($id: UUID!) {
    biblioDelete(id: $id) {
      biblio {
        id
      }
    }
  }
`;

export const MUTATION_BIBLIO_DUPLICATE = gql`
  mutation ($id: UUID!, $name: String!) {
    biblioDuplicate(id: $id, name: $name) {
      biblio {
        id
        name
        type
        document
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const BULK_UPDATE_PROPOSALS_ORDER = gql`
  mutation BulkUpdateProposalOrder($proposals: [JSONString!]!) {
    bulkUpdateProposalOrder(proposals: $proposals) {
      success
    }
  }
`;

export const GET_PROPOSALS_FOR_PROJECT = gql`
  query ProposalsByProjectId($projectId: UUID!) {
    proposals: proposalsByProjectId(projectId: $projectId) {
      id
      name
      hide
      order
      folderId
      countFilesInSpecialFolder
      fileXlsxUrl
      project {
        id
      }
      contact {
        id
        name
        email
        phone
        type
        company
        infoLine1
        infoLine2
      }
      chiffrages {
        id
        ouvrageId
        order
        numero
        title
        description
        groupTitle
        qt
        quantity
        unit
        unitPrice
        value
        vatRate
        vatValue
        lot {
          id
          number
          fullName
        }
        comment
        color
        location {
          id
          name
        }
        locations {
          id
          name
        }
      }
      lotGlobalValues
      lots {
        comment
        color
        sum
        id
        number
        name
        fullName
      }
      modeLots
      createdDate
      notes
      steps
      vatDefaultValue
    }
  }
`;

export const GET_SCENARIOS_FOR_PROJECT = gql`
  query ScenariosByProjectId($projectId: UUID!) {
    scenarios: scenariosByProjectId(projectId: $projectId) {
      id
      project {
        id
      }
      name
      graph
    }
  }
`;

export const GET_CRS_FOR_PROJECT = gql`
  query CrsByProjectId($projectId: UUID!) {
    monitoringReports: crsByProjectId(projectId: $projectId) {
      id
      url
      reportName
      reportNumber
      reportDate
    }
  }
`;

export const MUTATION_TENDER_UPDATE_OUVRAGES = gql`
  mutation ProjectTenderUpdateOuvrages(
    $projectId: UUID!
    $importUnit: Boolean
    $importQt: Boolean
    $importPrice: Boolean
  ) {
    projectTenderUpdateOuvrages(
      projectId: $projectId
      importUnit: $importUnit
      importQt: $importQt
      importPrice: $importPrice
    ) {
      tender {
        proposals {
          id
          name
          hide
          folderId
          countFilesInSpecialFolder
          fileXlsxUrl
          project {
            id
          }
          contact {
            id
            name
            email
            phone
            type
            company
            infoLine1
            infoLine2
          }
          chiffrages {
            id
            ouvrageId
            order
            numero
            title
            description
            groupTitle
            qt
            quantity
            unit
            unitPrice
            value
            vatRate
            vatValue
            lot {
              id
              number
              fullName
            }
            location {
              id
              name
            }
            locations {
              id
              name
            }
          }
          lotGlobalValues
          lots {
            id
            number
            fullName
          }
          modeLots
          createdDate
          notes
          steps
          vatDefaultValue
        }
      }
    }
  }
`;

export const MUTATION_TENDER_PROPOSAL_UPDATE_OUVRAGES = gql`
  mutation ProjectTenderUpdateOuvrages(
    $proposalId: UUID!
    $importUnit: Boolean
    $importQt: Boolean
    $importPrice: Boolean
    $resetExisting: Boolean
  ) {
    projectTenderProposalUpdateOuvrages(
      proposalId: $proposalId
      importUnit: $importUnit
      importQt: $importQt
      importPrice: $importPrice
      resetExisting: $resetExisting
    ) {
      proposal {
        id
        name
        hide
        folderId
        countFilesInSpecialFolder
        fileXlsxUrl
        project {
          id
        }
        contact {
          id
          name
          email
          phone
          type
          company
          infoLine1
          infoLine2
        }
        chiffrages {
          id
          ouvrageId
          order
          numero
          title
          description
          groupTitle
          qt
          quantity
          unit
          unitPrice
          value
          vatRate
          vatValue
          lot {
            id
            number
            fullName
          }
          location {
            id
            name
          }
          locations {
            id
            name
          }
        }
        lotGlobalValues
        lots {
          id
          number
          fullName
        }
        modeLots
        createdDate
        notes
        steps
        vatDefaultValue
      }
    }
  }
`;

export const PLANNING_UPDATE_INFO_MUTATION = gql`
  mutation PlanningUpdateInfo($projectId: UUID!, $startDate: Date, $endDate: Date) {
    updateInfo(projectId: $projectId, startDate: $startDate, endDate: $endDate) {
      planning {
        id
        startDate
        endDate
      }
    }
  }
`;

export const PLANNING_FOR_PROJECT_QUERY = gql`
  query PlanningByProjectId($projectId: UUID!) {
    planning: planningByProjectId(projectId: $projectId) {
      id
      startDate
      endDate
      phases {
        id
        indentationLevel
        hidden
        parent {
          id
        }
        startDate
        endDate
        title
        description
        colorBg
        colorText
        subPhases {
          id
          indentationLevel
          hidden
          parent {
            id
          }
          startDate
          endDate
          title
          description
          colorBg
          colorText
          subPhases {
            id
            indentationLevel
            hidden
            parent {
              id
            }
            startDate
            endDate
            title
            description
            colorBg
            colorText
            subPhases {
              indentationLevel
              id
              hidden
              parent {
                id
              }
              startDate
              endDate
              title
              description
              colorBg
              colorText
            }
          }
        }
      }
    }
  }
`;

export const PLANNING_CREATE_PHASE_MUTATION = gql`
  mutation PlanningCreatePhase(
    $colorBg: String
    $colorText: String
    $description: String
    $endDate: Date
    $parent: UUID
    $projectId: UUID!
    $startDate: Date
    $title: String
  ) {
    createPhase(
      colorBg: $colorBg
      colorText: $colorText
      description: $description
      endDate: $endDate
      parent: $parent
      projectId: $projectId
      startDate: $startDate
      title: $title
    ) {
      phase {
        id
        hidden
        parent {
          id
        }
        startDate
        endDate
        title
        description
        colorBg
        colorText
        subPhases {
          id
        }
      }
    }
  }
`;

export const PLANNING_UPDATE_PHASE_MUTATION = gql`
  mutation PlanningUpdatePhase(
    $id: UUID!
    $hidden: Boolean
    $colorBg: String
    $colorText: String
    $description: String
    $endDate: Date
    $projectId: UUID!
    $startDate: Date
    $title: String
  ) {
    updatePhase(
      id: $id
      hidden: $hidden
      colorBg: $colorBg
      colorText: $colorText
      description: $description
      endDate: $endDate
      projectId: $projectId
      startDate: $startDate
      title: $title
    ) {
      phase {
        id
        hidden
        parent {
          id
        }
        startDate
        endDate
        title
        description
        colorBg
        colorText
        subPhases {
          id
          hidden
          parent {
            id
          }
          startDate
          endDate
          title
          description
          colorBg
          colorText
          subPhases {
            id
            hidden
            parent {
              id
            }
            startDate
            endDate
            title
            description
            colorBg
            colorText
          }
        }
      }
    }
  }
`;

export const PLANNING_DELETE_PHASE_MUTATION = gql`
  mutation PlanningUpdatePhase($id: UUID!, $projectId: UUID!) {
    deletePhase(id: $id, projectId: $projectId) {
      phase {
        id
      }
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    currentUser {
      id
      email
      phone
      firstName
      lastName
      groups
      org {
        id
        name
        logo
        infoSiret
        infoAddress
        persoAlwaysForceSave
        persoColorMain
        persoColor1
        persoColor2
        persoColor3
        persoColor4
        persoColor5
        persoColor6
        persoTaskSort1
        persoTaskSort2
        persoTaskSort3
        persoTaskSort4
        persoTaskHideArchived
        persoFontBody
        persoFontTitle
        team {
          id
          firstName
          lastName
          photo
          color
          email
          phone
          role
          linkedContact {
            id
          }
        }
      }
      isSuperuser
      orgs {
        id
        name
      }
      appsheetUrl
      role
      trustleadToken
      trustleadActivated
      trustleadOrganizationId
    }
  }
`;

export const MUTATION_SUPERUSER_CHANGE_ORG = gql`
  mutation ($newOrgId: UUID!) {
    superuserChangeOrg(newOrgId: $newOrgId) {
      projects {
        id
        name
      }
    }
  }
`;
