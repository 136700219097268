<template>
  <div class="text-center">
    <img alt="No Todo" :src="image" class="img-fluid w-75" />
    <h3 class="card-title mt-8 mb-5">
      <span class="card-label fw-bolder text-dark fs-3">{{ message }}</span>
    </h3>
    <a class="btn btn-primary fw-bolder" @click="emitMainAction">{{ buttonMessage }}</a>
  </div>
</template>

<script>
export default {
  name: "TenderEstimationEmptyState",
  props: {
    message: {
      type: String,
      default: "Il n'y a pas d'estimatifs sur ce projet",
    },
    buttonMessage: {
      type: String,
      default: "Ajouter un estimatif",
    },
    image: {
      type: String,
      default: "/assets/media/svg/empty-states/empty-estimation.svg",
    },
  },
  methods: {
    emitMainAction() {
      this.$emit("mainAction");
    },
  },
};
</script>
