<template>
  <!-- MAIN -->
  <div :class="modalActive ? 'blur-content' : ''">
    <PrintHeader
      :sectionTitle="this.printMode === 'project' ? 'Planning du projet' : 'Planning de chantier'"
      pageTitle="Planning"
    ></PrintHeader>
    <div class="row gy-5">
      <div class="col-12 print-hidden">
        <div class="card">
          <div class="card-header border-0 justify-content-between">
            <h3 class="card-title align-text-bottom">
              <span class="card-label fw-bolder text-dark fs-3">
                <i class="bi fs-3 me-2 bi-calendar4-event"></i>Information du Chantier
              </span>
            </h3>
          </div>
          <div class="card-body">
            <div class="form-group mb-3">
              <label class="text-muted">Date de début de chantier</label>
              <input v-model="this.localPlanning.startDate" class="form-control form-control-solid" type="date" />
            </div>
            <div class="form-group mb-3">
              <label class="text-muted">Date de fin de chantier</label>
              <input
                v-model="this.localPlanning.endDate"
                :min="this.localPlanning.startDate"
                class="form-control form-control-solid"
                type="date"
              />
            </div>
            <div class="d-flex justify-content-end w-100">
              <div class="">
                <button
                  :disabled="
                    this.localPlanning?.startDate === this.planning?.startDate &&
                    this.localPlanning?.endDate === this.planning?.endDate
                  "
                  class="btn btn-primary"
                  @click="this.updatePlanningDate()"
                >
                  Enregistrer
                  <span
                    v-if="this.buttonUserPropertiesLoading"
                    class="ms-1 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Chargement...</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="this.printMode === 'chantier' ? 'print-hidden' : ''" class="col-12">
        <div class="card print-hidden">
          <div class="d-flex card-header border-0 pt-5 justify-content-between">
            <!-- TITLE -->
            <div>
              <h3 class="card-title align-text-bottom flex-column print-hidden">
                <span class="card-label fw-bolder text-dark fs-3">
                  <i class="bi fs-3 me-2 bi-calendar4-event"></i>
                  Planning projet
                </span>
              </h3>
            </div>
            <!-- ALERTE PLANNING GLOBAL -->
            <div
              class="mt-3 alert print-hidden bg-light-primary border border-dashed border-primary d-flex flex-column flex-sm-row"
            >
              <div class="">
                <span class="bi bi-cone-striped text-primary me-4 fs-1 align-middle"></span>
              </div>
              <div class="d-flex flex-column pe-0 pe-sm-10">
                <h5 class="mb-1">Planning de projet</h5>
                <p class="mb-1">
                  Un planning complet qui vous permettra de détailler des phases et sous-phases de manière plus flexible
                  que dans le planning de chantier. Cet outil étant voué à rester très simple, nous vous conseillons de
                  l'utiliser pour clarifier les différentes phases du projet à votre client, mais d'utiliser un outil
                  plus complet (ou un Excel, plus flexible) pour réaliser un planning précis de chantier. Pour améliorer
                  la mise en page de l'export, nous vous conseillons de :
                </p>
                <ul>
                  <li>Afficher les options avancées d'impression, puis :</li>
                  <li>Décocher l'option "en-tête et pied de page"</li>
                  <li>Réduire le niveau de zoom de l'export</li>
                </ul>
                <p>
                  Note : les dates de début et de fin sont calculées automatiquement à un instant T pour que toutes les
                  tâches soient visibles (elles ne s'enregistrent pas entre deux sessions)
                </p>
              </div>
              <button
                class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                data-bs-dismiss="alert"
                type="button"
              >
                <i class="bi bi-x fs-1"></i>
              </button>
            </div>

            <!-- INPUTS -->
            <div class="d-flex align-baseline">
              <div class="me-2">
                <div class="input-group input-group-solid">
                  <label class="input-group-text text-muted">Début :</label>
                  <input v-model="this.ganttConfig.startDate" class="form-control form-control-solid" type="date" />
                </div>
              </div>
              <div class="me-2">
                <div class="input-group input-group-solid">
                  <label class="input-group-text text-muted">Précision :</label>
                  <select v-model="this.ganttConfig.precision" class="form-select form-select-solid">
                    <option value="day">Jour</option>
                    <option value="week">Semaine</option>
                    <option value="month">Mois</option>
                  </select>
                </div>
              </div>
              <div>
                <div class="input-group input-group-solid">
                  <label class="input-group-text text-muted">Fin :</label>
                  <input v-model="this.ganttConfig.endDate" class="form-control form-control-solid" type="date" />
                </div>
              </div>
            </div>
            <div class="">
              <button
                v-tooltip:bottom="'Télécharger le planning de projet'"
                class="btn me-1 btn-primary btn-icon print-hidden"
                type="button"
                @click="this.printGlobalPlanning()"
              >
                <span class="bi align-baseline bi-download"></span>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div ref="planningGlobal" class="d-flex">
              <g-gantt-chart
                :chart-end="this.ganttConfig.endDate || Date.now()"
                :chart-start="this.ganttConfig.startDate || Date.now()"
                :precision="this.ganttConfig.precision"
                :row-height="50"
                bar-end="endDate"
                bar-start="startDate"
                class="w-100"
                date-format="YYYY-MM-DD"
                grid
                label-column-title="Phases"
                label-column-width="250px"
                width="100%"
                @dragend-bar="dragendBar"
              >
                <template #label-column-row="{ label, bars }">
                  <CustomGGanttLabelColumnRow
                    :bars="bars"
                    :label="label"
                    @createSubPhase="createSubPhase"
                    @deletePhase="deletePhase"
                    @hidePhase="hidePhase"
                    @updatePhase="updatePhase"
                  ></CustomGGanttLabelColumnRow>
                </template>
                <template v-for="phase in flattenPhases" :key="phase.id">
                  <g-gantt-row :bars="[this.localPlanning.phases[phase.id]]" :label="phase.title" />
                </template>
              </g-gantt-chart>
            </div>
            <div class="mt-2">
              <button
                class="btn btn-sm btn-bg-light btn-text-gray-600 btn-active-primary btn-active-white"
                @click="modalPlanningAddPhaseShow()"
              >
                Ajouter une phase<span class="ms-2 bi bi-plus-circle"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="card print-planning">
          <div class="card-body">
            <img
              v-if="this.planningGlobalImageUrl && this.printMode === 'project'"
              :src="this.planningGlobalImageUrl"
              alt="capture d'écran du planning pour l'impression"
              class="mw-100"
            />
          </div>
        </div>
      </div>
      <div :class="this.printMode === 'project' ? 'print-hidden' : ''" class="col-12">
        <div class="card print-hidden">
          <div class="d-flex card-header border-0 pt-5 justify-content-between">
            <div>
              <h3 class="card-title align-text-bottom flex-column print-hidden">
                <span class="card-label fw-bolder text-dark fs-3"
                  ><i class="bi fs-3 me-2 bi-calendar4-event"></i>Chantier</span
                >
              </h3>
            </div>
            <!-- TOOLBAR -->
            <div class="d-flex align-baseline">
              <div class="me-2">
                <div class="input-group input-group-solid">
                  <label class="input-group-text text-muted">Début :</label>
                  <input
                    v-model="this.ganttConfigChantier.startDate"
                    class="form-control form-control-solid"
                    type="date"
                  />
                </div>
              </div>
              <div class="me-2">
                <div class="input-group input-group-solid">
                  <label class="input-group-text text-muted">Précision :</label>
                  <select v-model="this.ganttConfigChantier.precision" class="form-select form-select-solid">
                    <option value="day">Jour</option>
                    <option value="week">Semaine</option>
                    <option value="month">Mois</option>
                  </select>
                </div>
              </div>
              <div>
                <div class="input-group input-group-solid">
                  <label class="input-group-text text-muted">Fin :</label>
                  <input
                    v-model="this.ganttConfigChantier.endDate"
                    class="form-control form-control-solid"
                    type="date"
                  />
                </div>
              </div>
            </div>
            <div class="">
              <button
                v-tooltip:bottom="'Télécharger le planning de chantier'"
                class="btn btn-primary btn-icon fw-bolder print-hidden"
                type="button"
                @click="this.printPlanningChantier()"
              >
                <span class="bi align-baseline bi-download"></span>
              </button>
            </div>
          </div>
          <div class="card-body">
            <!-- ALERTE PLANNING CHANTIER -->
            <div
              class="alert print-hidden bg-light-primary border border-dashed border-primary d-flex flex-column flex-sm-row"
            >
              <div class="">
                <span class="bi bi-cone-striped text-primary me-4 fs-1 align-middle"></span>
              </div>
              <div class="d-flex flex-column pe-0 pe-sm-10">
                <h5 class="mb-1">Planning de chantier</h5>
                <p class="mb-1">
                  Un planning rudimentaire qui vous permettra d'ajouter une date de début et de fin pour chaque lot de
                  votre projet, ainsi qu'un commentaire. C'est le planning qui apparaîtra sur les comptes-rendus de
                  chantier. Pour ajouter des sous-phases, utilisez le planning de projet au-dessus.
                </p>
              </div>
              <button
                class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                data-bs-dismiss="alert"
                type="button"
              >
                <i class="bi bi-x fs-1"></i>
              </button>
            </div>
            <!-- PLANNING DE CHANTIER - LISTE DES LOTS -->
            <div class="table-responsive print-hidden mt-8">
              <table class="table table-borderless mb-0 align-middle">
                <thead>
                  <tr>
                    <th class="p-0 min-w-150px"></th>
                    <th class="p-0 min-w-50px"></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="lot in currentProject.lots" :key="lot.id">
                    <tr style="break-inside: avoid; break-after: avoid">
                      <td :class="lot.number.includes('.') ? 'ps-5' : ''" class="px-0">
                        <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ lot.fullName }}</a>
                        <span class="text-gray-700 fw-bold d-block mt-1">{{
                          lot.assignedCompany?.id
                            ? lot.assignedCompany.company || lot.assignedCompany.name
                            : "Non attribué"
                        }}</span>
                        <span v-if="lot.startDate || lot.endDate" class="text-gray-700 fw-light d-block mt-1">
                          <i class="bi bi-calendar4-event me-2"></i>
                          {{ lot.startDate ? new Date(lot.startDate).toLocaleDateString() : "À déterminer" }}
                          >
                          {{ lot.endDate ? new Date(lot.endDate).toLocaleDateString() : "À déterminer" }}
                        </span>
                      </td>
                      <td class="text-end w-100px pe-0 print-hidden">
                        <button
                          v-tooltip:bottom="'Modifier'"
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                          @click="modalEditLotPlanningShow(lot.id)"
                        >
                          <span class="bi bi-pencil-fill"></span>
                        </button>
                      </td>
                    </tr>
                    <tr style="break-inside: avoid">
                      <td :class="lot.number.includes('.') ? 'ps-5' : 'ps-0'" class="p-0 row">
                        <div class="col-1" style="width: 20px">
                          <i class="bi bi-chat-text align-baseline me-2"></i>
                        </div>
                        <div class="col-11" style="">
                          <p v-if="lot.comment" class="text-gray-700 fw-light fs-6" style="white-space: pre-wrap">
                            {{ lot.comment }}
                          </p>
                          <p v-else class="text-gray-700 fw-light fs-6">Aucun commentaire</p>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card print-planning">
          <div class="card-body">
            <!-- PLANNING DE CHANTIER - LISTE DES LOTS -->
            <div :class="this.printMode === 'project' ? 'print-hidden' : ''" class="table-responsive mt-8">
              <table class="table table-borderless mb-0 align-middle">
                <thead>
                  <tr>
                    <th class="p-0 min-w-150px"></th>
                    <th class="p-0 min-w-50px"></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="lot in currentProject.lots" :key="lot.id">
                    <tr style="break-inside: avoid; break-after: avoid">
                      <td :class="lot.number.includes('.') ? 'ps-5' : ''" class="px-0">
                        <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ lot.fullName }}</a>
                        <span class="text-gray-700 fw-bold d-block mt-1">{{
                          lot.assignedCompany?.id
                            ? lot.assignedCompany.company || lot.assignedCompany.name
                            : "Non attribué"
                        }}</span>
                        <span v-if="lot.startDate || lot.endDate" class="text-gray-700 fw-light d-block mt-1"
                          ><i class="bi bi-calendar4-event me-2"></i
                          >{{ lot.startDate ? new Date(lot.startDate).toLocaleDateString() : "À déterminer" }}
                          >
                          {{ lot.endDate ? new Date(lot.endDate).toLocaleDateString() : "À déterminer" }}</span
                        >
                      </td>
                      <td class="text-end w-100px pe-0 print-hidden">
                        <button
                          v-tooltip:bottom="'Modifier'"
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                          @click="modalEditLotPlanningShow(lot.id)"
                        >
                          <span class="bi bi-pencil-fill"></span>
                        </button>
                      </td>
                    </tr>
                    <tr style="break-inside: avoid">
                      <td :class="lot.number.includes('.') ? 'ps-5' : 'ps-0'" class="p-0 row">
                        <div class="col-1" style="width: 20px">
                          <i class="bi bi-chat-text align-baseline me-2"></i>
                        </div>
                        <div class="col-11" style="">
                          <p v-if="lot.comment" class="text-gray-700 fw-light fs-6" style="white-space: pre-wrap">
                            {{ lot.comment }}
                          </p>
                          <p v-else class="text-gray-700 fw-light fs-6">Aucun commentaire</p>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL CREATE PHASE -->
  <div :style="modals.planningCreatePhase.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalPlanningAddPhaseCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">
                          {{ this.modals.planningCreatePhase.modalTitle }}
                        </h3>
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom</label>
                        <input
                          v-model="this.modals.planningCreatePhase.item.title"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="APS"
                          type="text"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                        <input
                          v-model="this.modals.planningCreatePhase.item.startDate"
                          class="form-control form-control-lg form-control-solid"
                          type="date"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                        <input
                          v-model="this.modals.planningCreatePhase.item.endDate"
                          class="form-control form-control-lg form-control-solid"
                          type="date"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaires</label>
                        <textarea
                          v-model="this.modals.planningCreatePhase.item.comment"
                          class="form-control form-control-solid form-text"
                        ></textarea>
                      </div>

                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Couleur</label>
                        <input
                          v-model="this.modals.planningCreatePhase.item.colorBg"
                          class="form-control form-control-lg form-control-solid"
                          type="color"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" type="button" @click="this.modalPlanningAddPhaseCancel()">
                        Annuler
                      </button>
                      <button
                        :disabled="!this.modals.planningCreatePhase.item.title"
                        class="btn btn-lg btn-primary ms-2"
                        type="button"
                        @click="this.modalPlanningAddPhaseValidate()"
                      >
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL EDIT PHASE -->
  <div :style="modals.planningEditPhase.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalPlanningEditPhaseCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">
                          {{ this.modals.planningEditPhase.modalTitle }}
                        </h3>
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom</label>
                        <input
                          v-model="this.modals.planningEditPhase.item.title"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="APS"
                          type="text"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                        <input
                          v-model="this.modals.planningEditPhase.item.startDate"
                          class="form-control form-control-lg form-control-solid"
                          type="date"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                        <input
                          v-model="this.modals.planningEditPhase.item.endDate"
                          class="form-control form-control-lg form-control-solid"
                          type="date"
                        />
                      </div>

                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaires</label>
                        <textarea
                          v-model="this.modals.planningEditPhase.item.description"
                          class="form-control form-control-solid form-text"
                        ></textarea>
                      </div>

                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Couleur</label>
                        <input
                          v-model="this.modals.planningEditPhase.item.colorBg"
                          class="form-control form-control-lg form-control-solid"
                          type="color"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" type="button" @click="this.modalPlanningEditPhaseCancel()">
                        Annuler
                      </button>
                      <button
                        :disabled="!this.modals.planningEditPhase.item.title"
                        class="btn btn-lg btn-primary ms-2"
                        type="button"
                        @click="this.modalPlanningEditPhaseValidate()"
                      >
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL DELETE PHASE -->
  <div :style="modals.planningDeletePhase.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalConfirmDeletePhaseCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Supprimer la phase</h3>
                      </div>

                      <div class="mb-10 w-100">
                        <p class="fs-6 me-2 fw-bolder text-dark form-label">
                          Êtes-vous sûr de vouloir supprimer cette phase ? Cela supprimera également les sous-phases
                          associées
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" type="button" @click="this.modalConfirmDeletePhaseCancel()">
                        Annuler
                      </button>
                      <button
                        class="btn btn-lg btn-danger ms-2"
                        type="button"
                        @click="this.modalConfirmDeletePhaseValidate()"
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL EDIT LOT -->
  <div :style="modals.editLotPlanning.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditLotPlanningCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-1">
                        <h3 class="fw-bolder text-dark display-6">Modifier un lot</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                        <label class="ms-1 fs-8 text-muted form-label"></label>
                        <input
                          v-model="modals.editLotPlanning.lot.startDate"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="2023-07-01"
                          type="date"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                        <label class="ms-1 fs-8 text-muted form-label"></label>
                        <input
                          v-model="modals.editLotPlanning.lot.endDate"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="2023-08-01"
                          type="date"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaire pour le CR</label>
                        <textarea
                          v-model="modals.editLotPlanning.lot.comment"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Pose du parquet dans les chambres lundi et mardi"
                        ></textarea>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" type="button" @click="modalEditLotPlanningCancel">
                        Annuler
                      </button>
                      <button class="btn btn-lg btn-primary ms-2" type="button" @click="modalEditLotPlanningValidate">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CURRENT_USER_QUERY,
  GET_PROJECT_BY_ID,
  PLANNING_CREATE_PHASE_MUTATION,
  PLANNING_DELETE_PHASE_MUTATION,
  PLANNING_FOR_PROJECT_QUERY,
  PLANNING_UPDATE_INFO_MUTATION,
  PLANNING_UPDATE_PHASE_MUTATION,
} from "@/graphql/graphql";
import kalmStore from "@/store";
import { ALL_CONTACTS_QUERY } from "@/views/contacts/data/contacts_graphql";
import PrintHeader from "@/views/project/tender/PrintHeader";
import domtoimage from "dom-to-image";

import CustomGGanttLabelColumnRow from "@/views/project/planning/CustomGGanttLabelColumnRow.vue";
import { GGanttRow } from "@infectoone/vue-ganttastic";
import { toast } from "vue3-toastify";

export default {
  name: "ProjectPlanningNew",
  components: {
    GGanttRow,
    PrintHeader,
    CustomGGanttLabelColumnRow,
  },
  apollo: {
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: kalmStore.getters.currentProjectFocus,
        };
      },
      skip() {
        return (
          !kalmStore.getters.currentProjectFocus ||
          kalmStore.getters.currentProjectFocus === "0" ||
          kalmStore.getters.currentProjectFocus === 0
        );
      },
    },
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    planning: {
      query: PLANNING_FOR_PROJECT_QUERY,
      variables() {
        return {
          projectId: this.currentProject.id,
        };
      },
    },
  },
  data() {
    return {
      todos: [],
      todoList: [],
      doneList: [],
      dones: [],
      project: {
        lots: [],
        phases: [],
      },
      planning: {
        phases: [],
        startDate: null,
        endDate: null,
      },
      localPlanning: {
        phases: {},
        startDate: null,
        endDate: null,
      },
      ganttConfig: {
        precision: "week",
        startDate: "",
        endDate: "",
      },
      ganttConfigChantier: {
        precision: "week",
        startDate: null,
        endDate: null,
      },
      currentUser: {},
      contacts: [],
      projects: [],
      lots: [],
      locations: [],
      modals: {
        importLot: {
          show: false,
          page: 0,
          projectId: "",
          lots: [],
        },
        importLocation: {
          show: false,
          page: 0,
          projectId: "",
          locations: [],
        },
        planningDeletePhase: {
          id: "",
          show: false,
        },
        planningEditPhase: {
          show: false,
          modalTitle: "",
          item: {
            id: "",
            hidden: false,
            title: "",
            description: "",
            startDate: null,
            endDate: null,
            colorBg: "",
          },
        },
        planningCreatePhase: {
          show: false,
          modalTitle: "Ajouter une phase",
          item: {
            parent: "",
            name: "",
            startDate: null,
            endDate: null,
            type: "",
            comment: "",
            colorBg: "",
          },
        },
        editLotPlanning: {
          show: false,
          lotId: "",
          lot: {
            comment: "",
            startDate: "",
            endDate: "",
          },
        },
      },
      modalLocation: {
        show: false,
        default: {
          name: "Nouvel Emplacement 01",
        },
        current: {
          name: "Nouvel Emplacement 01",
        },
      },
      modalConfirmDeleteLocation: {
        show: false,
        id: "",
      },
      modalEditLocation: {
        show: false,
        id: "",
        newLocation: {
          id: "",
          name: "",
        },
      },
      modalAssignContact: {
        show: false,
        default: {
          contactId: "",
          role: "CONTRACTOR",
        },
        current: {
          contactId: "",
          role: "CONTRACTOR",
        },
      },
      modalUnassignContact: {
        show: false,
        contactId: "",
      },
      contact_role_options: [
        {
          id: "MOA",
          name: "MOA - Maîtrise d'Ouvrage",
        },
        {
          id: "MOE",
          name: "MOE - Maîtrise d'Œuvre",
        },
        {
          id: "CONTRACTOR",
          name: "Entreprise",
        },
      ],
      editProject: {
        name: "",
        address: "",
        infoOpName: "",
        importantInfo: "",
        archived: "",
      },
      buttonProjectPropertiesLoading: false,
      localTimeline: [],
      localCurrentPhase: null,
      planningGlobalImageUrl: "",
      planningChantierImageUrl: "",
      printMode: "project",
      isDateChanged: false,
      buttonUserPropertiesLoading: false,
    };
  },
  mounted() {
    this.loadPlanning(true);
    this.updateDatesPlanningChantier();
  },
  methods: {
    updateDatesPlanningChantier() {
      this.ganttConfigChantier.startDate = null;
      this.ganttConfigChantier.endDate = null;
      this.currentProject.lots.forEach((lot) => {
        if (
          !this.ganttConfigChantier.startDate ||
          (lot?.startDate && lot.startDate <= this.ganttConfigChantier?.startDate)
        )
          this.ganttConfigChantier.startDate = lot.startDate;
        if (!this.ganttConfigChantier.endDate || (lot?.endDate && lot.endDate > this.ganttConfigChantier.endDate))
          this.ganttConfigChantier.endDate = lot.endDate;
      });
    },
    loadPlanning(updateDates = false) {
      this.localCurrentPhase = this.currentProject.currentPhase;

      this.localPlanning.startDate = this.planning.startDate;
      this.localPlanning.endDate = this.planning.endDate;

      // set the displayed current phase id to null if id is not part of available phases
      if (!(this.timeline || []).map((i) => i.name).includes(this.localCurrentPhase)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.localCurrentPhase = null;
      }

      if (updateDates) {
        this.flattenPhases.forEach((item) => {
          if (!this.ganttConfig.startDate || (item.startDate && item.startDate < this.ganttConfig.startDate))
            this.ganttConfig.startDate = item.startDate;
          if (!this.ganttConfig.endDate || (item.endDate && item.endDate > this.ganttConfig.endDate))
            this.ganttConfig.endDate = item.endDate;
        });
      }
    },
    modalConfirmDeletePhaseShow(id) {
      this.modals.planningDeletePhase.show = true;
      this.modals.planningDeletePhase.id = id;
    },
    modalConfirmDeletePhaseCancel() {
      this.modals.planningDeletePhase.show = false;
      this.modals.planningDeletePhase.id = "";
    },
    modalConfirmDeletePhaseValidate() {
      this.$apollo.mutate({
        mutation: PLANNING_DELETE_PHASE_MUTATION,
        variables: {
          id: this.modals.planningDeletePhase.id,
          projectId: this.currentProject.id,
        },
        refetchQueries: [
          {
            query: PLANNING_FOR_PROJECT_QUERY,
            variables: {
              projectId: this.currentProject.id,
            },
          },
        ],
      });
      this.modalConfirmDeletePhaseCancel();
    },
    modalPlanningEditPhaseShow(id, title) {
      this.modals.planningEditPhase.item = {
        id: this.localPlanning.phases[id].id,
        hidden: this.localPlanning.phases[id].hidden,
        title: this.localPlanning.phases[id].title,
        description: this.localPlanning.phases[id].description,
        startDate: this.localPlanning.phases[id].startDate,
        endDate: this.localPlanning.phases[id].endDate,
        colorBg: this.localPlanning.phases[id].colorBg,
      };

      /*this.modals.planningEditPhase.item = {
                title: "Phase 1",
                description: "Description de la phase 1",
                startDate: "2023-11-02",
                endDate: "2023-11-30",
                colorBg: "#ff0000",
            }*/
      this.modals.planningEditPhase.modalTitle = title;
      this.modals.planningEditPhase.show = true;
    },
    modalPlanningEditPhaseCancel() {
      this.modals.planningEditPhase.show = false;
    },
    modalPlanningEditPhaseValidate() {
      this.$apollo.mutate({
        mutation: PLANNING_UPDATE_PHASE_MUTATION,
        variables: {
          id: this.modals.planningEditPhase.item.id,
          parent: this.modals.planningEditPhase.item.parent,
          projectId: this.currentProject.id,
          hidden: this.modals.planningEditPhase.item.hidden,
          title: this.modals.planningEditPhase.item.title,
          description: this.modals.planningEditPhase.item.description,
          startDate: this.modals.planningEditPhase.item?.startDate,
          endDate: this.modals.planningEditPhase.item?.endDate,
          colorBg: this.modals.planningEditPhase.item.colorBg,
        },
        refetchQueries: [
          {
            query: PLANNING_FOR_PROJECT_QUERY,
            variables: {
              projectId: this.currentProject.id,
            },
          },
        ],
      });
      this.modalPlanningEditPhaseCancel();
    },
    async updatePlanningDate() {
      this.buttonUserPropertiesLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: PLANNING_UPDATE_INFO_MUTATION,
          variables: {
            projectId: this.currentProject.id,
            startDate: this.localPlanning.startDate,
            endDate: this.localPlanning.endDate,
          },
          refetchQueries: [
            {
              query: PLANNING_FOR_PROJECT_QUERY,
              variables: {
                projectId: this.currentProject.id,
              },
            },
          ],
        });
        this.isDateChanged = false;
        this.buttonUserPropertiesLoading = false;
        toast.success("Les dates du chantier ont été mises à jour avec succès");
      } catch (e) {
        this.buttonUserPropertiesLoading = false;
        toast.error("Une erreur est survenue lors de la mise à jour des dates du chantier");
      }
    },
    hidePhase({ id, newHiddenStatus }) {
      this.$apollo.mutate({
        mutation: PLANNING_UPDATE_PHASE_MUTATION,
        variables: {
          id: id,
          projectId: this.currentProject.id,
          hidden: newHiddenStatus,
        },
        refetchQueries: [
          {
            query: PLANNING_FOR_PROJECT_QUERY,
            variables: {
              projectId: this.currentProject.id,
            },
          },
        ],
      });
    },
    updatePhase(id) {
      this.modalPlanningEditPhaseShow(id, "Modifier une phase");
    },
    createSubPhase(id) {
      this.modalPlanningAddPhaseShow(id);
    },
    deletePhase(id) {
      this.modalConfirmDeletePhaseShow(id);
    },
    dragendBar({ bar }) {
      this.$apollo.mutate({
        mutation: PLANNING_UPDATE_PHASE_MUTATION,
        variables: {
          id: bar.id,
          projectId: this.currentProject.id,
          startDate: bar.startDate,
          endDate: bar.endDate,
        },
        refetchQueries: [
          {
            query: PLANNING_FOR_PROJECT_QUERY,
            variables: {
              projectId: this.currentProject.id,
            },
          },
        ],
      });
    },
    printGlobalPlanning() {
      this.printMode = "project";
      // makes a screenshot of gantt planning and adds it into a print-only div
      domtoimage
        .toPng(this.$refs.planningGlobal)
        .then((dataUrl) => (this.planningGlobalImageUrl = dataUrl))
        .then(() => {
          this.$nextTick(function () {
            window.print();
          });
        });
    },
    printPlanningChantier() {
      this.printMode = "chantier";

      this.$nextTick(function () {
        window.print();
      });
    },
    getContactById(contactId) {
      return this.contacts.find((c) => c.id === contactId);
    },
    getLotById(lotId) {
      return this.currentProject.lots.find((l) => l.id === lotId);
    },
    getLocationById(locationId) {
      return this.currentProject.locations.find((l) => l.id === locationId);
    },
    modalImportLotShow() {
      this.modals.importLot.show = true;
    },
    modalImportLotClose() {
      this.modals.importLot.show = false;
      this.modals.importLot.page = 0;
      this.modals.importLot.lots = [];
      this.modals.importLot.projectId = "";
    },
    modalImportLotFetch() {
      return kalmStore.getters.getProjectById(this.modals.importLot.projectId).lots;
    },
    modalImportLotValidate() {
      this.modals.importLot.lots.forEach((importedlotId) => {
        const importedLot = kalmStore.getters
          .getProjectById(this.modals.importLot.projectId)
          .lots.find((i) => i.id === importedlotId);

        kalmStore.dispatch("addLotToFocusProject", {
          apolloClient: this.$apollo,
          number: importedLot.number,
          name: importedLot.name,
        });
      });

      this.modalImportLotClose();
    },
    modalImportLocationShow() {
      this.modals.importLocation.show = true;
    },
    modalImportLocationClose() {
      this.modals.importLocation.show = false;
      this.modals.importLocation.page = 0;
      this.modals.importLocation.locations = [];
      this.modals.importLocation.projectId = "";
    },
    modalImportLocationFetch() {
      return kalmStore.getters.getProjectById(this.modals.importLocation.projectId).locations;
    },
    modalImportLocationValidate() {
      this.modals.importLocation.locations.forEach((importedlocationId) => {
        const importedLocation = kalmStore.getters
          .getProjectById(this.modals.importLocation.projectId)
          .locations.find((i) => i.id === importedlocationId);

        kalmStore.dispatch("addLocationToFocusProject", {
          apolloClient: this.$apollo,
          name: importedLocation.name,
        });
      });

      this.modalImportLocationClose();
    },
    modalLotShow() {
      this.modalLot.current = {
        id: this.modalLot.default.id,
        name: this.modalLot.default.name,
        number: this.modalLot.default.number,
        assignedCompanyId: this.modalLot.default.assignedCompanyId,
      };
      this.modalLot.show = true;
    },
    modalLotCancel() {
      this.modalLot.current = this.modalLot.default;
      this.modalLot.show = false;
    },
    modalLotValidate() {
      const payload = {
        number: this.modalLot.current.number,
        name: this.modalLot.current.name,
        assignedCompany: this.modalLot.current.assignedCompanyId,
        apolloClient: this.$apollo,
      };

      kalmStore.dispatch("addLotToFocusProject", payload);

      this.modalLot.show = false;
    },
    modalEditLotPlanningShow(lotId) {
      this.modals.editLotPlanning.lotId = lotId;

      const lot = this.currentProject.lots.find((l) => l.id === lotId);

      this.modals.editLotPlanning.lot.comment = lot.comment;
      this.modals.editLotPlanning.lot.startDate = lot.startDate;
      this.modals.editLotPlanning.lot.endDate = lot.endDate;

      this.modals.editLotPlanning.show = true;
    },
    modalEditLotPlanningCancel() {
      this.modals.editLotPlanning.show = false;

      this.modals.editLotPlanning.lotId = "";
      this.modals.editLotPlanning.lot.comment = "";
      this.modals.editLotPlanning.lot.startDate = "";
      this.modals.editLotPlanning.lot.endDate = "";
    },
    modalEditLotPlanningValidate() {
      kalmStore.dispatch("editLotPlanning", {
        lotId: this.modals.editLotPlanning.lotId,
        apolloClient: this.$apollo,
        startDate: this.modals.editLotPlanning.lot.startDate,
        endDate: this.modals.editLotPlanning.lot.endDate,
        comment: this.modals.editLotPlanning.lot.comment,
      });

      this.modalEditLotPlanningCancel();
    },
    modalConfirmDeleteLotShow(id) {
      this.modalConfirmDeleteLot.id = id;
      this.modalConfirmDeleteLot.show = true;
    },
    modalConfirmDeleteLotValidate() {
      const lotId = this.modalConfirmDeleteLot.id;
      if (lotId) {
        kalmStore.dispatch("deleteLot", { lotId, apolloClient: this.$apollo });
      }

      this.modalConfirmDeleteLot.id = "";
      this.modalConfirmDeleteLot.show = false;
    },
    modalConfirmDeleteLotCancel() {
      this.modalConfirmDeleteLot.show = false;
    },
    modalEditLotShow(id) {
      this.modalEditLot.id = id;
      const oldLot = JSON.parse(JSON.stringify(this.getLotById(id)));
      this.modalEditLot.newLot = {
        id: oldLot.id,
        name: oldLot.name,
        number: oldLot.number,
        assignedCompanyId: oldLot.assignedCompany?.id || null,
      };
      this.modalEditLot.show = true;
    },
    modalEditLotValidate() {
      const lotId = this.modalEditLot.id;

      const newLot = {
        id: lotId,
        number: this.modalEditLot.newLot.number || "",
        name: this.modalEditLot.newLot.name || "",
        assignedCompany: this.modalEditLot.newLot.assignedCompanyId || null,
      };

      kalmStore.dispatch("editLot", {
        lotId,
        newLot,
        apolloClient: this.$apollo,
      });

      this.modalEditLot.id = "";
      this.modalEditLot.show = false;
    },
    modalEditLotCancel() {
      this.modalEditLot.show = false;
      this.modalEditLot.id = "";
      this.modalEditLot.newLot = "";
    },
    modalConfirmDeleteLocationShow(id) {
      this.modalConfirmDeleteLocation.id = id;
      this.modalConfirmDeleteLocation.show = true;
    },
    modalConfirmDeleteLocationValidate() {
      const locationId = this.modalConfirmDeleteLocation.id;
      if (locationId) {
        kalmStore.dispatch("deleteLocation", {
          locationId,
          apolloClient: this.$apollo,
        });
      }

      this.modalConfirmDeleteLocation.id = "";
      this.modalConfirmDeleteLocation.show = false;
    },
    modalConfirmDeleteLocationCancel() {
      this.modalConfirmDeleteLocation.show = false;
    },
    modalLocationShow() {
      this.modalLocation.current.name = this.modalLocation.default.name;
      this.modalLocation.show = true;
    },
    modalLocationCancel() {
      this.modalLocation.current = this.modalLocation.default;
      this.modalLocation.show = false;
    },
    modalLocationValidate() {
      const payload = {
        number: this.modalLocation.current.number,
        name: this.modalLocation.current.name,
        apolloClient: this.$apollo,
      };

      kalmStore.dispatch("addLocationToFocusProject", payload);

      this.modalLocation.show = false;
    },
    modalEditLocationShow(id) {
      this.modalEditLocation.id = id;
      this.modalEditLocation.newLocation = JSON.parse(JSON.stringify(this.getLocationById(id)));
      this.modalEditLocation.show = true;
    },
    modalEditLocationValidate() {
      const locationId = this.modalEditLocation.id;

      const newLocation = {
        id: locationId,
        name: this.modalEditLocation.newLocation.name || "",
      };

      if (locationId) {
        kalmStore.dispatch("editLocation", {
          locationId,
          newLocation,
          apolloClient: this.$apollo,
        });
      }

      this.modalEditLocation.id = "";
      this.modalEditLocation.show = false;
    },
    modalEditLocationCancel() {
      this.modalEditLocation.show = false;
      this.modalEditLocation.id = "";
      this.modalEditLocation.newLocation = "";
    },
    modalAssignContactShow() {
      this.modalAssignContact.current.contactId = this.modalAssignContact.default.contactId;
      this.modalAssignContact.current.role = this.modalAssignContact.default.role;
      this.modalAssignContact.show = true;
    },
    modalAssignContactValidate() {
      this.modalAssignContact.show = false;

      kalmStore.dispatch("assignContact", {
        apolloClient: this.$apollo,
        contactId: this.modalAssignContact.current.contactId,
        role: this.modalAssignContact.current.role,
      });
    },
    modalAssignContactCancel() {
      this.modalAssignContact.show = false;
    },
    validateLotNumberInput(input) {
      let ret = true;
      if (input) {
        ret = true;
      }
      return ret;
    },
    modalUnassignContactShow(contactId) {
      this.modalUnassignContact.show = true;
      this.modalUnassignContact.contactId = contactId;
    },
    modalUnassignContactClose() {
      this.modalUnassignContact.show = false;
      this.modalUnassignContact.contactId = "";
    },
    modalUnassignContactValidate() {
      const contactId = this.modalUnassignContact.contactId;
      const apolloClient = this.$apollo;
      if (contactId) kalmStore.dispatch("unassignContact", { contactId, apolloClient });
      this.modalUnassignContactClose();
    },
    modalPlanningAddPhaseShow(existingPhaseId) {
      let defaultColor = this.currentUser.org?.persoColorMain;
      if (existingPhaseId) {
        let color = this.localPlanning.phases[existingPhaseId]?.colorBg || this.currentUser.org?.persoColorMain;
        const amount = 40;
        // lighten color from hex -- https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
        defaultColor =
          "#" +
          color
            .replace(/^#/, "")
            .replace(/../g, (color) =>
              ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
            );
      }
      this.modals.planningCreatePhase.item = {
        parent: existingPhaseId,
        name: "",
        startDate: null,
        endDate: null,
        type: "",
        comment: "",
        colorBg: defaultColor || "",
      };
      this.modals.planningCreatePhase.modalTitle = existingPhaseId ? "Ajouter une sous-phase" : "Ajouter une phase";
      this.modals.planningCreatePhase.show = true;
    },
    modalPlanningAddPhaseCancel() {
      this.modals.planningCreatePhase.show = false;
    },
    modalPlanningAddPhaseValidate() {
      const currentProjectId = this.currentProject.id;
      this.$apollo.mutate({
        mutation: PLANNING_CREATE_PHASE_MUTATION,
        variables: {
          parent: this.modals.planningCreatePhase.item.parent,
          projectId: this.currentProject.id,
          title: this.modals.planningCreatePhase.item.title,
          startDate: this.modals.planningCreatePhase.item?.startDate,
          endDate: this.modals.planningCreatePhase.item?.endDate,
          description: this.modals.planningCreatePhase.item.comment,
          colorBg: this.modals.planningCreatePhase.item.colorBg,
        },
        refetchQueries: [
          {
            query: PLANNING_FOR_PROJECT_QUERY,
            variables: {
              projectId: currentProjectId,
            },
          },
        ],
        // TODO add optimistic response
        // TODO add update function with 2 cases (is subPhase, is parent phase)
        /*update(cache, { data: { createPhase } }) {
                    let cachedPlanning = cache.readQuery({
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: currentProjectId,
                        }
                    })

                    const phase = createPhase.phase

                    cachedPlanning = {
                        planning: {
                            ...cachedPlanning.planning,
                            phases: [
                                ...cachedPlanning.planning.phases,
                                phase,
                            ]
                        }
                    }

                    // Write our data back to the cache.
                    cache.writeQuery({
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: currentProjectId,
                        },
                        data: cachedPlanning
                    })

                    console.log(cache.readQuery({
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: currentProjectId,
                        }
                    }).planning)
                },*/
      });
      this.modals.planningCreatePhase.show = false;
    },
    calculateEndDateForBar(startDate, endDate) {
      function addDay(date) {
        const result = new Date(date);
        result.setDate(result.getDate() + 1);
        return result;
      }

      if (endDate) {
        if (endDate === startDate) {
          return addDay(startDate);
        } else {
          return endDate;
        }
      } else {
        if (startDate) {
          return addDay(startDate);
        } else {
          return null;
        }
      }
    },
    transformGPhaseToGanttBar(phase) {
      return {
        id: phase.id,
        hidden: phase.hidden,
        title: phase.title,
        startDate: phase.startDate,
        description: phase.description,
        colorBg: phase.colorBg,
        endDate: this.calculateEndDateForBar(phase.startDate, phase.endDate),
        ganttBarConfig: {
          id: phase.id,
          indentationLevel: phase.indentationLevel,
          mobile: true,
          hasHandles: true,
          style: {
            background: phase?.colorBg || this.currentUser.org?.persoColorMain,
            borderRadius: "5px",
          },
        },
      };
    },
    flattenPhasesRecursively(phases) {
      let result = [];

      for (let phase of phases) {
        result.push(JSON.parse(JSON.stringify(this.transformGPhaseToGanttBar(phase))));

        if (phase.subPhases && phase.subPhases.length > 0) {
          result = result.concat(this.flattenPhasesRecursively(phase.subPhases));
        }
      }

      return result;
    },
  },
  computed: {
    mainColor() {
      return this.currentUser.org?.persoColorMain || "#195D5B";
    },
    flattenPhases: {
      get() {
        const phases = this.flattenPhasesRecursively(this.planning?.phases || []);
        phases.forEach((phase) => {
          this.localPlanning.phases[phase.id] = phase;
        });
        return phases;
      },
    },
    validatePhasesSchema() {
      return true;
    },
    modalActive() {
      return (
        kalmStore.state.display.profileToggled ||
        this.modals.planningCreatePhase.show ||
        this.modals.planningEditPhase.show ||
        this.modals.editLotPlanning.show
      );
    },
    currentProject() {
      return this.project;
    },
    isArchived() {
      return this.currentProject.archived;
    },
    getProjects() {
      return kalmStore.state.description.projects;
    },
    projectProperties: {
      get() {
        return {
          name: this.editProject.name || this.currentProject.name,
          address: this.editProject.address || this.currentProject.address,
          infoOpName: this.editProject.infoOpName || this.currentProject.infoOpName,
          importantInfo: this.editProject.importantInfo || this.currentProject.importantInfo,
          archived: this.editProject.archived,
        };
      },
      set(newValue) {
        this.editProject = newValue;
      },
    },
    timeline: {
      get() {
        if (this.currentProject.phases) {
          if (!this.localTimeline) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.localTimeline = this.currentProject.phases;
          }
          return this.localTimeline;
        } else {
          return [];
        }
      },
      set(newValue) {
        this.localTimeline = newValue;
      },
    },
    currentPhase: {
      get() {
        if (!this.localCurrentPhase) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.localCurrentPhase = this.currentProject.currentPhase;
          if (!(this.timeline || []).map((i) => i.name).includes(this.localCurrentPhase)) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.localCurrentPhase = null;
          }
        }
        return this.localCurrentPhase;
      },
      set(newValue) {
        this.localCurrentPhase = newValue;
        this.updateProjectPlanning();
      },
    },
    canSafelyDeleteLot() {
      return kalmStore.getters.canSafelyDeleteLot(
        kalmStore.state.display.currentProjectFocus,
        this.modalConfirmDeleteLot.id
      );
    },
    canSafelyDeleteLocation() {
      return kalmStore.getters.canSafelyDeleteLocation(
        kalmStore.state.display.currentProjectFocus,
        this.modalConfirmDeleteLocation.id
      );
    },
  },
  watch: {
    currentProject() {
      this.loadPlanning(true);
      this.updateDatesPlanningChantier();
    },
    planning: {
      deep: true,
      handler(oldValue, newValue) {
        if (oldValue?.id !== newValue?.id) {
          this.loadPlanning(true);
        }
      },
    },
  },
};
</script>

<style>
.g-label-column-row {
  overflow: visible !important;
}

.g-gantt-tooltip {
  display: none !important;
}

.g-gantt-row-label {
  display: none;
  /*background-color: white !important;
    box-shadow: none;
    border-bottom-right-radius: 0;
    height: 100%;
    width: 300px;*/
}

.g-gantt-row-bars-container {
  border-bottom: hidden !important;
  border-top: hidden !important;
}

.g-timeunits-container,
.g-upper-timeunit,
.g-timeunit {
  background-color: white !important;
}

.g-timeaxis {
  box-shadow: none;
}
</style>

<style scoped>
.planning-card-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen {
  .print-planning {
    display: none;
  }
}

@media print {
  .print-hidden {
    display: none !important;
  }

  .print-planning {
    display: block !important;
  }

  .planning-card-shadow {
    box-shadow: none;
  }
}
</style>
