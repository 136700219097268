<template>
    <div class="modal" tabindex="-1" style="display: block; z-index: 100000;">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div
                  ref="contactEditModalForm"
                  class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                >
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">{{headerLabel}}</h3>
                      </div>

                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom du tag</label>
                        <input
                          v-model="tag.name"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Projet Témoin - Appartement"
                        />
                      </div>
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Couleur du tag</label>
                        <input
                          v-model="tag.color"
                          type="color"
                          class="form-control form-control-lg form-control-solid"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" @click="$emit('close')">Annuler</button>
                      <button
                        class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                        :disabled="!tag.name"
                        @click="$emit('validate')"
                      >
                        {{buttonLabel}}
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "CreateTagModal",
    props: {
        modelValue: {
            type: Object,
            Required: true
        },
        buttonLabel: {
            type: String,
            default: 'Créer un tag'
        },
        headerLabel: {
            type: String,
            default: 'Créer un nouveau tag'
        }
    },
    computed: {
        tag: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>