<template>
  <div>
    <label class="form-label" for="content">Commentaire</label>
    <div v-if="comment" class="mb-2">
      <input v-model="updatedAt" type="datetime-local" class="form-control" id="content" />
    </div>
    <textarea v-model="content" type="text" rows="3" class="form-control" id="content" />
    <div class="float-end mt-2 pt-1">
      <button v-if="!isSaving" @click="save" :disabled="!canSave()" type="button" class="btn btn-primary btn-sm">
        Commenter
      </button>

      <LoadingButton v-if="isSaving"></LoadingButton>
    </div>
  </div>
</template>

<script>
import LoadingButton from "@/views/_core/components/LoadingButton.vue";
import { CREATE_COMMENT, GET_COMMENTS, UPDATE_COMMENT } from "../../data/comments_graphql";
import dayjs from "dayjs";

export default {
  name: "CommentForm",
  props: {
    comment: Object,
    taskId: {
      type: String,
      required: true,
    },
  },
  data() {
    const now = new Date().toISOString().slice(0, 16);
    return {
      updatedAt: now,
      content: "",
      isSaving: false,
    };
  },
  created() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      console.log(this.comment);
      if (this.comment) {
        this.updatedAt = dayjs(this.comment.updatedAt).format("YYYY-MM-DDTHH:mm");
        this.content = this.comment.content;
      }
    },
    canSave() {
      return this.content?.length;
    },
    async save() {
      this.isSaving = true;
      if (this.comment) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_COMMENT,
            variables: {
              id: this.comment.id,
              content: this.content,
              updatedAt: this.updatedAt,
            },
            refetchQueries: [
              {
                query: GET_COMMENTS,
                variables: {
                  taskId: this.taskId,
                },
              },
            ],
          });
        } catch (error) {
          console.error("Update comment error:", error);
        }
      } else {
        try {
          await this.$apollo.mutate({
            mutation: CREATE_COMMENT,
            variables: {
              content: this.content,
              taskId: this.taskId,
            },
            refetchQueries: [
              {
                query: GET_COMMENTS,
                variables: {
                  taskId: this.taskId,
                },
              },
            ],
          });
          this.content = "";
        } catch (error) {
          console.error("Create comment error:", error);
        }
      }
      this.isSaving = false;
      this.$emit("save");
    },
  },
  components: { LoadingButton },
};
</script>
