<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-center">
        <div class="spinner spinner-primary spinner-center"></div>
      </div>
    </div>
    <!-- NOTHING EMPTY STATE - CARD -->
    <div v-if="!getItems().length && emptyStateType === 'card' && !loading" class="col-12 col-md-7">
      <div class="row g-5 h-100">
        <div class="col-12 g-5 h-100">
          <div class="card h-auto">
            <div class="card-body">
              <div class="card-title fw-bolder">🚀 Gagnez en productivité grâce aux Todos !</div>
              <div class="card-text">
                <p>Créez dès maintenant vos tâches pour organiser efficacement votre travail.</p>
              </div>
              <div class="mt-5 d-flex justify-content-end">
                <button class="btn btn-sm btn-primary" @click="this.$router.push({ name: 'todos' })">
                  C'est parti !
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TODOPAGE EMPTY STATE - IMAGE -->
    <div v-if="!getItems().length && emptyStateType === 'image' && !loading" class="text-center w-50 mx-auto">
      <TodoEmptyState
        buttonMessage="Ajouter une Todo"
        image="/assets/media/svg/empty-states/empty-todo.svg"
        message="Aucune todo"
        @add="addTodo"
      />
    </div>
    <div class="mt-3">
      <draggable
        :component-data="{
          tag: 'div',
          type: 'transition-group',
          name: !dragging ? 'flip-list' : null,
        }"
        :disabled="!enabled || assignedToCurrentUser || readOnly"
        :list="getItems()"
        handle=".handle"
        item-key="id"
        v-bind="dragOptions"
        @end="
          dragging = false;
          onDropped();
        "
        @start="dragging = true"
      >
        <template #item="{ element }">
          <div :class="{ 'not-draggable': !enabled || assignedToCurrentUser }">
            <TodoList
              :assignedToCurrentUser="assignedToCurrentUser"
              :canevaId="canevaId"
              :filterAssignedTo="filterAssignedTo"
              :filterProject="filterProject"
              :is-draggable="!assignedToCurrentUser"
              :loading="this.$apollo.queries?.todosByProject?.loading"
              :projectId="projectId"
              :readOnly="this.readOnly"
              :show-all-buttons="this.showAllButtons"
              :todo="element"
            />
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { BULK_UPDATE_TODO_ORDER } from "../../../data/todos_graphql";
import TodoEmptyState from "./TodoEmptyState.vue";
import TodoList from "./TodoList.vue";
export default {
  name: "TodosList",
  components: {
    TodoList,
    draggable,
    TodoEmptyState,
  },
  props: {
    // Used to show own user todos on the dashboard
    assignedToCurrentUser: {
      type: Boolean,
      default: false,
    },
    initialItems: {
      type: Array,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    filterAssignedTo: {
      type: Array,
    },
    filterProject: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyStateType: {
      type: String, // 'card' or 'image'
      default: "image",
    },
    canevaId: {
      type: String,
      required: false,
    },
    projectId: {
      type: String,
      required: false,
    },
    showAllButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      dragging: false,
      enabled: true,
      localTodos: [],
      lastStringifiedTodos: "",
    };
  },
  methods: {
    addTodo() {
      this.$emit("add-todo");
    },
    onDropped() {
      this.$apollo.mutate({
        mutation: BULK_UPDATE_TODO_ORDER,
        variables: {
          todos: [
            ...this.localTodos.map((todo, index) =>
              JSON.stringify({
                id: todo.id,
                order: index,
              })
            ),
          ],
        },
      });
    },
    getItems() {
      if (!this.initialItems) {
        return [];
      }
      // Stringified todos without the order and updatedAt fields
      const stringifiedTodos = JSON.stringify(
        this.initialItems.map((todo) => {
          // eslint-disable-next-line no-unused-vars
          const { order, updatedAt, ...rest } = todo;
          return rest;
        })
      );

      if (this.lastStringifiedTodos !== stringifiedTodos) {
        this.localTodos = [...this.initialItems];
        this.lastStringifiedTodos = stringifiedTodos;
      }
      return this.localTodos;
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "todos",
        ghostClass: "ghost",
      };
    },
  },
};
</script>
