<template>
  <div>
    <label class="col-form-label">{{ title }}</label>
    <select v-model="selectedLotIdInternal" class="form-select form-select-solid">
      <option :value="null">Tâches générales</option>
      <option v-for="lot in lots" :key="lot.id" :value="lot.id">
        {{ lot.fullName || lot.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { ALL_LOTS_BY_PROJECT_QUERY } from "../../data/lots_graphql";

export default {
  name: "LotSelect",
  props: {
    title: String,
    projectId: String,
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      lots: [],
    };
  },
  computed: {
    selectedLotIdInternal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  apollo: {
    lots: {
      query: ALL_LOTS_BY_PROJECT_QUERY,
      variables() {
        return {
          projectId: this.projectId,
        };
      },
      skip() {
        return !this.projectId;
      },
    },
  },
};
</script>
