<!--suppress ALL -->
<template>
  <!-- MAIN -->
  <div :class="modalActive ? 'blur-content' : ''">
    <PrintHeader pageTitle="Planning" sectionTitle="Phases du projet"></PrintHeader>
    <div class="row g-5 print-hidden">
      <div class="col-12">
        <!-- simple alert -->
        <div
          class="mt-3 alert print-hidden bg-light-primary border border-dashed border-primary d-flex flex-column flex-sm-row"
        >
          <div class="">
            <span class="bi bi-lightbulb text-primary me-4 fs-1 align-middle"></span>
          </div>
          <div class="d-flex flex-column pe-0 pe-sm-10">
            <h5 class="mb-1">Refonte en cours</h5>
            <p class="mb-1">
              Nous sommes en train d'améliorer l'ergonomie de la page Cockpit pour faire suite à vos retours. Vous
              pourrez bientôt personnaliser son contenu et l'ordre dans lequel il apparaît. Faites-nous signe si vous
              souhaitez essayer cette fonctionnalité en bêta.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div>
            <img
              v-if="this.currentProject?.coverPhoto || true"
              :src="this.currentProject?.coverPhoto || 'https://app.kalm.ai/static/img/kalm-bg.png'"
              alt="Image de couverture du projet"
              class="card-img-top mh-150px object-fit-cover"
            />
            <button
              class="btn btn-secondary btn-icon btn-sm position-absolute top-0 end-0 me-3 mt-3"
              @click="this.modalEditCoverPhotoShow()"
            >
              <i class="bi bi-camera-fill"></i>
            </button>
          </div>
          <div class="card-body pt-5 pb-3">
            <div class="d-flex justify-content-start align-items-baseline">
              <h2 class="mb-4">{{ this.currentProject?.name }}</h2>
              <!--              <button class="ms-2 btn btn-sm btn-icon btn-light-primary btn-active-secondary"><span class="bi bi-pencil"></span></button>-->
            </div>
            <p v-if="this.currentProject?.infoOpName" class="text-muted text-break align-baseline mb-2">
              <span class="bi me-2 text-muted align-baseline bi-house-door"></span>{{ this.currentProject?.infoOpName }}
            </p>
            <p v-if="this.currentProject?.address" class="text-muted text-break align-baseline mb-2">
              <span class="bi me-2 text-muted align-baseline bi-pin"></span>{{ this.currentProject?.address }}
            </p>
          </div>
          <div class="card-footer d-flex pt-0 pb-4 border-0">
            <span
              class="bg-light text-gray-800 border text-center rounded py-1 px-2 h-30px cursor-pointer"
              @click="$router.push({ name: 'project.planning', params: { id: this.currentProject?.id } })"
              >{{ this.currentProject?.currentPhase || "En cours" }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 g-5 print-hidden">
      <!-- Différents raccourcis sur une ligne pour accéder aux outils : Descriptif, Documents, Consultation, Notes, Suivi de chantier, Todos -->
      <div class="d-flex justify-content-between overflow-x-scroll">
        <button
          class="tool-button"
          @click="$router.push({ name: 'project.description', params: { id: this.currentProject?.id } })"
        >
          <i class="bi bi-file-text"></i>
          <span>Descriptif</span>
        </button>
        <button
          class="tool-button"
          @click="$router.push({ name: 'project.documents', params: { id: this.currentProject?.id } })"
        >
          <i class="bi bi-folder"></i>
          <span>Documents</span>
        </button>
        <button
          class="tool-button"
          @click="$router.push({ name: 'project.planning_new', params: { id: this.currentProject?.id } })"
        >
          <i class="bi bi-calendar3"></i>
          <span>Planning</span>
        </button>
        <button
          class="tool-button"
          @click="$router.push({ name: 'project.tender', params: { id: this.currentProject?.id } })"
        >
          <i class="bi bi-journals"></i>
          <span>Consultation</span>
        </button>
        <button
          class="tool-button"
          @click="$router.push({ name: 'project.notes', params: { id: this.currentProject?.id } })"
        >
          <i class="bi bi-journal-bookmark"></i>
          <span>Notes</span>
        </button>
        <button
          class="tool-button"
          @click="$router.push({ name: 'project.tasks', params: { id: this.currentProject?.id } })"
        >
          <i class="bi bi-list-task"></i>
          <span>Suivi de chantier</span>
        </button>
        <button
          class="tool-button"
          @click="$router.push({ name: 'project.todos', params: { id: this.currentProject?.id } })"
        >
          <i class="bi bi-check2-square"></i>
          <span>Todos</span>
        </button>
      </div>
    </div>
    <div class="row g-5 print-hidden">
      <div class="col-12 mb-5 pt-5">
        <!--begin::Table Widget 2-->
        <div class="card card-stretch h-100 flex-grow-1">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">
                <i class="bi fs-3 bi-person me-2"></i>
                Intervenants
              </span>
            </h3>
            <div class="card-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-light">
                <li class="nav-item align-middle">
                  <button
                    id="AssignContactButton"
                    :disabled="this.isArchived"
                    class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                    @click="modalAssignContactShow()"
                  >
                    + Inviter
                  </button>
                </li>
                <li class="nav-item align-middle">
                  <router-link
                    :to="{ name: 'contacts' }"
                    class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                  >
                    + Créer
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0 mt-n3">
            <div id="ProjectHomePageContactTable" class="tab-content mt-4">
              <!--begin::Tap pane-->
              <div
                id="kt_tab_pane_2_1"
                aria-labelledby="kt_tab_pane_2_1"
                class="tab-pane fade show active"
                role="tabpanel"
              >
                <!--begin::Table-->
                <div class="table-responsive">
                  <table class="table table-borderless align-middle">
                    <thead>
                      <tr>
                        <th class="p-0 w-80px"></th>
                        <th class="p-0 min-w-150px"></th>
                        <th class="p-0 min-w-120px"></th>
                        <th class="p-0 min-w-70px"></th>
                        <th class="p-0 min-w-70px"></th>
                      </tr>
                    </thead>
                    <draggable
                      v-model="this.projectContacts"
                      :component-data="{
                        tag: 'div',
                        type: 'transition-group',
                        name: !drag ? 'flip-list' : null,
                      }"
                      handle=".lot-drag-handle"
                      item-key="contact.id"
                      tag="tbody"
                      v-bind="dragOptions"
                      @end="console.log('end')"
                      @start="console.log('start')"
                    >
                      <template #item="{ element }">
                        <tr class="h-100">
                          <td class="px-0 py-3 rounded-start d-flex">
                            <div class="lot-drag-handle" style="cursor: grab">
                              <svg
                                class="align-self-center"
                                style="width: 15px; height: 100%; display: block"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                                  fill-opacity="0.2"
                                />
                              </svg>
                            </div>
                            <AvatarLogo :url="element.contact.logoUrl"></AvatarLogo>
                          </td>
                          <td class="px-0">
                            <a class="text-gray-800 fw-bold fs-6">{{ element.contact?.company }}</a>
                            <span class="text-muted fw-bold d-block mt-1">{{ element.contact?.name }}</span>
                          </td>
                          <td></td>
                          <td class="text-center">
                            <span class="text-gray-800 fw-bolder d-block fs-6">{{
                              element.roleDisplayName ||
                              contact_role_options.find((o) => o.id === element.role)?.name ||
                              "Entreprise"
                            }}</span>
                          </td>
                          <td class="text-center">
                            <span class="d-block text-muted">{{ element.contact?.email }}</span>
                            <span class="d-block text-muted">{{ element.contact?.phone }}</span>
                          </td>
                          <td class="text-end pe-0 rounded-end">
                            <button
                              v-tooltip:bottom="'Modifier le rôle sur le projet'"
                              class="me-1 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                              @click="modalEditContactRoleShow(element)"
                            >
                              <span class="bi bi-pencil"></span>
                            </button>
                            <button
                              v-tooltip:bottom="'Modifier le contact'"
                              class="me-1 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                              @click="modalEditContactShow(element.contact.id)"
                            >
                              <span class="bi bi-person-vcard"></span>
                            </button>
                            <button
                              v-tooltip:bottom="'Retirer l\'intervenant'"
                              :disabled="this.isArchived"
                              class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                              @click="modalUnassignContactShow(element.contact)"
                            >
                              <span class="bi bi-x"></span>
                            </button>
                          </td>
                        </tr>
                      </template>
                    </draggable>
                  </table>
                </div>
                <!--end::Table-->
              </div>
              <!--end::Tap pane-->
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Table Widget 2-->
      </div>
    </div>
    <div class="row g-5 print-hidden">
      <div class="col-12 mb-5">
        <div id="ProjectInformation" class="card">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-gear"></i>Généralités</span>
            </h3>
          </div>
          <div class="card-body w-100 row pt-2">
            <div class="col-12">
              <div class="form-group mb-3">
                <label class="text-muted">Nom du projet</label>
                <input
                  v-model="this.projectProperties.name"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                />
              </div>
              <div class="form-group mb-3">
                <label class="text-muted">Nom de l'opération</label>
                <input
                  v-model="this.projectProperties.infoOpName"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                />
              </div>
              <div class="form-group mb-3">
                <label class="text-muted">Adresse</label>
                <input
                  v-model="this.projectProperties.address"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                />
              </div>
              <div class="form-group mb-3">
                <label class="text-muted">Tags</label>
                <TagMultiSelect
                  v-model="this.editProject.tags"
                  for="PROJECT"
                  @newTag="modalCreateTagShow"
                ></TagMultiSelect>
              </div>
            </div>
            <div class="flex-column text-end">
              <button
                :disabled="this.isArchived"
                class="btn btn-primary btn-active-accent"
                @click="this.updateProjectProperties()"
              >
                Enregistrer
                <span
                  v-if="this.buttonProjectPropertiesLoading"
                  class="ms-1 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Chargement...</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row g-5 print-hidden">
      <div class="col-xl-6">
        <!--begin::Table Widget 2-->
        <div class="card card-stretch">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-grid"></i>Lots</span>
            </h3>
            <div class="card-toolbar">
              <div></div>
              <ul class="nav nav-pills nav-pills-sm nav-light">
                <li class="align-middle" title="Ouvrir la fenêtre de numérotation auto des lots">
                  <button
                    :disabled="this.isArchived"
                    class="align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                    @click="modalAutoSortShow"
                  >
                    <span class="bi bi-stars"></span>
                  </button>
                </li>
                <li class="align-middle">
                  <button
                    :disabled="this.isArchived"
                    class="align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                    title="Importer des lots depuis un autre projet"
                    @click="modalImportLotShow"
                  >
                    + Importer
                  </button>
                </li>
                <li class="align-middle">
                  <button
                    :disabled="this.isArchived"
                    class="align-middle btn btn-active-light btn-color-muted py-2 ms-2 px-4 fw-bolder"
                    title="Ajouter un lot"
                    @click="modalLotShow"
                  >
                    + Ajouter
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 mt-n3">
            <div id="myTabTables2" class="tab-content mt-4">
              <div class="table-responsive">
                <table id="ProjectHomePageLotTable" class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="p-0 min-w-150px"></th>
                      <th class="p-0 min-w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="lot in this.currentProject?.lots" :key="lot.id">
                      <td :class="'ps-' + (lot.number.match(/\./g) || []).length * 5" class="px-0">
                        <a
                          class="text-gray-800 fw-bolder text-hover-primary cursor-pointer fs-6"
                          @click="
                            this.$router.push({
                              name: 'project.description',
                              params: { id: this.currentProject?.id },
                              hash: '#lot-' + lot.id,
                            })
                          "
                          >{{ lot.fullName }}</a
                        >
                        <span class="text-muted fw-bold d-block mt-1">{{
                          lot.assignedCompany?.id
                            ? lot.assignedCompany.company || lot.assignedCompany.name
                            : "Non attribué"
                        }}</span>
                      </td>
                      <td class="text-end w-100px pe-0">
                        <button
                          v-tooltip:bottom="'Modifier le lot'"
                          :disabled="this.isArchived"
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                          @click="modalEditLotShow(lot.id)"
                        >
                          <span class="bi bi-pencil-fill"></span>
                        </button>
                        <button
                          v-tooltip:bottom="'Supprimer le lot'"
                          :disabled="this.isArchived"
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-danger btn-sm btn-active-white"
                          @click="modalConfirmDeleteLotShow(lot.id)"
                        >
                          <span class="bi bi-trash-fill"></span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Table Widget 2-->
      </div>
      <div class="col-xl-6">
        <!--begin::Table Widget 2-->
        <div class="card card-stretch">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-pin"></i>Emplacements</span>
            </h3>
            <div class="card-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-light">
                <!--                                <li class="nav-item align-middle">
                                    <button :disabled="this.isArchived" @click="this.modalImportLocationShow()"
                                            class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder">
                                        + Importer
                                    </button>
                                </li>-->
                <li class="nav-item align-middle">
                  <button
                    id="AddLocationButton"
                    :disabled="this.isArchived"
                    class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                    @click="this.modalAddLocationShow()"
                  >
                    + Ajouter
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 mt-n3">
            <div id="myTabTables2" class="tab-content mt-4">
              <div class="table-responsive">
                <table id="ProjectHomePageLocationTable" class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="p-0 min-w-150px"></th>
                      <th class="p-0 min-w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="location in this.currentProject?.locations" :key="location.id">
                      <td class="px-0">
                        <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ location.name }}</a>
                      </td>
                      <td class="text-end w-100px pe-0">
                        <button
                          v-tooltip:bottom="'Modifier l\'emplacement'"
                          :disabled="this.isArchived"
                          class="ms-1 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                          @click="modalEditLocationShow(location.id)"
                        >
                          <span class="bi bi-pencil-fill"></span>
                        </button>
                        <button
                          v-tooltip:bottom="'Supprimer l\'intervenant'"
                          :disabled="this.isArchived"
                          class="ms-1 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-danger btn-sm btn-active-white"
                          @click="modalConfirmDeleteLocationShow(location.id)"
                        >
                          <span class="bi bi-trash-fill"></span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Table Widget 2-->
      </div>
    </div>
  </div>
  <!-- MODAL ADD LOT -->
  <AddLotModal
    v-if="this.modals.addLot.show"
    v-model="this.modals.addLot.default"
    :contacts="this.project.contacts"
    :lots="this.project?.lots"
    :projectId="this.$route.params.id"
    @close="modalLotCancel"
    @validate="modalLotValidate"
  ></AddLotModal>
  <KalmModal
    v-if="this.modals.editContactRole.show"
    :modalTitle="'Modifier le rôle du contact'"
    @close="this.modalEditContactRoleClose()"
  >
    <template v-slot:body>
      <div class="form-group mb-3">
        <!--begin::Form Group-->
        <div class="w-100">
          <div class="mb-2">
            <input
              id="editContactRoleStarred"
              v-model="this.modals.editContactRole.starred"
              class="form-check-input"
              type="checkbox"
            />
            <label id="editContactRoleStarred" class="form-check-label ms-2"> Intervenant mis en avant </label>
          </div>
          <div>
            <label class="form-label">Rôle</label>
            <select
              v-model="this.modals.editContactRole.role"
              class="form-select form-select-solid"
              @change="this.onChangeRole($event)"
            >
              <option
                v-for="contact_role_option in contact_role_options"
                :key="contact_role_option.id"
                :value="contact_role_option.id"
              >
                {{ contact_role_option.name }}
              </option>
            </select>
          </div>
          <div>
            <label class="form-label">Rôle affiché</label>
            <input
              v-model="this.modals.editContactRole.roleDisplayName"
              class="form-control form-control-solid"
              type="text"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button class="btn btn-secondary" @click="this.modalEditContactRoleClose()">Annuler</button>
      <button class="btn btn-primary" @click="this.modalEditContactRoleValidate()">Modifier</button>
    </template>
  </KalmModal>
  <!-- MODAL EDIT LOT -->
  <EditLotModal
    v-if="this.modals.editLot.show"
    v-model="this.modals.editLot.newLot"
    :contacts="this.project.contacts"
    :projectId="this.$route.params.id"
    @close="modalEditLotCancel"
    @validate="modalEditLotValidate"
  ></EditLotModal>
  <!-- CONFIRM DELETE LOT -->
  <DeleteLotModal
    v-if="this.modals.confirmDeleteLot.show"
    :lotId="this.modals.confirmDeleteLot.id"
    :project="this.project"
    @close="modalConfirmDeleteLotCancel"
    @validate="modalConfirmDeleteLotValidate"
  ></DeleteLotModal>
  <!-- ADD LOCATION -->
  <AddLocationModal
    v-if="this.modals.addLocation.show"
    v-model="this.modals.addLocation.current.name"
    :projectId="this.$route.params.id"
    @close="modalAddLocationCancel"
    @validate="modalAddLocationValidate"
  ></AddLocationModal>
  <!-- CONFIRM DELETE LOCATION -->
  <DeleteLocationModal
    v-if="this.modals.confirmDeleteLocation.show"
    :locationId="this.modals.confirmDeleteLocation.id"
    :project="this.project"
    @close="modalConfirmDeleteLocationCancel"
    @validate="modalConfirmDeleteLocationValidate"
  ></DeleteLocationModal>
  <!-- EDIT LOCATION -->
  <EditLocationModal
    v-if="this.modals.editLocation.show"
    v-model="this.modals.editLocation.name"
    :locationId="this.modals.editLocation.id"
    :projectId="this.$route.params.id"
    @close="modalEditLocationCancel"
    @validate="modalEditLocationValidate"
  ></EditLocationModal>
  <!-- ASSIGN CONTACT -->
  <AssignContactModal
    v-if="this.modals.assignContact.show"
    v-model="this.modals.assignContact.current"
    :already-assigned-contacts="this.modals.assignContact.alreadyAssignedContacts"
    :contacts="this.contacts"
    :projectId="this.$route.params.id"
    @close="modalAssignContactCancel"
    @validate="modalAssignContactValidate"
  ></AssignContactModal>
  <!-- CONFIRM UNASSIGN CONTACT -->
  <UnassignContactModal
    v-if="this.modals.unassignContact.show"
    :contact-full-name="this.modals.unassignContact.contactFullName"
    :contactId="this.modals.unassignContact.contactId"
    :projectId="this.project?.id"
    @close="modalUnassignContactCancel"
    @validate="modalUnassignContactValidate"
  ></UnassignContactModal>
  <!-- MODAL - Import from lot -->
  <ImportLotModal
    v-if="this.modals.importLot.show"
    :currentProject="this.currentProject"
    @close="modalImportLotCancel"
    @validate="modalImportLotValidate"
  ></ImportLotModal>
  <!-- MODAL - Lot Sort -->
  <SortLotModal
    v-if="this.modals.autoSort.show"
    :project="this.currentProject"
    @close="modalAutoSortCancel"
    @validate="modalAutoSortValidate"
  ></SortLotModal>
  <!-- MODAL - Import from location -->
  <ImportLocationModal
    v-if="this.modals.importLocation.show"
    :currentProject="this.currentProject"
    @close="modalImportLocationCancel"
    @validate="modalImportLocationValidate"
  ></ImportLocationModal>
  <!-- MODAL CONTACT UPDATE -->
  <ContactUpdateModal
    v-if="this.modals.editContact.show"
    v-model="this.editContact"
    :callback="this.modalEditContactValidate"
    @close="this.modalEditContactCancel"
  ></ContactUpdateModal>
  <!-- MODAL TAG CREATE -->
  <CreateTagModal
    v-if="this.modals.createTag.show"
    v-model="this.modals.createTag.newTag"
    @close="modalCreateTagClose"
    @validate="modalCreateTagValidate"
  />
  <KalmModal
    v-if="this.modals.editCoverPhoto.show"
    modal-title="Modifier la photo de couverture"
    @close="this.modalEditCoverPhotoClose()"
  >
    <template v-slot:body>
      <div class="form-group mb-3">
        <label class="text-muted d-block">Photo de couverture</label>
        <input
          accept="image/png,image/jpg,image/jpeg"
          class="form-control form-control-lg form-control-solid"
          type="file"
          @change="this.modalEditCoverPhotoHandleFileChange($event)"
        />
      </div>
      <div class="form-group mb-3">
        <label class="text-muted" for="deleteExistingCoverPhoto">Retirer la photo de couverture existante</label>
        <input
          id="deleteExistingCoverPhoto"
          ref="deleteExistingCoverPhoto"
          v-model="this.modals.editCoverPhoto.deleteExisting"
          class="form-check-input ms-2"
          type="checkbox"
          @change="this.modalEditCoverPhotoHandleDeleteChange($event)"
        />
      </div>
      <div class="">
        <!-- IMAGE PREVIEW -->
        <p class="text-muted">Aperçu de la future photo :</p>
        <img
          v-if="this.modals.editCoverPhoto.preview"
          :src="this.modals.editCoverPhoto.preview"
          alt="Image de couverture du projet"
          class="card-img-top mh-150px object-fit-cover"
        />
        <p>Les dimensions maximales sont de 2000x2000px</p>
      </div>
    </template>
    <template v-slot:footer>
      <button class="btn btn-light" @click="this.modalEditCoverPhotoClose()">Annuler</button>
      <button
        :disabled="!this.modals.editCoverPhoto.file"
        class="btn btn-primary"
        @click="this.modalEditCoverPhotoValidate()"
      >
        Enregistrer
      </button>
    </template>
  </KalmModal>
</template>

<script>
import { GET_PROJECT_BY_ID, MUTATION_PROJECT_UPDATE } from "@/graphql/graphql";
import { CREATE_TAG, GET_TAGS } from "@/views/tags/data/tags_graphql";

import kalmStore from "@/store";
import {
  GET_ALL_CONTACTS_SIMPLE,
  CONTACT_BY_ID,
  MUTATION_ASSIGN_CONTACT,
} from "@/views/contacts/data/contacts_graphql";
import PrintHeader from "@/views/project/tender/PrintHeader";
import TagMultiSelect from "@/views/tags/presentation/tag-select/TagMultiSelect.vue";
import CreateTagModal from "@/views/tags/presentation/components/CreateTagModal.vue";

import { toast } from "vue3-toastify";
import EditLotModal from "../components/EditLotModal.vue";
import AddLotModal from "../components/AddLotModal.vue";
import DeleteLotModal from "../components/DeleteLotModal.vue";
import AddLocationModal from "../components/AddLocationModal.vue";
import DeleteLocationModal from "../components/DeleteLocationModal.vue";
import EditLocationModal from "../components/EditLocationModal.vue";
import AssignContactModal from "../components/AssignContactModal.vue";
import UnassignContactModal from "../components/UnassignContactModal.vue";
import ImportLotModal from "../components/ImportLotModal.vue";
import SortLotModal from "../components/SortLotModal.vue";
import ImportLocationModal from "../components/ImportLocationModal.vue";
import ContactUpdateModal from "@/views/contacts/presentation/components/ContactUpdateModal.vue";
import draggable from "vuedraggable";
import AvatarLogo from "@/views/_core/components/AvatarLogo.vue";
import KalmModal from "@/views/_core/components/KalmModal.vue";

export default {
  name: "ProjectHome",
  components: {
    AvatarLogo,
    draggable,
    ContactUpdateModal,
    PrintHeader,
    TagMultiSelect,
    EditLotModal,
    AddLotModal,
    DeleteLotModal,
    AddLocationModal,
    DeleteLocationModal,
    EditLocationModal,
    AssignContactModal,
    UnassignContactModal,
    ImportLotModal,
    SortLotModal,
    ImportLocationModal,
    CreateTagModal,
    KalmModal,
  },
  apollo: {
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update(data) {
        this.editProject.tags = data.project.tags.map((t) => t.id);

        return data.project;
      },
    },
    contacts: {
      query: GET_ALL_CONTACTS_SIMPLE,
    },
  },
  data() {
    return {
      dragOptions: {
        animation: 150,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      modals: {
        editContact: {
          show: false,
          contact: null,
        },
        editContactRole: {
          show: false,
          contactId: null,
          roleDisplayName: null,
          role: null,
          starred: false,
        },
        editCoverPhoto: {
          show: false,
          deleteExisting: false,
          file: null,
          preview: "",
        },
        createTag: {
          show: false,
          callback: null,
          newTag: {
            name: "",
            color: "",
          },
        },
        autoSort: {
          show: false,
        },
        importLot: {
          show: false,
        },
        importLocation: {
          show: false,
        },
        editLot: {
          show: false,
          newLot: {
            lotId: "",
            number: "",
            name: "",
            assignedCompanyId: "",
          },
        },
        addLot: {
          show: false,
          default: {
            number: 0,
            name: "Nouveau Lot",
            assignedCompanyId: null,
          },
        },
        confirmDeleteLot: {
          show: false,
          id: "",
        },
        addLocation: {
          show: false,
          default: {
            name: "Nouvel Emplacement 01",
          },
          current: {
            name: "Nouvel Emplacement 01",
          },
        },
        confirmDeleteLocation: {
          show: false,
          id: "",
        },
        editLocation: {
          show: false,
          id: "",
          name: "",
        },
        assignContact: {
          show: false,
          alreadyAssignedContacts: [],
          default: {
            contactId: "",
            role: "CONTRACTOR",
            roleDisplayName: "Entreprise",
          },
          current: {
            contactId: "",
            role: "CONTRACTOR",
            roleDisplayName: "Entreprise",
          },
        },
        unassignContact: {
          show: false,
          contactId: "",
        },
      },
      contact_role_options: [
        {
          id: "MOA",
          name: "MOA - Maîtrise d'Ouvrage",
        },
        {
          id: "MOE",
          name: "MOE - Maîtrise d'Œuvre",
        },
        {
          id: "CONTRACTOR",
          name: "Entreprise",
        },
        {
          id: "BET",
          name: "Bureaux d'études & conseil",
        },
        {
          id: "AUTRE",
          name: "Autre",
        },
      ],
      editProject: {
        name: "",
        address: "",
        infoOpName: "",
        importantInfo: "",
        archived: "",
        tags: [],
      },
      project: {
        contacts: [],
      },
      contacts: [],
      buttonProjectPropertiesLoading: false,
    };
  },
  methods: {
    modalEditContactRoleShow(contactParticipation) {
      this.modals.editContactRole.roleDisplayName = contactParticipation.roleDisplayName;
      this.modals.editContactRole.role = contactParticipation.role;
      this.modals.editContactRole.contactId = contactParticipation.contact.id;
      this.modals.editContactRole.starred = contactParticipation.starred;
      this.modals.editContactRole.show = true;
    },
    onChangeRole($event) {
      this.modals.editContactRole.roleDisplayName = this.contact_role_options.find(
        (r) => r.id === $event.target.value
      )?.name;
    },
    modalEditContactRoleClose() {
      this.modals.editContactRole.show = false;
      this.modals.editContactRole.roleDisplayName = null;
      this.modals.editContactRole.role = null;
      this.modals.editContactRole.contactId = null;
      this.modals.editContactRole.starred = false;
    },
    modalEditContactRoleValidate() {
      this.$apollo.mutate({
        mutation: MUTATION_ASSIGN_CONTACT,
        variables: {
          projectId: this.project.id,
          contactId: this.modals.editContactRole.contactId,
          role: this.modals.editContactRole.role,
          roleDisplayName: this.modals.editContactRole.roleDisplayName,
          starred: this.modals.editContactRole.starred,
        },
      });
      this.modalEditContactRoleClose();
    },
    modalEditCoverPhotoShow() {
      this.modals.editCoverPhoto.show = true;
      this.modals.editCoverPhoto.preview = this.project?.coverPhoto || "https://app.kalm.ai/static/img/kalm-bg.png";
    },
    modalEditCoverPhotoClose() {
      this.modals.editCoverPhoto.show = false;
    },
    modalEditCoverPhotoHandleFileChange($event) {
      console.log("000", this.modals.editCoverPhoto, $event);
      if (this.modals.editCoverPhoto.deleteExisting) {
        console.log("111");
        this.modals.editCoverPhoto.file = "delete";
        this.modals.editCoverPhoto.preview = "https://app.kalm.ai/static/img/kalm-bg.png";
      } else if ($event.target.files[0]) {
        console.log("222");
        this.modals.editCoverPhoto.preview = URL.createObjectURL($event.target.files[0]);
        this.modals.editCoverPhoto.file = $event.target.files[0];
        console.log(this.modals.editCoverPhoto.preview);
      }
    },
    modalEditCoverPhotoHandleDeleteChange($event) {
      console.log("333", $event.target.checked);
      if ($event.target.checked) {
        this.modals.editCoverPhoto.file = "delete";
        this.$refs.deleteExistingCoverPhoto.value = null;
        this.modals.editCoverPhoto.preview = "https://app.kalm.ai/static/img/kalm-bg.png";
      } else {
        this.modals.editCoverPhoto.file = null;
        this.$refs.deleteExistingCoverPhoto.value = null;
        this.modals.editCoverPhoto.preview = this.project?.coverPhoto || "https://app.kalm.ai/static/img/kalm-bg.png";
      }
    },
    modalEditCoverPhotoValidate() {
      this.$apollo.mutate({
        mutation: MUTATION_PROJECT_UPDATE,
        variables: {
          projectId: this.project.id,
          newCoverPhoto: this.modals.editCoverPhoto.file,
        },
      });
      this.modalEditCoverPhotoClose();
    },
    modalCreateTagShow(callback) {
      this.modals.createTag.show = true;
      this.modals.createTag.callback = callback;
    },
    async modalCreateTagValidate() {
      try {
        const tag = await this.$apollo.mutate({
          mutation: CREATE_TAG,
          variables: {
            name: this.modals.createTag.newTag.name,
            color: this.modals.createTag.newTag.color,
            entity: "PROJECT",
          },
          refetchQueries: [{ query: GET_TAGS, variables: { entity: "PROJECT" } }],
        });
        this.modals.createTag.callback(tag.data.tagCreate.tag.id);
        this.modalCreateTagClose();
        toast.success("Le tag a bien été créé");
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue lors de la création du tag");
      }
    },
    modalCreateTagClose() {
      this.modals.createTag.show = false;
      this.modals.createTag.newTag.name = "";
      this.modals.createTag.newTag.color = "";
    },
    async getContactById(contactId) {
      const ret = await this.$apollo
        .query({
          query: CONTACT_BY_ID,
          variables: {
            contactId,
          },
        })
        .then((res) => res.data.contact);
      return ret;
    },
    getLotById(lotId) {
      return this.currentProject?.lots.find((l) => l.id === lotId);
    },
    getLocationById(locationId) {
      return this.currentProject?.locations.find((l) => l.id === locationId);
    },
    async modalEditContactShow(contactId) {
      this.modals.editContact.contact = await this.getContactById(contactId);
      this.modals.editContact.show = true;
    },
    modalEditContactCancel() {
      this.modals.editContact.show = false;
      this.modals.editContact.contact = null;
    },
    modalEditContactValidate() {
      this.$apollo.queries.project.refetch();

      this.modalEditContactCancel();
    },
    modalImportLotShow() {
      this.modals.importLot.show = true;
    },
    modalImportLotCancel() {
      this.modals.importLot.show = false;
    },
    modalImportLotValidate() {
      this.$apollo.queries.project.refetch();

      this.modalImportLotCancel();
    },
    modalAutoSortShow() {
      this.modals.autoSort.show = true;
    },
    modalAutoSortCancel() {
      this.modals.autoSort.show = false;
    },
    modalAutoSortValidate() {
      this.$apollo.queries.project.refetch();
      this.modals.autoSort.show = false;
    },
    modalImportLocationShow() {
      this.modals.importLocation.show = true;
    },
    modalImportLocationCancel() {
      this.modals.importLocation.show = false;
    },
    modalImportLocationValidate() {
      this.$apollo.queries.project.refetch();

      this.modalImportLocationCancel();
    },
    modalLotShow() {
      this.modals.addLot.show = true;
    },
    modalLotCancel() {
      this.modals.addLot.default = {
        number: 0,
        name: "Nouveau Lot",
        assignedCompanyId: null,
      };
      this.modals.addLot.show = false;
    },
    modalLotValidate() {
      this.$apollo.queries.project.refetch();
      this.modals.addLot.show = false;
    },
    modalConfirmDeleteLotShow(id) {
      this.modals.confirmDeleteLot.id = id;
      this.modals.confirmDeleteLot.show = true;
    },
    modalConfirmDeleteLotValidate() {
      this.$apollo.queries.project.refetch();

      this.modalConfirmDeleteLotCancel();
    },
    modalConfirmDeleteLotCancel() {
      this.modals.confirmDeleteLot.show = false;
    },
    modalEditLotShow(id) {
      this.modals.editLot.newLot.lotId = id;
      const oldLot = JSON.parse(JSON.stringify(this.getLotById(id)));
      this.modals.editLot.newLot = {
        lotId: oldLot.id,
        name: oldLot.name,
        number: oldLot.number,
        assignedCompanyId: oldLot.assignedCompany?.id || null,
      };
      this.modals.editLot.show = true;
    },
    modalEditLotValidate() {
      this.$apollo.queries.project.refetch();

      this.modalEditLotCancel();
    },
    modalEditLotCancel() {
      this.modals.editLot.show = false;
      this.modals.editLot.newLot.lotId = "";
    },
    modalConfirmDeleteLocationShow(id) {
      this.modals.confirmDeleteLocation.id = id;
      this.modals.confirmDeleteLocation.show = true;
    },
    modalConfirmDeleteLocationValidate() {
      this.$apollo.queries.project.refetch();

      this.modalConfirmDeleteLocationCancel();
    },
    modalConfirmDeleteLocationCancel() {
      this.modals.confirmDeleteLocation.id = "";
      this.modals.confirmDeleteLocation.show = false;
    },
    modalAddLocationShow() {
      this.modals.addLocation.current.name = this.modals.addLocation.default.name;
      this.modals.addLocation.show = true;
    },
    modalAddLocationCancel() {
      this.modals.addLocation.current.name = this.modals.addLocation.default.name;
      this.modals.addLocation.show = false;
    },
    modalAddLocationValidate() {
      this.$apollo.queries.project.refetch();

      this.modalAddLocationCancel();
    },
    modalEditLocationShow(id) {
      this.modals.editLocation.id = id;
      this.modals.editLocation.name = this.project?.locations?.find((l) => l.id === id).name || "";
      this.modals.editLocation.show = true;
    },
    modalEditLocationValidate() {
      this.$apollo.queries.project.refetch();

      this.modalEditLocationCancel();
    },
    modalEditLocationCancel() {
      this.modals.editLocation.show = false;
      this.modals.editLocation.id = "";
      this.modals.editLocation.name = "";
    },
    modalAssignContactShow() {
      this.modals.assignContact.current.contactId = this.modals.assignContact.default.contactId;
      this.modals.assignContact.alreadyAssignedContacts = this.projectContacts.map((c) => c.contact.id);
      this.modals.assignContact.current.role = this.modals.assignContact.default.role;
      this.modals.assignContact.show = true;
    },
    modalAssignContactValidate() {
      this.$apollo.queries.project.refetch();

      this.modals.assignContact.show = false;
    },
    modalAssignContactCancel() {
      this.modals.assignContact.show = false;
    },
    getContactName(contact) {
      if (contact.company && contact.name) return `${contact.name} (${contact.company})`;
      else
        return contact?.company || (contact.firstName + (contact.firstName && contact.name) ? " " : "" + contact?.name);
    },
    modalUnassignContactShow(contact) {
      this.modals.unassignContact.show = true;
      this.modals.unassignContact.contactId = contact.id;
      this.modals.unassignContact.contactFullName = this.getContactName(contact);
    },
    modalUnassignContactCancel() {
      this.modals.unassignContact.show = false;
      this.modals.unassignContact.contactId = "";
      this.modals.unassignContact.contactFullName = "";
    },
    modalUnassignContactValidate() {
      this.$apollo.queries.project.refetch();

      this.modalUnassignContactCancel();
    },
    async updateProjectProperties() {
      this.buttonProjectPropertiesLoading = true;

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_PROJECT_UPDATE,
          variables: {
            projectId: this.currentProject?.id,
            newName: this.projectProperties.name,
            newInfoOpName: this.projectProperties.infoOpName,
            newInfoOpAddress: this.projectProperties.address,
            tagIds: this.projectProperties.tags,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_BY_ID,
              variables: {
                id: this.currentProject?.id,
              },
            },
          ],
        });

        toast.success("Les informations du projet ont été mises à jour avec succès.");
        this.buttonProjectPropertiesLoading = false;
      } catch {
        toast.error("Une erreur est survenue lors de la mise à jour des informations du projet.");
        this.buttonProjectPropertiesLoading = false;
      }
    },
  },
  computed: {
    projectContacts: {
      get() {
        console.log(this.project?.contacts);
        return this.project?.contacts;
      },
      set(newValue) {
        this.$apollo
          .mutate({
            mutation: MUTATION_PROJECT_UPDATE,
            variables: {
              projectId: this.project.id,
              projectContactsOrder: newValue.map((c) => c.contact.id),
            },
            optimisticResponse: {
              __typename: "Mutation",
              projectUpdate: {
                __typename: "ProjectUpdate",
                project: {
                  __typename: "ProjectGType",
                  id: this.project.id,
                  contacts: newValue,
                },
              },
            },
          })
          .then(() => {
            toast.success("L'ordre des intervenants a été enregistré");
          })
          .catch((error) => {
            console.error(error);
            toast.error("Une erreur est survenue lors de la mise à jour de l'ordre des intervenants");
          });
      },
    },
    modalActive() {
      return kalmStore.state.display.profileToggled || Object.values(this.modals).some((m) => m.show);
    },
    currentProject() {
      return this.project;
    },
    isArchived() {
      return this.currentProject?.archived;
    },
    getProjects() {
      return kalmStore.state.description.projects;
    },
    projectProperties: {
      get() {
        return {
          name: this.editProject.name || this.currentProject.name,
          address: this.editProject.address || this.currentProject.address,
          infoOpName: this.editProject.infoOpName || this.currentProject.infoOpName,
          importantInfo: this.editProject.importantInfo || this.currentProject.importantInfo,
          archived: this.editProject.archived,
          tags: this.editProject.tags || this.currentProject.tags.map((t) => t.id),
        };
      },
      set(newValue) {
        this.editProject = newValue;
      },
    },
    editContact() {
      return { ...this.modals.editContact.contact };
    },
  },
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
</style>

<style scoped>
@media print {
  .print-hidden {
    display: none !important;
  }

  .planning-card-shadow {
    box-shadow: none;
  }
}

.tool-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: none;
  border-radius: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tool-button:hover {
  background-color: #e0e0e0;
}

.tool-button i {
  font-size: 24px;
  margin-bottom: 5px;
}

.tool-button span {
  font-size: 14px;
}
</style>
