<template>
  <div :class="modalActive ? 'blur-content' : ''">
    <div class="print-hidden">
      <ProjectBreadcrumbComponent
        :options="[
          { name: 'Tâches', id: 'project.tasks' },
          { name: 'Compte-rendus', id: 'project.monitoring_new' },
          {
            name: 'Avancement',
            type: 'link',
            url: this.project?.betaSpreadsheet,
            disabled: !this.project?.betaSpreadsheet,
          },
        ]"
        :project_id="$route.params.id"
        current_route="project.monitoring_new"
        title="Suivi de Chantier"
      ></ProjectBreadcrumbComponent>
    </div>
    <!--begin::Row-->
    <!--  <div class="row">
        <div class="col-12">
          <ProgressCard
              card_type="kalm"
              card_title="Suivi de chantier"
              card_subtitle="Dernière réunion il y a 2 jours"
              card_text_line_1="7 ouvrages réalisés"
              card_text_line_2="90 restants"
              :card_progress_percent="8"></ProgressCard>
        </div>
      </div>-->
    <!--end::Row-->
    <ReportsList :projectId="currentProjectId"></ReportsList>
    <PrintHeader pageTitle="Suivi du chantier" sectionTitle="Planning de chantier"></PrintHeader>
  </div>
  <!-- MODAL EDIT LOT -->
  <div :style="modals.editLotPlanning.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditLotPlanningCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-1">
                        <h3 class="fw-bolder text-dark display-6">Modifier un lot</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                        <label class="ms-1 fs-8 text-muted form-label"></label>
                        <input
                          v-model="modals.editLotPlanning.lot.startDate"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="2023-07-01"
                          type="date"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                        <label class="ms-1 fs-8 text-muted form-label"></label>
                        <input
                          v-model="modals.editLotPlanning.lot.endDate"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="2023-08-01"
                          type="date"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaire pour le CR</label>
                        <textarea
                          v-model="modals.editLotPlanning.lot.comment"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Pose du parquet dans les chambres lundi et mardi"
                        ></textarea>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" type="button" @click="modalEditLotPlanningCancel">
                        Annuler
                      </button>
                      <button class="btn btn-lg btn-primary ms-2" type="button" @click="modalEditLotPlanningValidate">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ProgressCard from "@/views/_core/components/ProgressCard";
import { CURRENT_USER_QUERY, GET_PROJECT_BY_ID, MUTATION_PROJECT_UPDATE } from "@/graphql/graphql";
import kalmStore from "@/store";
import { MUTATION_PROJECT_UPDATE_LOT } from "@/views/lots/data/lots_graphql";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import PrintHeader from "@/views/project/tender/PrintHeader";
import ReportsList from "@/views/reports/presentation/reports/ReportsList.vue";
import { toast } from "vue3-toastify";

export default {
  name: "MonitoringPage",
  components: {
    /*ProgressCard, */ ProjectBreadcrumbComponent,
    PrintHeader,
    ReportsList,
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: kalmStore.getters.currentProjectFocus,
        };
      },
      skip() {
        return !(
          kalmStore.getters.currentProjectFocus &&
          kalmStore.getters.currentProjectFocus !== "0" &&
          kalmStore.getters.currentProjectFocus !== 0
        );
      },
    },
  },
  data() {
    return {
      currentUser: {},
      project: {},
      modals: {
        editLotPlanning: {
          show: false,
          lotId: "",
          lot: {
            comment: "",
            startDate: "",
            endDate: "",
          },
        },
      },
      buttonProjectPropertiesLoading: false,
      editProject: {
        importantInfo: "",
      },
    };
  },
  methods: {
    modalImportTasksQuickSelect(lots, mode) {
      if (mode === "all") {
        lots.length = 0;
        this.currentProject.lots.forEach((l) => {
          lots.push(l.id);
        });
      } else {
        lots.length = 0;
      }
    },
    printDocument() {
      window.print();
    },
    modalEditLotPlanningShow(lotId) {
      this.modals.editLotPlanning.lotId = lotId;

      const lot = this.currentProject.lots.find((l) => l.id === lotId);

      this.modals.editLotPlanning.lot.comment = lot.comment;
      this.modals.editLotPlanning.lot.startDate = lot.startDate;
      this.modals.editLotPlanning.lot.endDate = lot.endDate;

      this.modals.editLotPlanning.show = true;
    },
    modalEditLotPlanningCancel() {
      this.modals.editLotPlanning.show = false;

      this.modals.editLotPlanning.lotId = "";
      this.modals.editLotPlanning.lot.comment = "";
      this.modals.editLotPlanning.lot.startDate = "";
      this.modals.editLotPlanning.lot.endDate = "";
    },
    modalEditLotPlanningValidate() {
      this.$apollo.mutate({
        mutation: MUTATION_PROJECT_UPDATE_LOT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
          lotId: this.modals.editLotPlanning.lotId,
          startDate: this.modals.editLotPlanning.lot.startDate,
          endDate: this.modals.editLotPlanning.lot.endDate,
          comment: this.modals.editLotPlanning.lot.comment,
        },
        refetchQueries: [
          {
            query: GET_PROJECT_BY_ID,
            variables: {
              id: this.currentProjectId,
            },
          },
        ],
      });

      this.modalEditLotPlanningCancel();
    },
    storeIsReady() {
      return this.currentProjectId && this.currentProjectId !== "0" && this.currentProjectId !== 0;
    },
    async updateProjectProperties() {
      this.buttonProjectPropertiesLoading = true;

      setTimeout(() => {
        this.buttonProjectPropertiesLoading = false;
      }, 350);

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_PROJECT_UPDATE,
          variables: {
            projectId: kalmStore.getters.currentProjectFocus,
            newInfoImportantInfo: this.projectProperties.importantInfo,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_BY_ID,
              variables: {
                id: this.currentProjectId,
              },
            },
          ],
        });
        toast.success("Les informations ont été mises à jour");
      } catch (e) {
        toast.error("Une erreur est survenue");
        console.error(e);
      }
    },
  },
  computed: {
    currentProjectId() {
      return kalmStore.getters.currentProjectFocus;
    },
    modalActive() {
      return kalmStore.state.display.profileToggled || this.modals.editLotPlanning.show;
    },
    currentProject() {
      return this.project;
    },
    isArchived() {
      return this.currentProject.archived;
    },
    projectProperties: {
      get() {
        return {
          importantInfo: this.editProject.importantInfo || this.currentProject.importantInfo,
        };
      },
      set(newValue) {
        this.editProject = newValue;
      },
    },
  },
};
</script>

<style scoped>
@media print {
  .print-hidden {
    display: none;
  }
}
</style>
