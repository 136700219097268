import gql from 'graphql-tag';

export const GET_TAGS = gql`
    query Tags($entity: String) {
        tags(entity: $entity) {
            id
            name
            color
            type
            entity
        }
    }
`;

export const ALL_TAGS = gql`
    query AllTags {
        allTags {
            id
            name
            color
            type
            entity
        }
    }
`;

export const CREATE_TAG = gql`
    mutation CreateTag($name: String!, $color: String!, $entity: String) {
        tagCreate(name: $name, color: $color, entity: $entity) {
            tag {
                id
                name
                color
                type
                entity
            }
        }
    }
`;

export const UPDATE_TAG = gql`
    mutation UpdateTag($id: UUID!, $name: String!, $color: String!) {
        tagUpdate(id: $id, name: $name, color: $color) {
            tag {
                id
                name
                color
                type
                entity
            }
        }
    }
`;

export const DELETE_TAG = gql`
    mutation DeleteTag($id: UUID!) {
        tagDelete(id: $id) {
            success
        }
    }
`;
