<template>
  <div style="z-index: 10000 !important" class="">
    <label class="col-form-label">{{ title }}</label>
    <span :class="this.open ? 'bg-blue' : ''">
      <div
        ref="input"
        class="form-select cursor-pointer fw-normal text-gray-800 py-1 fs-6 field-multilocation"
        style="z-index: 10"
        :class="this.open ? 'rounded-bottom-0' : ''"
        @click="this.openClose"
        :value="selectedLabels"
      >
        <div
          v-for="tag in this.value"
          :key="tag"
          class="d-inline-block cursor-default bg-primary text-white px-3 m-1 rounded"
        >
          <span>{{ statuses?.find((l) => l.id === tag)?.name }}</span>
          <i class="bi bi-x ms-2 text-white align-middle cursor-pointer" @click="removeValue($event, tag)"></i>
        </div>
        <div v-if="!this.value.length" class="fs-6 py-1">Aucun statut sélectionné</div>
      </div>
    </span>
    <span
      v-if="this.open"
      :style="`position: absolute; z-index: 10000 !important; width: ${this.computeWidth}px;
    `"
      class="d-block p-0"
    >
      <ul
        ref="dropdown"
        class="w-100 list-unstyled bg-white rounded-bottom"
        style="border: 1px solid #e4e6ef; border-top: none; z-index: 200 !important"
      >
        <li
          v-for="option in statuses?.filter((o) => !this.value.includes(o.id))"
          style="z-index: 10000 !important"
          :key="option.id"
          class="p-2 text-gray-800 fs-6 px-5 py-3 bg-hover-secondary"
          @click="addValue($event, option.id)"
        >
          {{ option.name }}
        </li>
        <li
          v-if="statuses?.filter((o) => !this.value.includes(o.id)).length === 0"
          class="p-2 ps-5 bg-white fs-6 rounded-bottom"
          style="z-index: 10000 !important"
        >
          Tous les status ont été ajoutés
        </li>
      </ul>
    </span>
  </div>
</template>

<script>
export default {
  name: "StatusMultiSelect",
  props: {
    title: String,
    statuses: Array,
    modelValue: Array,
  },
  beforeUnmount() {
    window.removeEventListener("click", this.close);
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.multiselectWidth = this.$refs.input.offsetWidth;
    window.addEventListener("resize", this.handleResize);
  },
  data: () => ({
    open: false,
    multiselectWidth: 0,
  }),
  methods: {
    handleResize() {
      if (this.$refs.input) {
        this.multiselectWidth = this.$refs.input.offsetWidth;
      }
    },
    close(event) {
      if (!this.$el.contains(event.target)) {
        this.open = false;
      }
      event.stopPropagation();
      event.preventDefault();
    },
    openClose() {
      this.open = !this.open;
    },
    addValue(event, id) {
      this.value = [...this.value, id].sort(this.sortValue);
      this.$nextTick(() => {
        if (this.statuses?.filter((o) => !this.value.includes(o.id)).length === 0) {
          this.openClose();
        }
      });
      event.stopPropagation();
      event.preventDefault();
    },
    removeValue(event, id) {
      this.value = [...this.value.filter((l) => l !== id)];
      event.stopPropagation();
      event.preventDefault();
    },
    sortValue(a, b) {
      if (this.statuses?.find((l) => l.id === a)?.name < this.statuses?.find((l) => l.id === b)?.name) {
        return -1;
      } else if (this.statuses?.find((l) => l.id === a)?.name > this.statuses?.find((l) => l.id === b)?.name) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  computed: {
    computeWidth() {
      return this.multiselectWidth;
    },
    value: {
      get() {
        return this.modelValue ?? [];
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    selectedLabels: {
      get() {
        return [...new Set(this.value)].map((v) => this.statuses?.find((l) => l.id === v)?.name).join(", ");
      },
    },
  },
  watch: {
    open(newValue) {
      if (newValue) {
        document.addEventListener("click", this.close);
      } else {
        document.removeEventListener("click", this.close);
      }
    },
  },
};
</script>

<style scoped>
@media print {
  .print-hidden {
    display: none !important;
  }
}
</style>
