<template>
  <KalmModal modalTitle="Compte rendu" @close="close">
    <template v-slot:body>
      <p>Félicitation, votre compte rendu est prêt</p>
      <button class="btn btn-primary btn-sm" @click="openReport">Compte rendu n° {{ report.reportNumber }}</button>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Ok</button>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
export default {
  name: "ReportCreatedModal",
  components: {
    KalmModal,
  },
  props: {
    report: Object,
  },
  methods: {
    openReport() {
      window.open(this.report.file.url, "_blank");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
