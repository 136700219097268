<template>
  <KalmModal modalTitle="Commentaires" @close="close">
    <template v-slot:body>
      <CommentsList :taskId="taskId"></CommentsList>
    </template>
    <template v-slot:footer>
      <button class="btn btn-secondary" data-bs-dismiss="modal" type="button" @click="close">Fermer</button>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import CommentsList from "./CommentsList.vue";
export default {
  name: "CommentsListModal",
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  components: {
    KalmModal,
    CommentsList,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
