<template>
  <div class="mt-3 mb-2 w-100" @mouseleave="this.hover = false" @mouseover="this.hover = true">
    <div class="w-100 d-flex justify-content-center">
      <template v-for="(option, index) in this.options" :key="option.id">
        <input
          :id="'switch-attendance-' + this.contactId + option.id + formId"
          v-model="this.value"
          :value="option.id"
          class="btn-check"
          type="radio"
        />
        <label
          :class="index === 0 ? 'rounded-end-0' : index === this.options.length - 1 ? 'rounded-start-0' : 'rounded-0'"
          :for="'switch-attendance-' + this.contactId + option.id + formId"
          class="flex-grow-1 btn btn-primary"
        >
          {{ option.name }}
        </label>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresenceButton",
  props: {
    modelValue: String,
    contactId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    hover: false,
    options: [
      { id: "P", name: "Présent" },
      { id: "ABS", name: "Absent" },
      { id: "E", name: "Excusé" },
      { id: "R", name: "Représenté" },
    ],
  }),
  computed: {
    formId() {
      return Math.random().toString(36).substring(7);
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
