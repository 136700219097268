<template>
  <IconButton iconName="copy"></IconButton>
</template>

<script>
import IconButton from "./IconButton.vue";
export default {
  name: "DuplicateButton",
  components: {
    IconButton,
  },
};
</script>
