<template>
  <div class="modal" tabindex="-1" style="display: block" id="DuplicateProjectModal">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.$emit('close')">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div
                  ref="contactEditModalForm"
                  class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                >
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouveau projet</h3>
                        <p>
                          Ce projet va copier le descriptif, les lots et les emplacements du projet
                          {{ this.project?.name }}
                        </p>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom du projet</label>
                        <input
                          v-model="this.newProjectName"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="P03 - TERRE-NEUVE"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Opération</label>
                        <input
                          v-model="this.newInfoOpName"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Surélévation d'un atelier"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Adresse</label>
                        <input
                          v-model="this.newInfoOpAddress"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="26 rue Terre-Neuve, 1005 Bruxelles"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <div class="align-center form-check mt-6 form-switch">
                          <label class="fs-6 text-dark form-label" for="duplicateLocations"
                            >Dupliquer les emplacements</label
                          >
                          <input
                            v-model="this.duplicateLocations"
                            id="duplicateLocations"
                            type="checkbox"
                            class="form-check-input"
                          />
                        </div>
                      </div>

                      <!--begin::Form Group-->
                      <div class="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <div class="align-center form-check mt-3 form-switch">
                          <label class="fs-6 text-dark form-label" for="duplicateContacts"
                            >Dupliquer les entreprises et intervenants</label
                          >
                          <input
                            v-model="this.duplicateContacts"
                            id="duplicateContacts"
                            type="checkbox"
                            class="form-check-input"
                          />
                        </div>
                      </div>

                      <!--begin::Form Group-->
                      <div class="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <div class="align-center form-check mt-3 form-switch">
                          <label class="fs-6 text-dark form-label" for="duplicateQts">Conserver les quantitatifs</label>
                          <input
                            v-model="this.duplicateQts"
                            id="duplicateQts"
                            type="checkbox"
                            class="form-check-input"
                          />
                        </div>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" @click="this.$emit('close')">Annuler</button>
                      <button
                        class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                        :disabled="!this.newProjectName"
                        @click="validate"
                      >
                        Ajouter le projet
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MUTATION_DUPLICATE_PROJECT } from "@/graphql/graphql";
import { toast } from "vue3-toastify";

export default {
  name: "DuplicateProjectModal",
  props: {
    project: {
      type: Object,
      Required: true,
      reactivity: true,
    },
  },
  data() {
    return {
      duplicateLocations: true,
      duplicateContacts: true,
      duplicateQts: true,
      newProjectName: "",
      newInfoOpName: "",
      newInfoOpAddress: "",
    };
  },
  mounted() {
    this.newInfoOpName = this.project.infoOpName;
    this.newInfoOpAddress = this.project.address;
    this.newProjectName = "Copie de " + this.project.name;
    console.log(this.project);
  },
  computed: {
    oldProjectId() {
      return this.project.id;
    },
    duplicateProject() {
      return {
        oldProjectId: this.oldProjectId,
        newProjectName: this.newProjectName,
        newInfoOpName: this.newInfoOpName,
        newInfoOpAddress: this.newInfoOpAddress,
        duplicateLocations: this.duplicateLocations,
        duplicateContacts: this.duplicateContacts,
        duplicateQts: this.duplicateQts,
      };
    },
  },
  methods: {
    async validate() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DUPLICATE_PROJECT,
          variables: this.duplicateProject,
        });
        toast.success("Le projet a été dupliqué avec succès");
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de la duplication du projet");
      }
      this.$emit("validate", this.duplicateProject);
    },
  },
};
</script>

<style lang="scss" scoped></style>
