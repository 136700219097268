<template>
  <div :class="{ 'blur-content': this.isModalActive }" class="row g-5">
    <!-- 1 - PROJETS EN COURS -->
    <div class="col-12">
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Projets en cours</span>
          </h3>
          <div class="card-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-light">
              <li class="nav-item">
                <a class="nav-link btn btn-primary fw-bolder me-2" @click="modalCreateProjectShow()">Nouveau projet</a>
              </li>
            </ul>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 mt-n3">
          <div id="myTabTables2" class="tab-content mt-4">
            <!--begin::Table-->
            <div class="table-responsive">
              <div
                v-if="this.$apollo.queries.projects?.loading"
                class="d-flex justify-content-center align-items-center h-100 w-100"
              >
                <div class="spinner spinner-border text-primary text-center spinner-primary mt-10 mb-5"></div>
              </div>
              <table v-else class="table table-borderless align-middle">
                <thead>
                  <tr>
                    <th class="px-2 py-0"></th>
                    <th class="px-2 py-0 w-100"></th>
                    <th class="px-2 py-0 min-w-200px"></th>
                    <th class="px-2 py-0 min-w-100px"></th>
                    <th class="px-2 py-0 min-w-50px"></th>
                    <th class="px-2 py-0 min-w-100px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="project in this.activeProjects"
                    :key="project.id"
                    class="bg-hover-light"
                    @click="this.navigateToProject(project.id)"
                  >
                    <td class="px-0 py-3 rounded-start p-5">
                      <div v-if="project?.coverPhoto" class="symbol symbol-55px ms-5 mt-1 me-5">
                        <img :src="project?.coverPhoto" class="object-fit-cover" />
                      </div>
                      <div v-else class="symbol symbol-55px ms-5 mt-1 me-5">
                        <span class="symbol-label bg-light-primary align-middle align-items-end">
                          <i class="bi bi-bricks fs-1 align-middle align-self-center"></i>
                        </span>
                      </div>
                    </td>
                    <td class="px-0">
                      <p class="text-gray-800 fw-bolder fs-6 my-1">{{ project.name }}</p>
                      <span class="text-muted fw-bold d-block mt-1">{{ project.address }}</span>
                    </td>
                    <td class="px-0 text-center">
                      <div v-if="project?.currentPhase">
                        <span class="bg-light text-gray-800 border text-center rounded py-1 px-2 h-30px">{{
                          project.currentPhase
                        }}</span>
                      </div>
                    </td>
                    <td class="px-3 d-none d-md-table-cell">
                      <div class="d-none align-items-center d-md-flex">
                        <span
                          v-for="tag in project?.tags"
                          :key="tag.id"
                          :style="{ background: tag?.color }"
                          class="d-inline-block cursor-default text-white px-3 m-1 rounded"
                        >
                          {{ tag?.name }}
                        </span>
                      </div>
                    </td>
                    <td></td>
                    <td class="px-0 rounded-end">
                      <button
                        v-tooltip:bottom="'Archiver ce projet'"
                        class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                        @click="this.modalConfirmArchiveProjectShow(project.id)"
                      >
                        <i class="bi bi-archive align-baseline text-center"></i>
                      </button>
                      <button
                        v-tooltip:bottom="'Dupliquer ce projet'"
                        class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                        @click="this.modalDuplicateFromProjectShow(project)"
                      >
                        <i class="fa fa-copy align-baseline text-center"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
    </div>
    <!-- 3 - PROJETS ARCHIVÉS -->
    <div class="col-12">
      <!--begin::Table Widget 2-->
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Projets archivés</span>
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 mt-n3">
          <div id="myTabTables2" class="tab-content mt-4">
            <!--begin::Table-->
            <div class="table-responsive">
              <div
                v-if="this.$apollo.queries.projects?.loading"
                class="d-flex justify-content-center align-items-center h-100 w-100"
              >
                <div class="spinner spinner-border text-primary text-center spinner-primary mt-10 mb-5"></div>
              </div>
              <table v-else class="table table-borderless align-middle">
                <thead>
                  <tr>
                    <th class="p-0 w-50px"></th>
                    <th class="p-0 min-w-150px"></th>
                    <th class="px-2 py-0 w-100px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="project in this.archivedProjects"
                    :key="project.id"
                    class="bg-hover-light"
                    @click="this.navigateToProject(project.id)"
                  >
                    <td class="px-0 py-3 rounded-start p-5">
                      <div class="symbol symbol-55px ms-5 mt-1 me-5">
                        <span class="symbol-label bg-light-primary align-middle align-items-end">
                          <!--         REMPLACER PAR IMAGE-AVATAR DU PROJET <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />-->
                          <i class="bi bi-bricks fs-1 align-middle align-self-center"></i>
                        </span>
                      </div>
                    </td>
                    <td class="px-0">
                      <p class="text-gray-800 fw-bolder fs-6 my-1">{{ project.name }}</p>
                      <span class="text-muted fw-bold d-block mt-1">{{ project.address }}</span>
                    </td>
                    <td class="p-0 rounded-end">
                      <button
                        v-tooltip:bottom="'Désarchiver ce projet'"
                        class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                        @click="this.modalConfirmUnarchiveProjectShow(project.id)"
                      >
                        <i class="bi bi-life-preserver align-baseline text-center"></i>
                      </button>
                      <button
                        v-tooltip:bottom="'Supprimer ce projet'"
                        class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-danger btn-sm btn-active-white me-2"
                        disabled
                        @click="this.modalConfirmDeleteProjectShow(project.id)"
                      >
                        <i class="bi bi-trash align-baseline text-center"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Table Widget 2-->
    </div>
  </div>
  <!-- MODAL CREATE NEW PROJECT -->
  <div :style="this.modals.createProject.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalCreateProjectCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div
                  ref="contactEditModalForm"
                  class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                >
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouveau projet</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom du projet</label>
                        <input
                          v-model="modals.createProject.newProject.name"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="P03 - TERRE-NEUVE"
                          type="text"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Opération</label>
                        <input
                          v-model="modals.createProject.newProject.newInfoOpName"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Surélévation d'un atelier"
                          type="text"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Adresse</label>
                        <input
                          v-model="modals.createProject.newProject.newInfoOpAddress"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="26 rue Terre-Neuve, 1005 Bruxelles"
                          type="text"
                        />
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" @click="modalCreateProjectCancel()">Annuler</button>
                      <button
                        :disabled="!modals.createProject.newProject.name"
                        class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                        @click="modalCreateProjectValidate()"
                      >
                        Ajouter le projet
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL DUPLICATE PROJECT -->
  <DuplicateProjectModal
    v-if="this.modals.duplicateProject.show"
    :project="this.modals.duplicateProject.oldProject"
    @close="modalDuplicateFromProjectCancel"
    @validate="modalDuplicateFromProjectValidate"
  ></DuplicateProjectModal>
  <!-- MODAL CONFIRM ARCHIVE PROJECT -->
  <div :style="this.modals.archiveProject.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalConfirmArchiveProjectCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Archiver le projet</h3>
                      </div>
                      <p>
                        Vous pourrez toujours le consulter, le dupliquer, mais plus le modifier ou en faire des exports.
                        Si besoin de le désarchiver, n'hésitez pas à contacter le service client :)
                      </p>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button
                        class="btn btn-lg btn-light"
                        type="button"
                        @click="this.modalConfirmArchiveProjectCancel()"
                      >
                        Annuler
                      </button>
                      <button
                        class="btn btn-lg btn-primary ms-2"
                        type="button"
                        @click="this.modalConfirmArchiveProjectValidate()"
                      >
                        Confirmer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL CONFIRM UNARCHIVE PROJECT -->
  <div :style="this.modals.unarchiveProject.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalConfirmUnarchiveProjectCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Désarchiver le projet</h3>
                      </div>
                      <p>Welcome back :)</p>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button
                        class="btn btn-lg btn-light"
                        type="button"
                        @click="this.modalConfirmUnarchiveProjectCancel()"
                      >
                        Annuler
                      </button>
                      <button
                        class="btn btn-lg btn-primary ms-2"
                        type="button"
                        @click="this.modalConfirmUnarchiveProjectValidate()"
                      >
                        Confirmer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ALL_PROJECTS_QUERY,
  MUTATION_CREATE_PROJECT,
  MUTATION_ARCHIVE_PROJECT,
  MUTATION_UNARCHIVE_PROJECT,
} from "@/graphql/graphql";
import kalmStore from "@/store";
import { toast } from "vue3-toastify";
import DuplicateProjectModal from "./components/DuplicateProjectModal.vue";

export default {
  name: "ProjectsPage",
  apollo: {
    projects: {
      query: ALL_PROJECTS_QUERY,
    },
  },
  components: {
    DuplicateProjectModal,
  },
  methods: {
    modalConfirmArchiveProjectShow(projectId) {
      event.stopPropagation();
      this.modals.archiveProject.projectId = projectId;
      this.modals.archiveProject.show = true;
    },
    modalConfirmArchiveProjectCancel() {
      this.modals.archiveProject.projectId = "";
      this.modals.archiveProject.show = false;
    },
    async modalConfirmArchiveProjectValidate() {
      const projectId = this.modals.archiveProject.projectId;
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_ARCHIVE_PROJECT,
          variables: {
            projectId,
          },
          refecthQueries: [
            {
              query: ALL_PROJECTS_QUERY,
            },
          ],
        });
        toast.success("Projet archivé avec succès");
      } catch {
        toast.error("Erreur lors de l'archivage du projet");
      }
      this.modals.archiveProject.show = false;
    },
    modalConfirmUnarchiveProjectShow(projectId) {
      event.stopPropagation();
      this.modals.unarchiveProject.projectId = projectId;
      this.modals.unarchiveProject.show = true;
    },
    modalConfirmUnarchiveProjectCancel() {
      this.modals.unarchiveProject.projectId = "";
      this.modals.unarchiveProject.show = false;
    },
    async modalConfirmUnarchiveProjectValidate() {
      const projectId = this.modals.unarchiveProject.projectId;
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UNARCHIVE_PROJECT,
          variables: {
            projectId,
          },
          refecthQueries: [
            {
              query: ALL_PROJECTS_QUERY,
            },
          ],
        });
        toast.success("Projet désarchivé avec succès");
      } catch {
        toast.error("Erreur lors de la désarchivage du projet");
      }
      this.modals.unarchiveProject.show = false;
    },
    modalDuplicateFromProjectShow(project) {
      this.modals.duplicateProject.oldProject = project;
      this.modals.duplicateProject.show = true;
      event.stopPropagation();
    },
    modalDuplicateFromProjectCancel() {
      this.modals.duplicateProject.oldProject = null;
      this.modals.duplicateProject.show = false;
    },
    modalDuplicateFromProjectValidate() {
      this.$apollo.queries.projects.refetch();
      this.modalDuplicateFromProjectCancel();
    },
    navigateToProject(projectId) {
      this.$router.push({
        name: "project.home",
        params: {
          id: projectId,
        },
      });
    },
    modalCreateProjectShow() {
      this.modals.createProject.show = true;
      this.modals.createProject.newProject.name = "";
    },
    modalCreateProjectCancel() {
      this.modals.createProject.show = false;
      this.modals.createProject.newProject.name = "";
    },
    async modalCreateProjectValidate() {
      const newProjectName = this.modals.createProject.newProject.name;
      const newInfoOpName = this.modals.createProject.newProject.newInfoOpName;
      const newInfoOpAddress = this.modals.createProject.newProject.newInfoOpAddress;

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_CREATE_PROJECT,
          variables: {
            newInfoOpAddress,
            newInfoOpName,
            newProjectName,
          },
          refetchQueries: [
            {
              query: ALL_PROJECTS_QUERY,
            },
          ],
        });
        toast.success("Projet créé avec succès");
      } catch {
        toast.error("Erreur lors de la création du projet");
      }

      this.modalCreateProjectCancel();
    },
  },
  computed: {
    isModalActive() {
      return kalmStore.state.display.profileToggled || Object.values(this.modals).some((m) => m.show);
    },
    activeProjects() {
      return this.projects.filter((p) => !p.archived);
    },
    archivedProjects() {
      return this.projects.filter((p) => p.archived);
    },
  },
  data() {
    return {
      modals: {
        createProject: {
          show: false,
          newProject: {
            name: "",
            newInfoOpName: "",
            newInfoOpAddress: "",
          },
        },
        duplicateProject: {
          show: false,
          oldProject: null,
        },
        archiveProject: {
          show: false,
          projectId: "",
        },
        unarchiveProject: {
          show: false,
          projectId: "",
        },
        deleteProject: {
          show: false,
          projectId: "",
        },
      },
      projects: [],
      libraries: [],
    };
  },
};
</script>

<style scoped></style>
