<template>
  <IconButton iconName="paperclip">
    <template v-slot:before-icon>
      <span class="ms-2">{{ countFilesForLinkedTodoItem }}</span>
    </template>
  </IconButton>
</template>

<script>
import IconButton from "@/views/_core/components/IconButton.vue";
import { GET_TODO_ITEM_BY_ID } from "@/views/todos/data/todos_graphql";

export default {
  name: "FileCounterForTodoItem",
  props: {
    todoItemId: {
      type: String,
      required: true,
    },
  },
  components: {
    IconButton,
  },
  computed: {
    countFilesForLinkedTodoItem() {
      return this.todoItem?.files?.length || 0;
    },
  },
  data() {
    return {
      todoItem: {
        files: [],
      },
    };
  },
  apollo: {
    todoItem: {
      query: GET_TODO_ITEM_BY_ID,
      variables() {
        return {
          todoItemId: this.todoItemId,
        };
      },
    },
  },
};
</script>
