<template>
  <div class="mt-4">
    <div>
      <input v-model="name" type="text" class="form-control" id="name" placeholder="Nom de la tâche" />
    </div>
    <div class="my-4 d-flex gap-3">
      <button @click="close" type="button" class="btn btn-secondary">Annuler</button>
      <button @click="save" :disabled="!canSave()" type="button" class="btn btn-primary">Sauvegarder</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TodoSubItemForm",
  props: {
    todoItem: Object,
  },
  components: {},
  data() {
    return {
      name: "",
    };
  },
  created() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      if (this.todoItem) {
        this.name = this.todoItem.name;
      }
    },
    canSave() {
      return this.name?.length;
    },
    close() {
      this.$emit("close");
    },
    async save() {
      this.$emit("save", {
        name: this.name,
      });
      this.name = "";
    },
  },
};
</script>
