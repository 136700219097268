<template>
  <KalmModal modalTitle="Todo" @close="close">
    <template v-slot:body>
      <TodoForm
        :assignedToCurrentUser="assignedToCurrentUser"
        :buttonName="this.buttonName"
        :canevaId="this.canevaId"
        :filterAssignedTo="filterAssignedTo"
        :filterProject="filterProject"
        :lastOrder="lastOrder"
        :projectId="projectId"
        :readOnly="readOnly"
        :todo="todo"
        @close="close"
      >
      </TodoForm>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import TodoForm from "./TodoForm.vue";
export default {
  name: "TodoFormModal",
  props: {
    todo: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      required: false,
    },
    filterAssignedTo: {
      type: Array,
    },
    filterProject: {
      type: Array,
    },
    canevaId: {
      type: String,
      required: false,
    },
    lastOrder: Number,
    assignedToCurrentUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonName: {
      type: String,
      required: false,
      default: "Ajouter",
    },
  },
  components: {
    KalmModal,
    TodoForm,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
