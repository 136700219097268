<template>
  <div style="display: block" class="modal show" tabindex="-1" @keydown.esc="this.closeModal()" id="modalUploadTask">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.closeModal()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Content-->
            <div class="d-flex flex-row-fluid justify-content-center">
              <!--begin::Form-->
              <div ref="contactEditModalForm" class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                <!--begin::Step-->
                <div class="pb-5 completed">
                  <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-5">
                      <h3 class="fw-bolder text-dark display-6">Convertir les ouvrages du descriptif</h3>
                      <p class="text-muted mb-1">
                        Choisissez les lots à inclure, tous les ouvrages de ces lots seront transformés en tâches.
                      </p>
                      <p class="text-muted">
                        <span class="bi bi-info-circle me-1"></span>Astuce : Assignez une entreprise à un lot depuis le
                        cockpit, pour que les tâches lui soient automatiquement attribuées !
                      </p>
                    </div>
                    <!--end::Heading-->

                    <!--begin::Form Group Options-->
                    <div class="text-end text-muted">
                      <a class="cursor-pointer" @click="modalImportTasksQuickSelect(this.getLots, 'all')"
                        >Tout sélectionner</a
                      >
                      /
                      <a class="cursor-pointer" @click="modalImportTasksQuickSelect(this.getLots, 'none')">Aucun</a>
                    </div>
                    <div v-for="lot in this.lots" :key="lot.id">
                      <label class="d-flex flex-stack mb-6 cursor-pointer">
                        <span class="d-flex align-items-center me-2">
                          <span class="d-flex flex-column">
                            <span class="fs-6" :for="lot.id">{{ lot.fullName }}</span>
                          </span>
                        </span>
                        <span class="form-check form-check-custom form-check-solid">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="lot.id"
                            :id="lot.id"
                            v-model="this.getLots"
                          />
                        </span>
                      </label>
                    </div>
                    <!--end::Form Group Options-->
                  </div>
                </div>
                <!--end::Step-->

                <!--begin::Actions-->
                <div class="d-flex justify-content-end">
                  <div>
                    <button class="btn btn-lg btn-light" @click="closeModal()">Annuler</button>
                    <button class="btn btn-lg btn-primary ms-2" @click="validate()">Importer</button>
                  </div>
                </div>
                <!--end::Actions-->
              </div>
              <!--end::Form-->
            </div>
            <!--end::Content-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MUTATION_PROJECT_UPLOAD_TASKS } from "@/graphql/graphql";
import { toast } from "vue3-toastify";
export default {
  name: "UploadTasksModal",
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    lots: {
      type: Array,
      required: true,
    },
    currentProjectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    getLots: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    validate() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: MUTATION_PROJECT_UPLOAD_TASKS,
          variables: {
            projectId: this.currentProjectId,
            mode: "TaskChantier",
            lots: this.getLots,
          },
          skip() {
            return !(this.currentProjectId && this.currentProjectId !== "0" && this.currentProjectId !== 0);
          },
        })
        .then(() => {
          this.loading = false;
          toast.success("Les tâches ont été importées avec succès !");
          this.$emit("validate");
        })
        .catch(() => {
          this.loading = false;
          toast.error("Une erreur est survenue lors de l'import des tâches.");
        });
    },
    modalImportTasksQuickSelect(lots, mode) {
      if (mode === "all") {
        lots.length = 0;
        this.lots.forEach((l) => {
          lots.push(l.id);
        });
      } else {
        lots.length = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
