<template>
  <node-view-wrapper class="d-flex print-hidden" data-drag-handle draggable="true">
    <div @mouseleave="hover = false" @mouseover="hover = true">
      <div class="mt-3 mb-3 ms-8 print-border">
        <div class="ps-0 mt-0">
          <div class="card lot-component-shadow" style="position: relative">
            <div class="p-0 card-body">
              <!--begin::Section-->
              <div class="print-margins py-6 ps-0 pe-5 align-items-center justify-content-between d-flex">
                <!-- LEFT HOVER ACTIONS -->
                <div class="position-absolute d-flex align-content-center print-hidden" style="left: -50px">
                  <div
                    :style="'visibility: ' + (this.showActionButtons ? 'visible' : 'hidden')"
                    class="ouvrage-actions-left ouvrage-action-plus"
                    contenteditable="false"
                    role="button"
                    title="Ajouter une ligne"
                    @click="plusAction()"
                  >
                    <svg style="width: 15px; height: 100%; display: block" viewBox="0 0 16 16">
                      <path
                        d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"
                      ></path>
                    </svg>
                  </div>
                  <div
                    :style="'visibility: ' + (this.showActionButtons ? 'visible1' : 'hidden')"
                    class="ouvrage-actions-left ouvrage-action-drag-handle drag-handle"
                    contenteditable="false"
                    data-drag-handle
                    draggable="true"
                    role="button"
                    tabindex="0"
                    title="Déplacer l'ouvrage"
                    @click="this.toggleContextMenu()"
                  >
                    <svg
                      style="width: 15px; height: 100%; display: block"
                      viewBox="-3 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                        fill-opacity="0.2"
                      />
                    </svg>
                  </div>
                  <div v-if="this.contextMenu.show" class="position-relative" @mouseleave="hideContextMenu()">
                    <div
                      class="position-absolute mt-8 card border border-1 border-dark-subtle shadow"
                      style="z-index: 1000"
                    >
                      <div class="">
                        <button
                          class="w-100 text-nowrap btn btn-sm btn-outline-danger rounded-bottom-0"
                          @click="
                            this.deleteNode();
                            this.hideContextMenu();
                          "
                        >
                          Supprimer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- MODE - FULL -->
                <div class="digital-margins flex-grow-1 text-break">
                  <!-- Node number & collapsed mode badges -->
                  <div class="d-flex flex-column justify-content-center align-items-center no-page-break-after ms-5">
                    <span class="text-gray-500 font-monospace align-baseline fs-6 mb-3">Table des matières</span>
                    <p class="text-center text-muted mb-0">
                      Inclus dans l'export PDF+. Les lots et numéros de page seront calculés à l'export
                    </p>
                  </div>

                  <!-- Node main content -->
                  <div class="w-100" style=""></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper } from "@tiptap/vue-3";

export default {
  name: "TableOfContentsComponent",
  components: {
    NodeViewWrapper,
  },
  data() {
    return {
      hover: false,
      contextMenu: {
        show: false,
      },
    };
  },
  methods: {
    hideContextMenu(timing = 0) {
      setTimeout(() => {
        this.toggleContextMenu(false);
      }, timing);
    },
    toggleContextMenu(forceState = null) {
      this.contextMenu.show = forceState ?? !this.contextMenu.show;
    },
    plusAction() {
      const { editor, getPos, node } = this;
      const end = getPos() + node.nodeSize - 1;

      editor.chain().insertContentAt(end, { type: "lotParagraph", content: [] }).focus().run();
    },
  },
  computed: {
    showActionButtons() {
      return this.hover || this.contextMenu.show;
    },
  },
  props: {
    deleteNode: {
      type: Function,
      required: true,
    },
    node: {
      type: Object,
      required: true,
    },
    updateAttributes: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
/*noinspection CssRedundantUnit*/
.lot-component-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ProseMirror-focused:focus {
  outline: none !important;
}

.ProseMirror:focus {
  outline: none !important;
}

.ProseMirror-focused:focus-visible {
  outline: none !important;
}

.ProseMirror:focus-visible {
  outline: none !important;
}

.ouvrage-action-drag-handle {
  flex: 0 0 auto;
  order: 2;

  width: 20px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;

  cursor: grab;
}

.ouvrage-action-drag-handle:hover {
  background: rgba(55, 53, 47, 0.08);
}

.ouvrage-actions-left {
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus {
  order: 1;
  transition: background 20ms ease-in 0s;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  fill: rgba(55, 53, 47, 0.35);
  cursor: pointer;
  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus:hover {
  background: rgba(55, 53, 47, 0.08);
}

.print-info {
  display: none;
}

@media screen {
  div .digital-margins {
    margin-left: 40px;
    margin-right: 60px;
  }
}

@media print {
  div .digital-margins {
    margin-left: 0px;
    margin-right: 0px;
  }
  .lot-component-shadow {
    box-shadow: none;
  }

  .drag-handle {
    display: none;
  }

  .field-qt {
    border: 0;
  }

  .field-multilocation {
    border: 0;
    background-image: unset;
  }

  div .print-margins {
    margin-left: 0 !important;
    padding-left: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .print-border {
    border-left: 1px solid lightgray;
    padding-left: 10px !important;
    margin-bottom: 20px !important;
    margin-top: 15px !important;
    margin-left: 5px !important;
  }

  .ouvrage-left-actions {
    display: none !important;
    visibility: hidden;
  }

  .print-hidden {
    display: none !important;
  }

  .print-info {
    display: block;
  }
}
</style>
