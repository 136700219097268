<template>
  <div :class="{ 'blur-content': this.isModalActive }" class="row g-5">
    <!-- 1 - MES BIBLIOTHÈQUES -->
    <div id="biblios" class="col-12">
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Mes bibliothèques</span>
          </h3>
          <div class="card-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-light">
              <li class="nav-item">
                <a class="nav-link btn btn-primary fw-bolder me-2" @click="modalCreateBiblioShow()">
                  Créer une bibliothèque
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 mt-n3">
          <div id="myTabTables2" class="tab-content mt-4">
            <!--begin::Table-->
            <div class="table-responsive">
              <div v-if="this.sortedBiblios.length">
                <table class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="px-2 py-0 w-50px"></th>
                      <th class="px-2 py-0 w-100"></th>
                      <th class="px-2 py-0 min-w-300px"></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="biblio in this.sortedBiblios.filter((b) => b.type !== 'DEFAULT')"
                      :key="biblio.id"
                      class="bg-hover-light"
                      @click="this.navigateToBiblio(biblio.id)"
                    >
                      <td class="pe-3 py-3 rounded-start p-5">
                        <div class="symbol symbol-55px ms-5 mt-1 me-5">
                          <span class="symbol-label bg-light-primary align-middle align-items-end">
                            <!--         REMPLACER PAR IMAGE-AVATAR DU PROJET <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />-->
                            <i class="bi bi-bookmark-check fs-1 align-middle align-self-center"></i>
                          </span>
                        </div>
                      </td>
                      <td class="px-3">
                        <p class="text-gray-800 fw-bolder fs-6 my-1">{{ biblio.name }}</p>
                        <div class="d-flex align-items-center w-100px w-sm-100 overflow-x-scroll d-md-none">
                          <span
                            v-for="tag in biblio?.tags"
                            :key="tag.id"
                            :style="{ background: tag?.color }"
                            class="d-inline-block cursor-default text-white px-3 m-1 rounded"
                          >
                            {{ tag?.name }}
                          </span>
                        </div>
                      </td>
                      <td class="px-3 d-none d-md-table-cell">
                        <div class="d-none align-items-center d-md-flex">
                          <span
                            v-for="tag in biblio?.tags"
                            :key="tag.id"
                            :style="{ background: tag?.color }"
                            class="d-inline-block cursor-default text-white px-3 m-1 rounded"
                          >
                            {{ tag?.name }}
                          </span>
                        </div>
                      </td>
                      <td class="px-0 rounded-end">
                        <button
                          v-tooltip:bottom="'Supprimer la bibliothèque'"
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                          @click.stop="this.modalConfirmDeleteBiblioShow(biblio.id)"
                        >
                          <i class="bi bi-trash-fill align-baseline text-center"></i>
                        </button>
                        <button
                          v-tooltip:bottom="'Dupliquer cette biblio'"
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                          @click.stop="this.modalDuplicateFromBiblioShow(biblio)"
                        >
                          <i class="fa fa-copy align-baseline text-center"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else-if="this.loading" class="text-center">
                <span class="spinner-border"></span>
              </div>
              <div v-else>
                <p class="text-center text-muted my-8">
                  <span class="bi bi-folder me-2"></span>
                  Aucune bibliothèque. Cliquez sur créer une bibliothèque pour en ajouter une
                  <span class="ms-1 bi bi-arrow-up-right"></span>
                </p>
              </div>
            </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
    </div>
    <!-- 2 - BIBLIOTHÈQUES PAR DÉFAUT -->
    <div id="biblios2" class="col-12">
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Bibliothèques by Kalm</span>
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 mt-n3">
          <div id="myTabTables2" class="tab-content mt-4">
            <!--begin::Table-->
            <div class="table-responsive">
              <div v-if="this.sortedBiblios.length">
                <table class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="px-2 py-0 w-50px"></th>
                      <th class="px-2 py-0 w-100"></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="biblio in this.sortedBiblios.filter((b) => b.type === 'DEFAULT')"
                      :key="biblio.id"
                      class="bg-hover-light"
                      @click="this.navigateToBiblio(biblio.id)"
                    >
                      <td class="px-0 py-3 rounded-start p-5">
                        <div class="symbol symbol-55px ms-5 mt-1 me-5">
                          <span class="symbol-label bg-light-primary align-middle align-items-end">
                            <!--         REMPLACER PAR IMAGE-AVATAR DU PROJET <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />-->
                            <i class="bi bi-bookmark-check fs-1 align-middle align-self-center"></i>
                          </span>
                        </div>
                      </td>
                      <td class="px-0">
                        <p class="text-gray-800 fw-bolder fs-6 my-1">{{ biblio.name }}</p>
                      </td>
                      <td class="px-0 rounded-end">
                        <button
                          v-tooltip:bottom="'Dupliquer cette biblio'"
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                          @click.stop="this.modalDuplicateFromBiblioShow(biblio)"
                        >
                          <i class="fa fa-copy align-baseline text-center"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else-if="this.loading" class="text-center">
                <span class="spinner-border"></span>
              </div>
              <div v-else>
                <p class="text-center text-muted my-8">
                  <span class="bi bi-folder me-2"></span>
                  Aucune bibliothèque. Cliquez sur créer une bibliothèque pour en ajouter une
                  <span class="ms-1 bi bi-arrow-up-right"></span>
                </p>
              </div>
            </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!-- MODAL CREATE NEW BIBLIO -->
  <div :style="this.modals.createBiblio.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalCreateBiblioCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div
                  ref="contactEditModalForm"
                  class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                >
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouvelle bibliothèque d'ouvrages</h3>
                      </div>

                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom de la bibliothèque</label>
                        <input
                          v-model="modals.createBiblio.newBiblio.name"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Projet Témoin - Appartement"
                          type="text"
                        />
                      </div>
                      <div class="fv-row mb-4">
                        <TagMultiSelect
                          v-model="modals.createBiblio.newBiblio.tags"
                          for="BIBLIO"
                          @newTag="modalCreateTagShow"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" @click="modalCreateBiblioCancel()">Annuler</button>
                      <button
                        :disabled="!modals.createBiblio.newBiblio.name"
                        class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                        @click="modalCreateBiblioValidate()"
                      >
                        Créer une bibliothèque
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL DUPLICATE BIBLIO -->
  <div :style="this.modals.duplicateBiblio.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalDuplicateBiblioCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div
                  ref="contactEditModalForm"
                  class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                >
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouvelle bibliothèque d'ouvrages</h3>
                      </div>

                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom de la bibliothèque</label>
                        <input
                          v-model="modals.duplicateBiblio.newBiblio.name"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Projet Témoin - Appartement"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" @click="modalDuplicateBiblioCancel()">Annuler</button>
                      <button
                        :disabled="!modals.duplicateBiblio.newBiblio.name"
                        class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                        @click="modalDuplicateBiblioValidate()"
                      >
                        Créer une bibliothèque
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL CONFIRM DELETE BIBLIO -->
  <div :style="this.modals.deleteBiblio.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalConfirmDeleteBiblioCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Supprimer la bibliothèque</h3>
                      </div>
                      <p>
                        Vous pourrez toujours le consulter, le dupliquer, mais plus le modifier ou en faire des exports.
                        Si besoin de le désarchiver, n'hésitez pas à contacter le service client :)
                      </p>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" type="button" @click="this.modalConfirmDeleteBiblioCancel()">
                        Annuler
                      </button>
                      <button
                        class="btn btn-lg btn-danger ms-2"
                        type="button"
                        @click="this.modalConfirmDeleteBiblioValidate()"
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Create Tag -->
  <CreateTagModal
    v-if="modals.createTag.show"
    v-model="modals.createTag.newTag"
    @close="modalCreateTagClose"
    @validate="modalCreateTagValidate"
  />
</template>

<script>
import TagMultiSelect from "../tags/presentation/tag-select/TagMultiSelect.vue";
import CreateTagModal from "../tags/presentation/components/CreateTagModal.vue";
import { CREATE_TAG, GET_TAGS } from "@/views/tags/data/tags_graphql";
import {
  ALL_BIBLIO_QUERY,
  MUTATION_BIBLIO_CREATE,
  MUTATION_BIBLIO_DELETE,
  MUTATION_BIBLIO_DUPLICATE,
} from "@/graphql/graphql";
import kalmStore from "@/store";
import { toast } from "vue3-toastify";

export default {
  name: "BibliosPage",
  apollo: {
    biblios: {
      query: ALL_BIBLIO_QUERY,
    },
  },
  components: {
    TagMultiSelect,
    CreateTagModal,
  },
  methods: {
    modalConfirmDeleteBiblioShow(id) {
      event.stopPropagation();
      this.modals.deleteBiblio.id = id;
      this.modals.deleteBiblio.show = true;
    },
    modalConfirmDeleteBiblioCancel() {
      this.modals.deleteBiblio.id = "";
      this.modals.deleteBiblio.show = false;
    },
    modalConfirmDeleteBiblioValidate() {
      const id = this.modals.deleteBiblio.id;

      this.$apollo.mutate({
        mutation: MUTATION_BIBLIO_DELETE,
        variables: { id },
        refetchQueries: [
          {
            query: ALL_BIBLIO_QUERY,
          },
        ],
      });

      this.modals.deleteBiblio.show = false;
    },
    navigateToBiblio(biblioId) {
      this.$router.push({
        name: "biblio.edit",
        params: {
          id: biblioId,
        },
      });
    },
    modalCreateBiblioShow() {
      this.modals.createBiblio.show = true;
      this.modals.createBiblio.newBiblio.name = "";
    },
    modalCreateBiblioCancel() {
      this.modals.createBiblio.show = false;
      this.modals.createBiblio.newBiblio.name = "";
    },
    modalCreateBiblioValidate() {
      this.$apollo
        .mutate({
          mutation: MUTATION_BIBLIO_CREATE,
          variables: {
            newBiblioName: this.modals.createBiblio.newBiblio.name,
            tags: this.modals.createBiblio.newBiblio.tags,
          },
          refetchQueries: [
            {
              query: ALL_BIBLIO_QUERY,
            },
          ],
        })
        .then((res) => {
          this.$router.push({
            name: "biblio.edit",
            params: {
              id: res.data.biblioCreate.biblio.id,
            },
          });
        });

      this.modalCreateBiblioCancel();
    },
    modalDuplicateFromBiblioShow(biblio) {
      this.modals.duplicateBiblio.show = true;
      this.modals.duplicateBiblio.oldBiblioId = biblio.id;
      this.modals.duplicateBiblio.newBiblio.name = "Copie de " + biblio.name;
    },
    modalDuplicateBiblioCancel() {
      this.modals.duplicateBiblio.show = false;
      this.modals.duplicateBiblio.oldBiblioId = "";
      this.modals.duplicateBiblio.newBiblio.name = "";
    },
    async modalDuplicateBiblioValidate() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_BIBLIO_DUPLICATE,
          variables: {
            id: this.modals.duplicateBiblio.oldBiblioId,
            name: this.modals.duplicateBiblio.newBiblio.name,
          },
          refetchQueries: [
            {
              query: ALL_BIBLIO_QUERY,
            },
          ],
        });
        toast.success("Bibliothèque dupliquée avec succès");
      } catch (e) {
        console.error(e);
        toast.error("Erreur lors de la duplication de la bibliothèque");
      }

      this.modalDuplicateBiblioCancel();
    },
    async modalCreateTagValidate() {
      try {
        const tag = await this.$apollo.mutate({
          mutation: CREATE_TAG,
          variables: {
            name: this.modals.createTag.newTag.name,
            color: this.modals.createTag.newTag.color,
            entity: "BIBLIO",
          },
          refetchQueries: [{ query: GET_TAGS, variables: { entity: "BIBLIO" } }],
        });
        this.modals.createTag.callback(tag.data.tagCreate.tag.id);
        this.modalCreateTagClose();
        toast.success("Le tag a bien été créé");
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue lors de la création du tag");
      }
    },
    modalCreateTagClose() {
      this.modals.createTag.show = false;
      this.modals.createTag.newTag.name = "";
      this.modals.createTag.newTag.color = "";
    },
    modalCreateTagShow(callback) {
      this.modals.createTag.show = true;
      this.modals.createTag.callback = callback;
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.biblios.loading;
    },
    sortedBiblios() {
      return JSON.parse(JSON.stringify(this.biblios || [])).sort((a, b) => a.name.localeCompare(b.name));
    },
    isModalActive() {
      return kalmStore.state.display.profileToggled || Object.values(this.modals).some((m) => m.show);
    },
    sortedProjects() {
      return JSON.parse(JSON.stringify(this.projects)).sort(function (a, b) {
        if ("" + a.name < "" + b.name) return -1;
        if ("" + a.name > "" + b.name) return 1;
        return 0;
      });
    },
  },
  data() {
    return {
      modals: {
        createProject: {
          show: false,
          newProject: {
            name: "",
            newInfoOpName: "",
            newInfoOpAddress: "",
          },
        },
        createBiblio: {
          show: false,
          newBiblio: {
            name: "",
            tags: [],
          },
        },
        duplicateBiblio: {
          show: false,
          oldBiblioId: "",
          newBiblio: {
            name: "",
          },
        },
        duplicateProject: {
          show: false,
          oldProjectId: "",
          duplicateLocations: true,
          duplicateContacts: true,
          duplicateQts: true,
          newProject: {
            name: "",
            newInfoOpName: "",
            newInfoOpAddress: "",
          },
        },
        createTag: {
          show: false,
          callback: null,
          newTag: {
            name: "",
            color: "",
          },
        },
        archiveProject: {
          show: false,
          projectId: "",
        },
        deleteBiblio: {
          show: false,
          id: "",
        },
        unarchiveProject: {
          show: false,
          projectId: "",
        },
        deleteProject: {
          show: false,
          projectId: "",
        },
      },
      projects: [],
      libraries: [],
    };
  },
};
</script>

<style scoped></style>
