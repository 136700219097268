<template>
  <span class="position-relative align-middle h-100 d-flex" :style="{ width: '100%', height: '100%' }">
    <div class="d-flex align-middle" @mouseenter="showContextMenu = true" @mouseleave="showContextMenu = false">
      <button class="btn btn-sm btn-icon print-hidden my-auto">
        <span class="bi bi-three-dots-vertical print-hidden"></span>
      </button>
      <span class="my-auto" v-if="indentationLevel > 0">{{ "–".repeat(indentationLevel) }}</span>
      <div v-if="showContextMenu">
        <div
          class="overflow-visible position-absolute bg-white border border-1 border-secondary rounded p-2"
          style="z-index: 100"
        >
          <!--                    <button class="btn btn-sm btn-bg-light btn-text-gray-600 btn-active-primary btn-active-white w-100" @click="showContextMenu = false; $emit('hidePhase', { id: currentPhaseId, newHiddenStatus: !isHidden})">
                        <span v-if="!isHidden">
                            Masquer <span class="ms-2 bi bi-eye-slash-fill"></span>
                        </span>
                        <span v-else>
                            Afficher <span class="ms-2 bi bi-eye-fill"></span>
                        </span>
                    </button>-->
          <button
            class="mt-1 btn btn-sm btn-bg-light btn-text-gray-600 btn-active-primary btn-active-white w-100"
            @click="
              showContextMenu = false;
              $emit('updatePhase', currentPhaseId);
            "
          >
            Modifier <span class="ms-2 bi bi-pencil"></span>
          </button>
          <button
            class="mt-1 btn btn-sm btn-bg-light btn-text-gray-600 btn-active-primary btn-active-white w-100"
            @click="
              showContextMenu = false;
              $emit('createSubPhase', currentPhaseId);
            "
          >
            Sous-phase <span class="ms-2 bi bi-plus-circle"></span>
          </button>
          <button
            class="mt-1 btn btn-sm btn-bg-light btn-text-gray-600 btn-active-primary btn-active-white w-100"
            @click="
              showContextMenu = false;
              $emit('deletePhase', currentPhaseId);
            "
          >
            Supprimer <span class="ms-2 bi bi-trash"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="h-100 w-100 d-flex align-middle justify-content-center">
      <span class="m-auto w-100">{{ label }}</span>
    </div>
  </span>
</template>

<script>
export default {
  name: "CustomGGanttLabelColumnRow",
  props: {
    label: {
      type: String,
      default: "",
    },
    bars: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showContextMenu: false,
    };
  },
  computed: {
    currentPhaseId() {
      return this.bars[0].ganttBarConfig.id || null;
    },
    isHidden() {
      return this.bars[0].hidden || false;
    },
    indentationLevel() {
      return this.bars[0].ganttBarConfig.indentationLevel;
    },
  },
};
</script>
