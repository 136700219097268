<template>
  <IconButton iconName="trash" :tooltip-name="tooltipName"></IconButton>
</template>

<script>
import IconButton from "./IconButton.vue";
export default {
  name: "DeleteButton",
  props: {
    tooltipName: {
      type: String,
      default: "Supprimer",
    },
  },
  components: {
    IconButton,
  },
};
</script>
