<template>
  <div id="loader">
    <img id="loader-img" alt="Logo" src="/assets/media/logos/logo-compact-positive.png" fill="#01949A" />
    <div id="loader-content"></div>
  </div>
</template>

<script>
export default {
  name: "KalmLoader",
};
</script>

<style lang="scss" scoped>
#loader {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loader-img {
  width: 100px;
  height: 100px;
  display: block;
  position: absolute;
}

#loader-content {
  display: block;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border: 3px solid transparent;
  border-top-color: #195d5b;
  border-bottom-color: #195d5b;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
