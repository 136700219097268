<template>
  <div class="col-12 container px-2 py-12 g-5">
    <div class="h-100 p-auto" @click="createTask">
      <div
        class="d-flex btn flex-column justify-content-center align-items-center h-100 border border-1 border-dashed border-hover-secondary rounded border-active active border-primary py-10"
      >
        <p class="mb-3">Ajouter une tâche</p>
        <i class="bi bi-plus-square-dotted text-primary"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TasksEmptyState",
  methods: {
    createTask() {
      this.$emit("createTask");
    },
  },
};
</script>
