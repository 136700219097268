<template>
  <IconButton iconName="chat-dots-fill">
    <template v-slot:before-icon>
      <span class="ms-2">{{ commentsByTask.length }}</span>
    </template>
  </IconButton>
</template>

<script>
import IconButton from "@/views/_core/components/IconButton.vue";
import { GET_COMMENTS } from "../../data/comments_graphql";
export default {
  name: "CommentsCounter",
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  components: {
    IconButton,
  },
  data() {
    return {
      commentsByTask: [],
    };
  },
  apollo: {
    commentsByTask: {
      query: GET_COMMENTS,
      variables() {
        return {
          taskId: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
};
</script>
