<template>
  <div class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar mb-5" id="kt_toolbar">
      <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
          <!--begin::Title-->
          <h3 class="text-dark fw-bolder my-1">Mon abonnement</h3>
          <!--end::Title-->
          <!--begin::Breadcrumb-->
          <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
            <li class="breadcrumb-item">Paramètres</li>
            <li class="breadcrumb-item text-dark">Mon abonnement</li>
          </ul>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Info-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
          <router-link :to="{ name: 'settings.account_settings' }" class="btn btn-active-accent fw-bolder ms-3"
            >Mon compte</router-link
          >
          <router-link
            :to="{ name: 'settings.subscription_settings' }"
            class="btn btn-active-accent active fw-bolder ms-3"
            >Mon abonnement</router-link
          >
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Profile Account-->
        <div class="card">
          <!--begin::Subscription-->
          <div class="row p-5">
            <div class="row">
              <div class="col-md-4 col-lg-12 col-xl-4">
                <div class="d-flex flex-column text-center px-9 py-12 justify-content-center">
                  <div class="text-primary fs-3 fw-bold mb-7">Basic</div>
                  <div class="fs-5x fw-semibold d-flex justify-content-center align-items-start lh-sm">
                    49<span class="align-self-start fs-2 mt-3">€</span>
                  </div>
                  <div class="text-muted fw-bold mb-7">/mois</div>
                  <a href="#" class="btn btn-light-primary fw-bold fs-6 py-3 px-8 mx-auto">Choisir</a>
                </div>
              </div>
              <div class="col-md-4 col-lg-12 col-xl-4">
                <div class="d-flex flex-column text-center px-9 py-12 justify-content-center bg-primary rounded">
                  <div class="text-white fs-3 fw-bold mb-7">Pro</div>
                  <div class="fs-5x text-white fw-semibold d-flex justify-content-center align-items-start lh-sm">
                    79<span class="fs-2 mt-3">€</span>
                  </div>
                  <div class="text-white fw-bold mb-7">/mois</div>
                  <a
                    href="#"
                    class="btn disabled bg-white bg-opacity-20 bg-hover-white text-hover-primary text-white fw-bold fs-6 py-3 px-8 mx-auto"
                    >Plan actuel</a
                  >
                </div>
              </div>
              <div class="col-md-4 col-lg-12 col-xl-4">
                <div class="d-flex flex-column text-center px-9 py-12 justify-content-center">
                  <div class="text-primary fs-3 fw-bold mb-7">Advanced</div>
                  <div class="fs-5x fw-semibold d-flex justify-content-center align-items-start lh-sm">
                    109<span class="fs-2 mt-3">€</span>
                  </div>
                  <div class="text-muted fw-bold mb-7">/mois</div>
                  <a href="#" class="btn btn-light-primary fw-bold fs-6 py-3 px-8 mx-auto">Choisir</a>
                </div>
              </div>
            </div>
          </div>
          <!--end::Subscription-->
        </div>
        <!--end::Profile Account-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
export default {
  name: "SettingsWrapperPage",
};
</script>

<style scoped></style>
